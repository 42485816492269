import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  WarningOutlined
} from "@ant-design/icons";
import {
  Form,
  Input,
  notification,
  Pagination,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import {
  MdDelete,
  MdOutlineClose
} from "react-icons/md";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
// import { MdOutlineClose } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

// const MyFormItemContext = React.createContext([]);
// function toArr(str) {
//   return Array.isArray(str) ? str : [str];
// }
// const MyFormItemGroup = ({ prefix, children }) => {
//   const prefixPath = React.useContext(MyFormItemContext);
//   const concatPath = React.useMemo(
//     () => [...prefixPath, ...toArr(prefix)],
//     [prefixPath, prefix]
//   );
//   return (
//     <MyFormItemContext.Provider value={concatPath}>
//       {children}
//     </MyFormItemContext.Provider>
//   );
// };
// const MyFormItem = ({ name, ...props }) => {
//   const prefixPath = React.useContext(MyFormItemContext);
//   const concatName =
//     name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
//   return <Form.Item name={concatName} {...props} />;
// };

// const menuItems = [
//   { key: 1, label: "15 min", value: "15 min" },
//   { key: 2, label: "30 min", value: "30 min" },
//   { key: 3, label: "45 min", value: "45 min" },
//   { key: 4, label: "60 min", value: "60 min" },
//   { key: 5, label: "75 min", value: "75 min" },
//   { key: 6, label: "90 min", value: "90 min" },
//   { key: 7, label: "105 min", value: "105 min" },
//   { key: 8, label: "120 min", value: "120 min" },
//   { key: 9, label: "135 min", value: "135 min" },
//   { key: 10, label: "150 min", value: "150 min" },
//   { key: 11, label: "165 min", value: "165 min" },
//   { key: 12, label: "180 min", value: "180 min" },
// ];

const AddExpertsTable = () => {
  let history = useNavigate();
  const [searchText, setSearchText] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showIndustryModal, setIndustryModal] = useState(false);
  const [showTagModal, setTagModal] = useState(false);

  const [st, setSt] = useState(1);
  const [addExpertList, setAddExpertList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [managerId, setManagerId] = useState("");
  const [managerList, setManagerList] = useState("");
  const [pagination, setPagination] = useState({
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  });

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [selectedIndustOptions, setSelectedIndustOptions] = useState([]);
  const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);

  const [expertId, setExpertId] = useState("");
  const [filteredClientList, setFilteredClientList] = useState([]);

  const [industOption, setFilteredIndust] = useState("");
  const [tagtOption, setFilteredTag] = useState("");

  // const handlePlayPause = () => {
  //   setIsPlaying(!isPlaying);
  // };

  // const getQueryParameter = (name) => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   return urlParams.get(name);
  // };

  const GetAddExpertList = async (page, st, selectedOptions) => {
    setLoading(true);
    try {
      let object = {};

      if (selectedOptions?.key) {
        object.key = selectedOptions?.key;
      }
      const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

      const res = await fetch(
        `${API_PATH.ADD_EXPERT_LIST
        }?st=${st}&page=${page}&limit=10&${queryParams?.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setAddExpertList(result?.data);
        setCallPage(result?.countData);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const GetTagsList = async (tagtOption) => {
    setLoading(true);

    let object = {};

    if (tagtOption) {
      object.key = `${tagtOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_TAG}?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredOptions(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const GetIndustryList = async (industOption) => {
    setLoading(true);

    let object = {};

    if (industOption) {
      object.key = `${industOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_INDUSTRY
        }?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredIndustOptions(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const GetCategoryList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.ADD_EXPERT_CATEGORIES}?limit=50`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setCategoryList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const GetManagerList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.MANAGER_LIST}?limit=100`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setManagerList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAddExpertList(currentPage, st, selectedOptions);
    GetTagsList(tagtOption);
    GetIndustryList(industOption);
    GetCategoryList();
    GetManagerList();
    const url = constructUrl(selectedOptions);
  }, [currentPage, industOption, tagtOption, st, selectedOptions]);

  const DeleteConsult = (_id) => {
    setManagerId(_id);
  };

  const getExpertId = ({ data, id }) => {
    if (!data?.industry || /^\s*,\s*$/.test(data?.industry)) {
      // If industry data is empty or contains only commas and whitespace, don't set selectedIndustOptions
      setSelectedIndustOptions([]);
    } else {
      const selectedIndustOptions = Array.isArray(data?.industry)
        ? data?.industry
        : data?.industry?.split(",");
      setSelectedIndustOptions(selectedIndustOptions);
    }

    if (!data?.industry || /^\s*,\s*$/.test(data?.industry)) {
      // If industry data is empty or contains only commas and whitespace, don't set selectedIndustOptions
      setSelectedOptions([]);
    } else {
      const selectedOptions = Array.isArray(data?.tags)
        ? data?.tags
        : data?.tags?.split(",");
      setSelectedOptions(selectedOptions); // Convert tags to an array
    }

    setExpertId(id);
  };

  const handleChangeProfiles = (option) => {
    if (option === "New Profiles") {
      setSt(1);
    } else {
      setSt(4);
    }

    GetAddExpertList(currentPage, st);
  };

  const DeleteConsulting = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CONSULTING_DELETE}/${managerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        GetAddExpertList(currentPage, st);
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Expert has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const UpdateCallList = async (record) => {
    setLoading(true);
    if (
      record?.email !== "" &&
      record?.industry &&
      record?.tags &&
      record?.tags &&
      record?.linkendin_url &&
      record?.analyst &&
      record?.by_linkedinAccount &&
      record?.category
    ) {
      try {
        const res = await fetch(`${API_PATH.SEND_QC}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            email: record?.email,
            contact_code: record?.contact_code,
            contact_number: record?.contact_number,
            // linkendin_url: record?.linkendin_url,
            linkedin_url: record?.linkendin_url,
            by_linkedinAccount: record?.by_linkedinAccount,
            category: record?.category,
            industry: [record?.industry],
            tags: [record?.tags],
            expert_id: record?._id,
          }),
        });

        const result = await res.json();
        if (result) {
          GetAddExpertList(currentPage, st);
          if (res.status === 200 || res.status === true) {
            notification.success({
              message: "Expert moved to QC within 5 - 10 min.",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } else {
      notification.warning({
        message: "Warning: All fields are mandatory",
        icon: <WarningOutlined style={{ color: "#fff" }} />, // Custom warning icon
        style: {
          backgroundColor: "#f39c12", // Yellow background color
          color: "#fff", // Text color
          border: "1px solid #d35400", // Border color (optional)
          padding: "10px", // Padding (optional)
        },
        duration: 5, // Duration in seconds
        placement: "topRight", // Position of the notification
      });
    }

    setLoading(false);
  };

  const UpdateAddExpert = async (record) => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          email: record?.email,
          contact_code: record?.contact_code,
          contact_number: record?.contact_number,
          linkendin_url: record?.linkendin_url,
          linkedin_url: record?.linkendin_url,
          by_linkedinAccount: record?.by_linkedinAccount,
          category: record?.category,
          industry: [record?.industry],
          tags: [record?.tags],
          expert_id: record?._id,
        }),
      });

      const result = await res.json();
      if (result) {
        GetAddExpertList(currentPage, st);
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Expert Details Updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const addIndustries = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          industry: selectedIndustOptions,
          expert_id: expertId,
        }),
      });

      const result = await res.json();
      if (result) {
        GetAddExpertList(currentPage, st);
        if (result?.status === 200 || result?.status === true) {
          notification.success({
            message: "Expert Details Updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const addTags = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          tags: selectedOptions,
          expert_id: expertId,
        }),
      });

      const result = await res.json();
      if (result) {
        GetAddExpertList(currentPage, st);
        if (result?.status === 200 || result?.status === true) {
          notification.success({
            message: "Expert Details Updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, record) => {
        const handleChange = (value) => {
          record.email = value;
          setAddExpertList([...addExpertList]);
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) => handleChange(e.target.textContent, record, "email")}
            style={{ cursor: "pointer" }}
          >
            {email}
          </div>
        );
      },
    },
    {
      title: "Dial code",
      dataIndex: "contact_code",
      key: "contact_code",
      render: (contact_code, record) => {
        const handleChange = (value) => {
          record.contact_code = value;
          setAddExpertList([...addExpertList]);
        };

        const addPlusIfNeeded = (text) => {
          if (!text) {
            return ""; // Return an empty string if text is falsy (null or undefined)
          }
          if (!text?.includes("+")) {
            return "+" + text;
          }
          return text;
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(
                addPlusIfNeeded(e.target.textContent),
                record,
                "contact_code"
              )
            }
            style={{ cursor: "pointer" }}
          >
            {addPlusIfNeeded(contact_code)}
          </div>
        );
      },
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
      render: (contact_number, record) => {
        const handleChange = (value) => {
          record.contact_number = value;
          setAddExpertList([...addExpertList]);
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "contact_number")
            }
            style={{ cursor: "pointer" }}
          >
            {contact_number}
          </div>
        );
      },
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      render: (text, record) => {
        const industries = Array?.isArray(text) ? text : text?.split(",");
        const displayedIndustries = industries?.slice(0, 1);
        const remainingIndustries = industries?.slice(1);

        return (
          <>
            <Space>
              {displayedIndustries?.map((indust) => (
                <>
                  {indust && (
                    <Tag className="me-1" size="large" color="red" key={indust}>
                      {indust}
                    </Tag>
                  )}
                </>
              ))}
              {remainingIndustries?.length > 0 && (
                <Tag className="me-1" size="large" color="red">
                  +{remainingIndustries?.length}
                </Tag>
              )}

              <PlusCircleOutlined
                size="large"
                onClick={() => {
                  setIndustryModal(true);
                  getExpertId({ data: record, id: record?._id });
                }}
              />
            </Space>
          </>
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text, record) => {
        const tags = Array?.isArray(text) ? text : text?.split(",") || [];
        const displayedTags = tags?.slice(0, 1);
        const remainingTags = tags?.slice(1);

        return (
          <>
            <Space className="d-flex align-items-center text-center">
              {displayedTags?.map((tag) => (
                <>
                  {tag && (
                    <Tag className="me-1" size="large" color="blue" key={tag}>
                      {tag}
                    </Tag>
                  )}
                </>
              ))}
              {remainingTags?.length > 0 && (
                <Tag className="me-1" size="large" color="blue">
                  +{remainingTags?.length}
                </Tag>
              )}

              <PlusCircleOutlined
                size="large"
                onClick={() => {
                  setTagModal(true);
                  getExpertId({ data: record, id: record?._id });
                }}
              />
            </Space>
          </>
        );
      },
    },

    {
      title: "Linkedin Url",
      // dataIndex: "linkendin_url",
      key: "linkendin_url",
      render: (record) => {
        const handleChange = (value) => {
          record.linkendin_url = value;
          setAddExpertList([...addExpertList]);
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "linkendin_url")
            }
            style={{ cursor: "pointer" }}
          >
            {record?.linkendin_url
              ? record?.linkendin_url
              : record?.linkedin_url}
          </div>
        );
      },
    },
    {
      title: "Analyst",
      dataIndex: "analyst",
      key: "analyst",
      render: (analyst) => `${analyst[0]?.name}`,
    },
    {
      title: "Linkedin Account",
      dataIndex: "by_linkedinAccount",
      key: "by_linkedinAccount",
      render: (by_linkedinAccount, record) => {
        const handleChange = (value) => {
          record.by_linkedinAccount = value;
          setAddExpertList([...addExpertList]);
        };

        const updatedOptions = [
          { name: "Self" },
          { name: "Consulting" },
          ...(managerList || []),
        ];

        return (
          <Select
            placeholder="Select"
            value={by_linkedinAccount}
            showSearch
            onChange={handleChange}
            style={{
              width: 180,
            }}
            size="large"
          >
            {updatedOptions?.length > 0 ? (
              updatedOptions.map((option, index) => (
                <Option value={option?.name} key={index}>
                  {option?.name}
                </Option>
              ))
            ) : (
              <Option value="">No data found</Option>
            )}
          </Select>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category, record) => {
        const handleChange = (value) => {
          record.category = value;
          setAddExpertList([...addExpertList]);
        };

        return (
          <Select
            placeholder="Select"
            value={category}
            showSearch
            onChange={handleChange}
            style={{
              width: 180,
            }}
            size="large"
          >
            {categoryList && categoryList?.length > 0 ? (
              categoryList?.map((option, index) => (
                <Option value={option?.key} key={index}>
                  {option?.label}
                </Option>
              ))
            ) : (
              <option value="">No data found</option>
            )}
          </Select>
        );
      },
    },
    {
      title: "Send to QC",

      render: (record) => (
        <div className="d-flex expertActions justify-content-center align-items-center gap-1">
          <Tooltip placement="top" title={"Process to qc"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => UpdateCallList(record)}
              >
                <FaTelegramPlane />
              </button>
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "add",
      render: (_id, record) => (
        <>
          <div className="d-flex expertActions align-items-center gap-1">
            <Tooltip placement="top" title={"Add Data"} color="#5746EB">
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  onClick={() => {
                    UpdateAddExpert(record);
                  }}
                >
                  <LuPlus />
                </button>
              </span>
            </Tooltip>

            <Tooltip placement="top" title="Delete" color="#5746EB">
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  setDeleteModal(true);
                  DeleteConsult(_id);
                }}
              >
                <MdDelete />
              </button>
            </Tooltip>
          </div>

          {/* <Space size="middle">
            <Tooltip color="#5746EB" title="Update" placement="top">
              <MdModeEditOutline
                style={{ fontSize: "18px", cursor: "pointer" }}
                onClick={() => UpdateAddExpert(record)}
              />
            </Tooltip>

            <Tooltip color="#5746EB" title="Delete" placement="top">
              <MdDelete
                onClick={() => {
                  setDeleteModal(true);
                  DeleteConsult(_id);
                }}
                style={{ fontSize: "18px", cursor: "pointer" }}
              />
            </Tooltip>
          </Space> */}
        </>
      ),
    },
  ];

  // const handleChangeCate = (value) => {
  //   const selectValue = value;
  //   setSelectedCategories({ ...selectedCategories, currency: selectValue });
  // };

  useEffect(() => {
    const filteredData = addExpertList?.filter((record) =>
      Object.values(record)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredClientList(filteredData);
    setPagination({
      ...pagination,
      current: 1,
      total: filteredData.length,
    });
  }, [addExpertList, searchText]);

  // const handleSearch = (value) => {
  //   setSearchText(value);

  //   if (currentPage !== 1) {
  //     setCurrentPage(1); // Reset the currentPage to 1 when the search filter changes
  //   }
  // };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  // const addFooter = (
  //   <div className="d-flex justify-content-around align-items-center">
  //     <Button type="primary" key="cancel">
  //       Cancel
  //     </Button>
  //     <Button key="submit">Add as Manager</Button>
  //   </div>
  // );

  // const updateFooter = (
  //   <div className="d-flex justify-content-around align-items-center">
  //     <Button type="primary" key="cancel">
  //       Close
  //     </Button>
  //     <Button key="submit">Update</Button>
  //   </div>
  // );

  // const ConstantsArray = (array) => {
  //   let newarray = array?.split(",");
  //   return newarray;
  // };

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  // const handleChange = (value) => {
  // };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleOptionIndustChange = (selectedValues) => {
    setSelectedIndustOptions(selectedValues);
  };

  const constructUrl = (options) => {
    if (options && options.key) {
      const { key } = options;

      const projectParam = Array.isArray(key) ? key.join(", ") : key;
      const sanitizedProject = projectParam
        ? projectParam.replace(/%20/g, " ")
        : "";

      const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`];

      return history({
        pathname: `/dashboard/temp_expert`,
        search: `?${urlParams.join("&")}`,
      });
    } else {
      console.error("Invalid options:", options);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSelectedOptions({
      key: inputValue,
    });
    setSearchText(inputValue);
    setCurrentPage(1);
  };

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Add Experts</h2>
              <Segmented
                size="large"
                defaultValue={"New Profiles"}
                options={["New Profiles", "Old Profiles"]}
                onChange={handleChangeProfiles}
              />
            </div>

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />
            <Table
              dataSource={filteredClientList}
              columns={columns}
              pagination={false}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
              loading={loading}
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              showTotal={(callPage, range) =>
                `${range[0]}-${range[1]} of ${callPage} items`
              }
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <Modal
        show={showIndustryModal}
        onHide={() => setIndustryModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body>
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setIndustryModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Add Industry</h4>
          </div>
          <Form name="form_item_path" layout="vertical" onFinish="">
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                style={{
                  width: "100%",
                }}
                size="large"
                mode="tags"
                placeholder="Select options"
                onSearch={setFilteredIndust}
                value={selectedIndustOptions}
                onChange={handleOptionIndustChange}
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {filteredIndustOptions?.map((option) => (
                  <Option key={option?._id} value={option?.category_name}>
                    {option?.category_name}
                  </Option>
                ))}
              </Select>
            </Space>
            <div className="d-flex justify-content-end align-items-center mt-4">
              <button
                className="secondaryBttn btn"
                onClick={() => setIndustryModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="viewButton btn ms-2"
                onClick={() => {
                  setIndustryModal(false);
                  addIndustries();
                }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTagModal}
        onHide={() => setTagModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body>
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setTagModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Add Tag</h4>
          </div>
          <Form name="form_item_path" layout="vertical" onFinish="">
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                style={{
                  width: "100%",
                }}
                size="large"
                mode="tags"
                onSearch={setFilteredTag}
                placeholder="Select options"
                value={selectedOptions}
                onChange={handleOptionChange}
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {filteredOptions?.map((option) => (
                  <Option key={option?._id} value={option?.name}>
                    {option?.name.toString().toLowerCase()}
                  </Option>
                ))}
              </Select>
            </Space>
            <div className="d-flex justify-content-end align-items-center mt-4">
              <button
                className="secondaryBttn btn"
                onClick={() => setTagModal(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setTagModal(false);
                  addTags();
                }}
                className="viewButton btn ms-2"
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Delete Expert</h4>
          </div>
          <div>
            This action cannot be undone. Are you sure you want to delete this{" "}
            expert ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <button
              onClick={() => {
                setDeleteModal(false);
                DeleteConsulting();
              }}
              className="viewButton btn ms-2"
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddExpertsTable;
