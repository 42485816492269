import { Checkbox, DatePicker, Form, Input, Select, Button, Spin } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import useStep from "../../../CustomHooks/UseStep";
import { InfoCircleOutlined } from "@ant-design/icons";
import API_PATH from "../../../Constants/api-path";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import ExpertAuth from "../../components/ExpertAuth";

dayjs.extend(customParseFormat);

const { Option } = Select;

function RegistrationStep2() {
  const history = useNavigate();
  const { setCurrentStep } = useStep();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState("");
  const [step2, setStep2] = useState({
    current_employment_status: "",
    highest_education: "",
    highest_professional_level: "",
    current_designation: "",
    expertise_subject: "",
    associated_company: "",
    agreements_textarea: "",
  });
  const [inputs, setInputs] = useState([
    {
      career_title: "",
      company_name: "",
      location: "",
      date_from: null,
      date_till: null,
      till_present: "no",
    },
  ]);

  // const [allDesig, setAllDesig] = useState([]);

  const handleStep2Change = (event) => {
    const { name, value } = event.target;
    setStep2((step2) => ({
      ...step2,
      [name]: value,
    }));
  };

  const handleStep2ChangeCuremp = (value) => {
    const selectValue = value;
    setStep2({ ...step2, current_employment_status: selectValue });
  };

  const handleStep2ChangehigEdu = (value) => {
    const selectValue = value;
    setStep2({ ...step2, highest_education: selectValue });
  };

  const handleStep2ChangeHigprof = (value) => {
    const selectValue = value;
    setStep2({ ...step2, highest_professional_level: selectValue });
  };

  const handleStep2ChangecurrDesig = (value) => {
    const selectValue = value;
    setStep2({ ...step2, current_designation: selectValue });
  };

  const handleCheckboxChange = (event, inputIndex) => {
    const { checked } = event.target;
    const updatedInputs = inputs.map((input, index) =>
      index === inputIndex
        ? { ...input, till_present: checked ? "yes" : "no" }
        : input
    );
    setInputs(updatedInputs);
  };

  const handleInputChange = (value, field, index) => {
    setInputs((prevInputs) =>
      prevInputs?.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            [field]:
              (field === "date_from" || field === "date_till") && value
                ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                : value, // If value is null (cleared), then set field to null
          };
        }
        return input;
      })
    );
  };

  const saveToLocalStorage = () => {
    const data = {
      career_title: [],
      company_name: [],
      location: [],
      date_from: [],
      date_till: [],
      till_present: [],
    };

    inputs?.forEach((input) => {
      if (
        input.career_title !== "" &&
        input.company_name !== "" &&
        input.location !== ""
      ) {
        data.career_title.push(input.career_title);
        data.company_name.push(input.company_name);
        data.location.push(input.location);
        data.date_from.push(
          input.date_from
            ? dayjs(input.date_from, "MM, YYYY").format("MMM YYYY")
            : ""
        );
        data.date_till.push(
          input.date_till
            ? dayjs(input.date_till, "MM, YYYY").format("MMM YYYY")
            : ""
        );
        data.till_present.push(input.till_present === "yes" ? "yes" : "no");
      }
    });

    const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
    const dataToStore = {
      step2,
      inputs: data,
      expiration: expirationTime,
    };
    localStorage.setItem("step2", JSON.stringify(dataToStore));
  };

  const handleSubmit = () => {
    // Handle form submission
    saveToLocalStorage();
    history(`/expert-register?regType=1&step=3`);
    setCurrentStep(3);
  };

  const handleAddInput = () => {
    const newInputs = [
      ...inputs,
      {
        career_title: "",
        company_name: "",
        location: "",
        date_from: null,
        date_till: null,
        till_present: "no",
      },
    ];
    setInputs(newInputs);
  };

  const handleDeleteInput = (index) => {
    if (inputs.length > 1) {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
    }
  };

  // function filterDuplicates(array) {
  //   return Array.from(new Set(array));
  // }

  const getExpertsOptions = () => {
    setLoading(true);

    axios
      .get(`${API_PATH.EXPERT_OPTION}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // setAllDesig(filterDuplicates(res?.data?.designationNew));
        setLoading(false); // Move setLoading to false here to
      })
      .catch((error) => {
        console.error("Expert Options: ", error);
        setLoading(false);
      });
    setLoading(false);
  };

  const allDesig = [
    "Developer",
    "Senior Backend Develpoer",
    "Business Development",
    "Business Analyst",
    "Director of Analytics",
    "Director Transformation and client solutions",
    "Consultant",
    "First Vice President",
    "Executive Director",
    "General Manager",
    "Director",
    "Project Manager",
    "Innovation Manager",
    "Managing Principal",
    "Field Sales Manager",
    "Senior Director",
    "CTO",
    "Head Commercial / Procurement",
    "Technical Director & Partner",
    "Business Manager",
    "Employee",
    "Chief Excecutive Officer",
    "Clinical AI Project Manager",
    "APAC & Emerging Market",
    "COO / Global Head of Digital Technology ",
    "VP Corporate Strategy",
    "Global Marketing",
    "Legal Director",
    "Senior Vice President",
    "Consultant",
    "Strategic Sourcing & Supply Chain",
    "Principal Consultant Mines",
    "Resources and Energy | Engineering",
    "Business",
    "Strategy",
    "Global Account Director",
    "Digital Transformation Consultant",
    "Owner",
    "Senior Manager",
    "Business Development and Partnerships",
    "Chief Marketing Officer",
    "Head of custome quality",
    "Co-Founder and Director",
    "Product",
    "Vice President - New Product Initiatives",
    "Portfolio Director",
    "Global Head of New Business",
    "Global Technology Director",
    "Global Programme Director",
    "Product Owner",
    "Change and Transformation Leader",
    "Chief Commercial Officer",
    "Director Analytics",
    "AVP - OPERATIONS",
    "Managing Director",
    "Physician",
    "Head of Acquiring",
    "Firefighter / Operational Officer",
    "Head of Strategy& Insight",
    "Chief of Staff",
    "Sr Director",
    "Product Management",
    "Messaging",
    "Subsurface Manager",
    "Chief Marketing Officer",
    "CFO and Strategy Director",
    "Vice President Product Management and Strategy",
    "Vice President",
    "Independent Automotive Management Consultant",
    "Distribution and Sales Manager",
    "Regional Controller / CFO",
    "Director",
    "Test Pilot",
    "University Research Fellow",
    "Founder and Director ",
    "Strategy Manager",
    "Global FSQI Manager",
    "Senior Program Manager",
    "Strategic Programs",
    "Marketing Manager",
    "Senior Lead Engineer",
    "Police Chief",
    "Professor of PSychiatry",
    "Independent Consultant",
    "CEO President",
    "Senior Data Scientist - Team Lead",
    "Senior Product Manager",
    "Delivery Manager",
    "Payments Strategy",
    "Head of Digital",
    "DCX",
    "Global Head of Commercial - Cloud",
    "CXO",
    "Former Key Account Manager",
    "Self Employed",
    "Chairman",
    "Feed Mill Consultant",
    "Director of integration",
    "Director/Head of Customer Success",
    "Director, Strategy",
    "Retired ",
    "Officer",
    "Freelance Advisor",
    "Chief Operating Officer",
    "Assistant Editor",
    "Business Development Manager",
    "Senior Consultant",
    "Head of Supply Chain department",
    "Associate Professor",
    "Sales Director",
    "Director of Business Development",
    "Technical Consultant",
    "Specialist consulting and C-Level Board advisory services",
    "Managing Partner",
    "vice president- Marketing",
    "Assistant Principal",
    "Deputy General Manager",
    "Professor ",
    "Business Head",
    "Retired Banker ",
    "Branch Manager",
    "Senior Manager Marketing",
    "Lead - Content Engineering",
    "Consutant ",
    "VP Business Development ",
    "Sales and Marketing Director",
    "Manager - Marketing & Sales (Natural Gas)",
    "National Specification Manager",
    "VP - Vehicle Financing & Growth",
    "Head Sales & Marketing ",
    "Associate Director",
    "Department Head ",
    "Director Marketing",
    "Advisor",
    "Brand Manager",
    "VP Operations",
    "Associate Partner",
    "Head Customer Acquisition and SEO Growth ",
    "Manager",
    "Senior Lead Client Success",
    "Commercial manager Innovation and Marketing",
    "Advisor/Consultant",
    "National Manager",
    "HR",
    "Lead IT Recruiter",
    "DVP",
    "VP Finance",
    "Business manager",
    "Global Business Development Manager - Marine Lubricants",
    "CISO",
    "AVP - Solutions and Capabilities",
    "Senior Analyst",
    "CRM & Loyalty Sr. Manager",
    "Associate",
    "Senior Growth Marketing Manager",
    "Head - Digital Sales",
    "Associate General Manager - Strategy & Operational Excellence ",
    "Talent Acquisition Lead",
  ];

  const isLocalStorageExpired = () => {
    const storedData = localStorage.getItem("step2");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const now = new Date().getTime();
      return parsedData.expiration && now > parsedData.expiration;
    }
    return true;
  };

  useEffect(() => {
    const storedData = localStorage.getItem("step2");
    if (!isLocalStorageExpired() && storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData?.step2 && parsedData?.inputs) {
        const { step2, inputs } = parsedData;
        setStep2(step2);

        const inputsArray = Array.isArray(inputs.career_title)
          ? inputs.career_title.map((_, index) => ({
            career_title: inputs.career_title[index] || "",
            company_name: inputs.company_name[index] || "",
            location: inputs.location[index] || "",
            date_from: inputs.date_from[index]
              ? dayjs(inputs.date_from[index], "MMM YYYY").format("MM, YYYY")
              : "",
            date_till: inputs.date_till[index]
              ? dayjs(inputs.date_till[index], "MMM YYYY").format("MM, YYYY")
              : "",
            till_present: inputs.till_present[index] || "no",
          }))
          : [];

        setInputs(inputsArray);

        const initialValues = {
          current_employment_status: step2?.current_employment_status,
          highest_education: step2?.highest_education,
          highest_professional_level: step2?.highest_professional_level,
          current_designation: step2?.current_designation,
          expertise_subject: step2?.expertise_subject,
          associated_company: step2?.associated_company,
          agreements_textarea: step2?.agreements_textarea,
          till_present: step2?.till_present,
        };

        const inputValues = inputsArray.reduce((acc, input, index) => {
          acc[index] = {
            career_title: input?.career_title,
            company_name: input?.company_name,
            location: input?.location,
            date_from: input?.date_from || "",
            date_till: input?.date_till || "",
          };
          return acc;
        }, {});

        form.setFieldsValue({
          ...initialValues,
          ...inputValues,
        });
      }
    } else {
      // Clear data and remove expired item from local storage
      localStorage.removeItem("step2");
    }

    // Assuming this function is defined elsewhere and used appropriately
    getExpertsOptions();
  }, [form]);

  return (
    <React.Fragment>
      <ExpertAuth />

      <div className="expertRegistration step1">
        <h5>Step 2 : Professional Background</h5>
        <p>
          Kindly complete this section by either sharing your LinkedIn profile
          link or Curriculum Vitae (CV). Alternatively, if the first two options
          are unavailable, you have the option of completing this section
          manually.
        </p>

        <div>
          <Form layout="vertical" autoComplete="off" form={form}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  name="current_employment_status"
                  label="Current Employment Status?"
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    name="current_employment_status"
                    size="large"
                    placeholder="Select Current Employement"
                    value={setStep2?.current_employment_status}
                    onChange={handleStep2ChangeCuremp}
                  >
                    <Select.Option value="Employed">Employed</Select.Option>
                    <Select.Option value="Unemployed">Unemployed</Select.Option>
                    <Select.Option value="Retired">Retired</Select.Option>
                    <Select.Option value="Self Employed">
                      Self Employed
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  name="highest_education"
                  label="Highest Education?"
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    name="highest_education"
                    size="large"
                    placeholder="Select Highest Education"
                    value={step2?.highest_education}
                    onChange={handleStep2ChangehigEdu}
                  >
                    <Select.Option value="High School">
                      High School
                    </Select.Option>
                    <Select.Option value="Bachelors">Bachelors</Select.Option>
                    <Select.Option value="Masters">Masters</Select.Option>
                    <Select.Option value="PHD">PHD</Select.Option>
                    <Select.Option value="Additional">Additional</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  name="highest_professional_level"
                  label="Highest Professional Level?"
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    name="highest_professional_level"
                    size="large"
                    placeholder="Select Highest Professional Level"
                    value={step2?.highest_professional_level}
                    onChange={handleStep2ChangeHigprof}
                  >
                    <Select.Option value="Advisor">Advisor</Select.Option>
                    <Select.Option value="CXO">CXO</Select.Option>
                    <Select.Option value="VP">VP</Select.Option>
                    <Select.Option value="AVP">AVP</Select.Option>
                    <Select.Option value="Manager & Below">Manager & Below </Select.Option>
                    <Select.Option value="Self Employed">Self Employed </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  name="current_designation"
                  label="Current Designation"
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  {loading ? (
                    <Spin />
                  ) : (
                    <Select
                      name="current_designation"
                      size="large"
                      placeholder="Select Current Designation"
                      value={step2?.current_designation}
                      onChange={handleStep2ChangecurrDesig}
                    >
                      {allDesig?.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Item
                  name="expertise_subject"
                  label="Briefly describe any specific area/subject of expertise you possess"
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    name="expertise_subject"
                    rows={4}
                    showCount
                    maxLength={300}
                    value={step2?.expertise_subject}
                    onChange={handleStep2Change}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Item
                  name="associated_company"
                  label="Are you associated with a publicly traded company?"
                  tooltip={{
                    title: "If yes, please mention the name of the company/ies",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    name="associated_company"
                    showCount
                    maxLength={100}
                    value={step2?.associated_company}
                    onChange={handleStep2Change}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Item label="Past Career Experience">
                  {inputs
                    ?.filter((input) =>
                      Object.values(input).some(
                        (value) => value !== "" && value !== null
                      )
                    )
                    .map((input, index) => (
                      <>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Item
                              className="mb-2"
                            // name={[index, "career_title"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Field is required",
                            //   },
                            // ]}
                            >
                              <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="Title"
                                name="career_title"
                                value={input?.career_title}
                                onChange={(event) =>
                                  handleInputChange(
                                    event.target.value,
                                    "career_title",
                                    index
                                  )
                                }
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              className="mb-2"
                            // name={[index, "company_name"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Field is required",
                            //   },
                            // ]}
                            >
                              <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="Company Name"
                                name="company_name"
                                value={input?.company_name}
                                onChange={(event) =>
                                  handleInputChange(
                                    event.target.value,
                                    "company_name",
                                    index
                                  )
                                }
                              ></Input>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3}>
                            <Form.Item
                              // name={[index, "location"]}
                              className="mb-2"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Field is required",
                            //   },
                            // ]}
                            >
                              <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="Location"
                                name="location"
                                value={input?.location}
                                onChange={(event) =>
                                  handleInputChange(
                                    event.target.value,
                                    "location",
                                    index
                                  )
                                }
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={3}>
                            <DatePicker
                              className="mb-2 w-100"
                              size="large"
                              format="MM, YYYY"
                              picker="month"
                              name="date_from"
                              value={
                                input?.date_from
                                  ? dayjs(input?.date_from, "MM, YYYY")
                                  : null
                              }
                              onChange={(value) =>
                                handleInputChange(value, "date_from", index)
                              }
                              disabledDate={(current) =>
                                current && current.isAfter(dayjs(), "month")
                              }
                            />
                          </Col>
                          <Col xs={12} md={3}>
                            <DatePicker
                              size="large"
                              className="w-100 mb-2"
                              format="MM, YYYY"
                              picker="month"
                              name="date_till"
                              value={
                                input?.date_till
                                  ? dayjs(input?.date_till, "MM, YYYY")
                                  : null
                              }
                              onChange={(value) =>
                                handleInputChange(value, "date_till", index)
                              }
                              disabledDate={(current) =>
                                current && current.isAfter(dayjs(), "month")
                              }
                            />
                          </Col>

                          <Col xs={12} md={3}>
                            <Checkbox
                              className="mb-2"
                              checked={input.till_present === "yes"}
                              onChange={(event) =>
                                handleCheckboxChange(event, index)
                              }
                              size="large"
                            >
                              Present
                            </Checkbox>
                          </Col>

                          <div>
                            {inputs.length > 1 ? (
                              <div className="d-flex justify-content-end align-items-center my-2">
                                <Button
                                  type="primary"
                                  onClick={() => handleDeleteInput(index)}
                                >
                                  Remove
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        </Row>
                      </>
                    ))}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <Button
                      size="large"
                      className="text-center btn btn-responsive expertButtonNext"
                      onClick={handleAddInput}
                    >
                      Add
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Item
                  name="agreements_textarea"
                  label="Do you have any agreements that can restrict you from providing consultations or being an expert on our platform? For Example, (NDAs, Employment Contracts, Severance Packages, etc.). If yes, please carefully read our confidentiality terms and conditions before registering as a part of the network."
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    name="agreements_textarea"
                    showCount
                    rows={4}
                    maxLength={300}
                    value={step2?.agreements_textarea}
                    onChange={handleStep2Change}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Item>
                <NavLink
                  to={`/expert-register?regType=1&step=1`}
                  onClick={() => setCurrentStep(1)}
                >
                  <Button
                    size="large"
                    className="btn btn-responsive expertButtonNext"
                  >
                    Prev
                  </Button>
                </NavLink>
              </Form.Item>

              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    onClick={handleSubmit}
                    className="btn btn-responsive expertButtonNext"
                    size="large"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Next
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegistrationStep2;
