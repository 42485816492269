import { Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFormValues, setIsOpen } from "../../features/QCExpert.slice";
import useQCExpert from "./hook/useQCExpert";
function AddPhantomKeyModal() {
  const { isOpen, formValues } = useSelector((state) => state.qcMeeting);
  const { updatePhantomKey } = useQCExpert();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [form, formValues]);
  return (
    <Modal
      title={false}
      style={{
        top: 20,
      }}
      open={isOpen}
      // onOk={() => dispatch(setIsOpen(false))}
      onCancel={() => {
        form.resetFields();
        dispatch(setFormValues({ phantomKey: "" }));
        dispatch(setIsOpen(false));
      }}
      width={800}
      footer={null}
      centered
    >
      <div className="mt-5">
        <h4 className="modalHeader">Update Phantom Key</h4>
        <Form
          form={form}
          initialValues={formValues}
          name="form_item_path"
          layout="vertical"
          onFinish={() => {
            updatePhantomKey();
            form.resetFields();
          }}
        >
          <Form.Item
            label="Phantom Key"
            name="phantomKey"
            rules={[{ required: true, message: "phantomKey is required!" }]}
          //   validateStatus={errorMessage ? "error" : ""}
          //   help={errorMessage}
          >
            <Input
              name="number"
              size="large"
              // value={phantomKey}
              onChange={(e) => {
                let obj = {
                  ...formValues,
                  phantomKey: e.target.value,
                };
                dispatch(setFormValues(obj));
                form.setFieldValue("phantomKey", e.target.value);
              }}
            />
          </Form.Item>

          <div className="d-flex justify-content-end align-items-center">
            <button
              className="btn viewButton mx-2"
              size="large"
              htmlType="submit"
            >
              Add
            </button>
            <button
              className="btn viewButton"
              size="large"
              htmlType="button"
              type="button"
              onClick={() => {
                dispatch(setFormValues({ phantomKey: "" }));
                dispatch(setIsOpen(false));
                form.resetFields();
              }}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default AddPhantomKeyModal;
