import { Drawer } from "antd";
import { Markup } from "interweave";
import React from "react";
import { MdOutlineStar } from "react-icons/md";
import { getFormattedDate, modifiedContent } from "../../Utils/util";

function ProjectDrawer({
  toggleDrawer,
  isDrawerVisible,
  submitPop,
  statusData,
}) {
  return (
    <Drawer
      title={
        <>
          <div style={{ float: "left" }}>
            Expert Profile #{submitPop?.profile_number}
          </div>
        </>
      }
      placement="right"
      closable={true}
      onClose={toggleDrawer}
      open={isDrawerVisible}
      width={"50%"}
    >
      {/* Offcanvas content goes here */}
      {/* Replace the content below with your actual content */}
      <div>
        {submitPop && (
          <>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "15px",
                  }}
                >
                  {submitPop?.expert_experience[0]?.current_designation}
                </h2>
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "15px",
                  }}> {submitPop?.email || "-"}</h2>
              </div>
              <div
                className="nameAndCompany_M"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  color: "#333",
                  marginTop: "-8px",
                }}
              >
                <strong
                  id="nameAndCompany_M"
                  style={{
                    fontWeight: "600",
                    fontSize: "15px",
                    color: "#333",
                  }}
                >
                  {submitPop?.expert_experience[0]?.current_compny}
                </strong>
                <span> {submitPop?.current_location}</span>
              </div>
            </div>
            <div className="d-flex mt-2 gap-2" data-bs-dismiss="offcanvas">
              {statusData}
            </div>
            <div className="mt-2">
              <div className="expertPanel_3Zo3_">
                <div className="section_3ByhA">
                  <div className="detailsRows_2038l">
                    <span className="detailsRowLeft_3h_zH">Region</span>
                    <span className="detailsRowRight_3h_zH">
                      {submitPop?.current_location}
                    </span>
                  </div>

                  <div className="detailsRows_2038l">
                    <span className="detailsRowLeft_3h_zH">Linkedin</span>
                    <span className="detailsRowRight_3h_zH">
                      <a
                        href={submitPop?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Profile
                      </a>
                    </span>
                  </div>
                  <div className="detailsRows_2038l">
                    <span className="detailsRowLeft_3h_zH">Profile No.</span>
                    <span className="detailsRowRight_3h_zH">
                      <a
                        href={submitPop?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {submitPop?.profile_number || "-"}
                      </a>
                    </span>
                  </div>
                  <div className="detailsRows_2038l">
                    <span className="detailsRowLeft_3h_zH">
                      Consulting Rate (Expert)
                    </span>
                    <span className="detailsRowRight_3h_zH">
                      {submitPop?.expertCurrency} {submitPop?.consulting_rate}
                      /hr
                    </span>
                  </div>
                  <div className="detailsRows_2038l">
                    <span className="detailsRowLeft_3h_zH">
                      Consulting Rate (Client)
                    </span>
                    <span className="detailsRowRight_3h_zH">
                      {submitPop?.expert_client_Currency}{" "}
                      {submitPop?.hourly_consulting_rate}/hr
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview mt-2">
              <h2
                style={{
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Overview
              </h2>
              <Markup
                className="markup-para"
                content={modifiedContent(submitPop?.overview)}
              />
            </div>
            <div className="experien-list mt-4">
              <h6
                style={{
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Experience
              </h6>
              <div>
                <ul className="nextyn_list_items">
                  {submitPop.expert_experience.map((curElem, i) =>
                    curElem?.current_compny || curElem.previous_compny ? (
                      <li
                        className="nextyn_list_item"
                        key={i}
                        style={{ position: "relative" }}
                      >
                        {curElem?.show_check === "yes" && (
                          <MdOutlineStar
                            style={{
                              position: "absolute",
                              top: "12px",
                              right: 0,
                              color: "#5746ec",
                            }}
                          />
                        )}

                        <div className="expertCompany_2UyI2">
                          <span className="title_1p_LH">
                            {curElem?.previous_designation
                              ? curElem?.previous_designation
                              : curElem?.current_designation}
                          </span>
                          <div className="companyName_1dSwA">
                            {curElem?.previous_compny
                              ? curElem?.previous_compny
                              : curElem?.current_compny}
                          </div>
                        </div>
                        <span className="careerDates_3sJ2b">
                          {curElem?.previous_designation_date
                            ? getFormattedDate(
                              curElem?.previous_designation_date
                            )
                            : getFormattedDate(
                              curElem?.current_designation_date
                            ) +
                            `${curElem?.till_present === "yes"
                              ? " Present"
                              : ""
                            }`}
                        </span>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default ProjectDrawer;
