import { Button, Checkbox, Divider, Drawer, Select, Tag } from "antd";

import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ACCOUNT_AND_FINANCE_ID } from "../../Utils/Constants";
import { getUpdatedTimeZone } from "../../Utils/util";
import "./AddMeetingForm.css";
import useZoomMeeting from "./useZoomMeeting";
import { setStep1 } from "../../features/zoomMeeting.slice";

function ViewZoomMeetingDrawer({ open, setOpen, GetZoomList }) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [, setShowZoomInput] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const {
    isMediatorCheckboxChecked,
    autoRecordingCheckboxChecked,
    meetingLinkCheckboxChecked,
    step1,
    step3,
    inputs,
    mediators,
    updateDraftMeeting,
    allResult,
    zoomHostAccountList,
  } = useZoomMeeting();

  const oncecall = useRef(false);

  React.useEffect(() => {
    if (
      !step1?.meeting_link &&
      !oncecall.current &&
      !meetingLinkCheckboxChecked
    ) {
      if (step1?.zoom_id) {
        oncecall.current = true;
        setShowZoomInput(true);
      }
    }
  }, [meetingLinkCheckboxChecked, step1?.meeting_link, step1.zoom_id]);

  const zoomAccountOptions = useMemo(
    () =>
      zoomHostAccountList?.map((item) => ({
        label: item?.email,
        value: item?._id,
      })),
    [zoomHostAccountList]
  );
  const dispatch = useDispatch();
  return (
    <>
      <Drawer
        title="Calendar Invite"
        placement={"right"}
        closable={true}
        destroyOnClose
        onClose={() => {
          oncecall.current = false;
          setOpen(false);
          setShowZoomInput(false);
        }}
        open={open}
        key={"1"}
        rootClassName="zoom_draft_drawer"
      >
        <div>
          <div className="">
            <div className="">
              <div className="drwrTitle">
                <div className="container">
                  <h4 className="title"> Project Details</h4>
                  <Row className="g-3 g-md-2 mb-md-4 mb-3">
                    {!meetingLinkCheckboxChecked && (
                      <Col md={4}>
                        <h5>{["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting", "introductory_call"]?.includes(step1?.engagement_type) ? "Engagement Type" : "Zoom Account"}</h5>
                        <p className="text-capitalize">
                          {["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting", "introductory_call"]?.includes(step1?.engagement_type) ?
                            <Tag color="blue" style={{ fontSize: "10px" }}>{
                              step1?.engagement_type === "file_or_sheet_filling" ? "File Or Sheet Filling" :
                                step1?.engagement_type === "focus_group_discuession" ? "Focus Group Discussion" :
                                  step1?.engagement_type === "b2b_survey" ? "B2B Survey" :
                                    step1?.engagement_type === "in_person_meeting" ? "In-Person Meeting" :
                                      step1?.engagement_type === "introductory_call" ? "Intro Call"
                                        : ""}</Tag>
                            : step1?.zoom_id ? (
                              <Tag color="green">
                                {" "}
                                {
                                  zoomHostAccountList?.filter(
                                    (acc) => acc?._id === step1?.zoom_id
                                  )?.[0]?.email
                                }
                              </Tag>
                            ) : (
                              <Tag color="red">Account Not Selected</Tag>
                            )}
                        </p>
                      </Col>
                    )}
                    <Col md={4}>
                      <h5>Project Name</h5>
                      <p>{step1?.project_name}</p>
                    </Col>
                    <Col md={4}>
                      <h5>Call Date</h5>
                      <p>
                        {["Invalid date", "null", "", "undefined"]?.includes(
                          step1?.call_date
                        )
                          ? moment().format("DD/MM/YYYY")
                          : moment(step1?.call_date, [
                            "YYYY-MM-DD",
                            "DD/MM/YYYY",
                          ]).format("DD/MM/YYYY")}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-3 g-md-2 mb-md-4 mb-3">
                    <Col xs={6} md={4}>
                      <h5>Call Time (IST)</h5>
                      <p>{step1?.call_time}</p>
                    </Col>
                    <Col xs={6} md={4}>
                      <h5>Call Duration</h5>
                      <p>{step1?.duration?.label || step1?.duration} min.</p>
                    </Col>
                    {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6} className="">
                      <Checkbox checked={autoRecordingCheckboxChecked}>
                        <span>Record this call</span>
                      </Checkbox>
                    </Col>}
                  </Row>
                  <Row>
                    {step1?.meeting_link && (
                      <Col xs={6} md={4}>
                        <h5>Meeting Link</h5>
                        <p>{step1?.meeting_link}</p>
                      </Col>
                    )}
                    {step1?.meeting_id && (
                      <Col xs={6} md={4}>
                        <h5>Meeting ID</h5>
                        <p>{step1?.meeting_id}</p>
                      </Col>
                    )}
                    {step1?.meeting_passcode && (
                      <Col xs={6} md={4}>
                        <h5>Passcode</h5>
                        <p>{step1?.meeting_passcode}</p>
                      </Col>
                    )}
                  </Row>
                </div>
                <Divider />
                <div className="container">
                  <h4 className="title"> Client Details</h4>
                  <Row className="g-md-2 g-3 mb-md-4 mb-3">
                    <Col xs={6} md={3}>
                      <h5>Location</h5>
                      <p>
                        {typeof step1?.country !== "object" && step1?.country}
                      </p>
                    </Col>
                    <Col xs={6} md={3}>
                      <h5>Timezone</h5>
                      <p>
                        {getUpdatedTimeZone(step1?.country, step1?.timezone) ||
                          step1?.timezone}
                      </p>
                    </Col>
                    <Col xs={6} md={3}>
                      <h5>Call Rate</h5>
                      <p>
                        {step1?.hourly_currency}{" "}
                        {step1?.hourly_rate_call_cunsltn}
                      </p>
                    </Col>
                    <Col xs={6} md={3}>
                      <h5>Client Company</h5>
                      <p>{step1?.client_company_name || "-"}</p>
                    </Col>
                  </Row>
                  <Row className="g-md-2 g-3 mb-md-4 mb-3">
                    <Col md={3}>
                      <h5>Client Name</h5>
                      {inputs?.map((input, index) => {
                        return <p key={index}>{input?.client_name}</p>;
                      })}
                    </Col>
                    <Col md={9}>
                      <h5>Client Email</h5>
                      {inputs?.map((input, index) => {
                        return <p key={index}>{input?.client_email}</p>;
                      })}
                    </Col>
                  </Row>
                </div>
                <Divider />
                <div className="container">
                  <h4 className="title"> Expert Details</h4>
                  <Row>
                    <Col xs={12}>
                      {step3?.map((input, index) => {
                        return (
                          <div
                            key={index}
                            className="mb-2 ms-md-4 ms-1 expert-container"
                          >
                            <h6
                              style={{
                                fontSize: "18px",
                                fontWeight: 500,
                                margin: "0 0 15px 0",
                              }}
                            >
                              Expert #{index + 1}{" "}
                              <Checkbox
                                className="ms-5"
                                checked={input?.opt_out_check}
                                disabled={true}
                              >
                                <span style={{ color: "#333" }}>
                                  <strong>DO NOT</strong> send pre call email to
                                  expert
                                </span>
                              </Checkbox>
                            </h6>

                            <table>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Expert Email
                                  </p>
                                </td>
                                <td>
                                  <p>{input?.expert_email}</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Expert Name
                                  </p>
                                </td>
                                <td>
                                  <p>{input?.expert_name}</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Designation
                                  </p>
                                </td>
                                <td>
                                  <p>{input?.expert_designation}</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Company
                                  </p>
                                </td>
                                <td>
                                  <p>{input?.expert_company}</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Location
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {typeof input?.country !== "object" &&
                                      input?.country}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Timezone
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {getUpdatedTimeZone(
                                      input?.country,
                                      input?.timezone
                                    ) || input?.timezone}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p
                                    style={{ width: "150px", fontWeight: 500 }}
                                  >
                                    Call Rate
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {input?.expert_hourly_currency ||
                                      input?.expert_call_rate_currency}{" "}
                                    {input?.expert_hourly_rate_call_cunsltn}
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </div>
                        );
                      })}
                    </Col>
                    {/* <Col xs={12} className="mt-4">
                      <Checkbox checked={isCheckboxChecked} disabled={true}>
                        <span style={{ color: "#333" }}>
                          Get call transcript for internal purpose
                        </span>
                      </Checkbox>
                    </Col> */}
                    {/* <Col xs={12} className="mt-4">
                      <Checkbox
                        checked={isExpertCheckboxChecked}
                        disabled={true}
                      >
                        <span style={{ color: "#333" }}>
                          Do not send pre-call email
                        </span>
                      </Checkbox>
                    </Col> */}
                  </Row>
                </div>
                <Divider />
                {isMediatorCheckboxChecked && (
                  <div className="container">
                    <h4 className="title"> moderator Details</h4>
                    <Row>
                      <Col xs={12}>
                        {mediators?.map((mediat, index) => {
                          return (
                            <div
                              key={index}
                              className="mb-2 ms-md-4 ms-1 expert-container"
                            >
                              <h6
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  margin: "0 0 15px 0",
                                }}
                              >
                                moderator #{index + 1}
                                {/* <Checkbox
                                className="ms-5"
                                checked={mediat?.optout_expert_for_pre_and_post_calls}
                                disabled={true}
                              >
                                <span style={{ color: "#333" }}>
                                  Do not send pre-call email
                                </span>
                              </Checkbox> */}
                              </h6>

                              <table>
                                <tr>
                                  <td>
                                    <p
                                      style={{
                                        width: "150px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      moderator Email
                                    </p>
                                  </td>
                                  <td>
                                    <p>{mediat?.mediator_email}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p
                                      style={{
                                        width: "150px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      moderator Name
                                    </p>
                                  </td>
                                  <td>
                                    <p>{mediat?.mediator_name}</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <p
                                      style={{
                                        width: "150px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Location
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {typeof mediat?.mediator_country !==
                                        "object" && mediat?.mediator_country}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p
                                      style={{
                                        width: "150px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Timezone
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {getUpdatedTimeZone(
                                        mediat?.mediator_country,
                                        mediat?.mediator_timezone
                                      ) || mediat?.mediator_timezone}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          );
                        })}
                      </Col>
                      {/* <Col xs={12} className="mt-4">
                      <Checkbox checked={isCheckboxChecked} disabled={true}>
                        <span style={{ color: "#333" }}>
                          Get call transcript for internal purpose
                        </span>
                      </Checkbox>
                    </Col> */}
                      {/* <Col xs={12} className="mt-4">
                      <Checkbox
                        checked={isExpertCheckboxChecked}
                        disabled={true}
                      >
                        <span style={{ color: "#333" }}>
                          Do not send pre-call email
                        </span>
                      </Checkbox>
                    </Col> */}
                    </Row>
                  </div>
                )}
                <Row>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(step1?.engagement_type) && !step1?.meeting_link &&
                      !meetingLinkCheckboxChecked && step1?.zoom_id &&
                      userData?.data?.roleData?.[0]?.role_permission?.[16]
                        ?.draftedZoomMeeting?.selectZoomAcc && (
                        <Col md={4}>
                          <Select
                            size="large"
                            name="zoom_id"
                            allowClear
                            placeholder="Select Zoom Account"
                            onChange={(value) => {
                              dispatch(
                                setStep1({
                                  ...step1,
                                  zoom_id: value,
                                })
                              );
                            }}
                            value={step1?.zoom_id || undefined}
                            className="w-100"
                            options={zoomAccountOptions}
                          />
                        </Col>
                      )}
                    <Col className="col-auto">
                      {userData?.data?.roleData?.[0]?.role_permission?.[16]
                        ?.draftedZoomMeeting?.publish &&
                        !(
                          userData?.data?.roleData?.[0]?.role_id ===
                          ACCOUNT_AND_FINANCE_ID
                        ) &&
                        ![
                          allResult?.approve_by_user1,
                          allResult?.approve_by_user2,
                        ]?.includes(userData?.data?._id) && (
                          <div className="text-end">
                            <Button
                              onClick={() => {
                                setOpenConfirm(true);
                              }}
                              className="btn btn-responsive expertButtonNext"
                              size="large"
                              htmlType="submit"
                            >
                              Approve
                            </Button>
                          </div>
                        )}
                      {userData?.data?.roleData?.[0]?.role_permission?.[16]
                        ?.draftedZoomMeeting?.publish &&
                        userData?.data?.roleData?.[0]?.role_id ===
                        ACCOUNT_AND_FINANCE_ID &&
                        allResult?.approve_by_user1 &&
                        ![
                          allResult?.approve_by_user1,
                          allResult?.approve_by_user2,
                        ]?.includes(userData?.data?._id) && (
                          <div className="text-end">
                            <Button
                              onClick={() => {
                                setOpenConfirm(true);
                              }}
                              className="btn btn-responsive expertButtonNext"
                              size="large"
                              htmlType="submit"
                            >
                              Approve
                            </Button>
                          </div>
                        )}
                      {/* {userData?.data?.roleData?.[0]?.role_permission?.[16]
                                                ?.draftedZoomMeeting?.publish &&
                                                ![
                                                    allResult?.approve_by_user1,
                                                    allResult?.approve_by_user2,
                                                ]?.includes(userData?.data?._id) && (
                                                    <div className="text-end">
                                                        <Button
                                                            onClick={() => {
                                                                setOpenConfirm(true);
                                                            }}
                                                            className="btn btn-responsive expertButtonNext"
                                                            size="large"
                                                            htmlType="submit"
                                                        >
                                                            Approve
                                                        </Button>
                                                    </div>
                                                )} */}
                    </Col>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <ConfirmationModal
        open={openConfirm}
        setOpen={() => {
          setOpenConfirm(!openConfirm);
        }}
        okFunction={async () => {
          await updateDraftMeeting("approve");
          GetZoomList({ page: 1, key: "" });
          setOpen(false);
          setOpenConfirm(false);
        }}
        cancelFunction={() => {
          setOpenConfirm(false);
        }}
        message={"Are you sure?"}
        title={"Approve Meeting"}
      />
    </>
  );
}

export default ViewZoomMeetingDrawer;
