import { useCallback, useMemo, useRef } from 'react'
import API_URL from '../../../../Constants/api-path';
import useSWR from 'swr';
import { clearAll, setCountData, setDocumentList, setIndustryOptions, setIsLoadingDelete, setIsLoadingSubmit, setIsOpenDelete, setKey, setOpenAddDocumentModal, setPage, setSubIndustryOptions } from '../../../../features/documentList.slice';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Form, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const useDocumentList = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const onlyOnce = useRef(false)
    const userData = JSON.parse(localStorage.getItem("userData"));
    const { formvalues, fileList, page, limit, selectedDoc, key } = useSelector(
        (state) => state.documentList
    );

    const getSubIndustryList = useCallback(async (industOption) => {
        let object = {};
        if (industOption) {
            object.key = `${industOption}`;
        }

        const queryParams = new URLSearchParams(object);
        try {
            const res = await fetch(
                `${API_URL.ADD_EXPERT_INDUSTRY
                }?${queryParams?.toString()}&page=1&limit=30`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const result = await res.json();
            return result
        } catch (error) {
            console.error("Industry Error", error);
            return error
        }
    }, []);

    const getIndustryList = useCallback(async (subIndustryOption) => {
        try {
            const res = await fetch(`${API_URL.ADD_EXPERT_CATEGORIES}?limit=50`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            return result

        } catch (error) {
            return error
        };
    }, [userData?.token])
    const getDocumentList = useCallback(async ({ dpage, dlimit, key }) => {
        try {
            const res = await axios.get(`${API_URL.UPLOAD_DOCUMENT}?page=${dpage}&limit=${dlimit}&search=${key}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            return res

        } catch (error) {
            return error
        };
    }, [userData?.token])


    const { isLoadingSubIndustry, } = useSWR(["get_sub_industry_list"], () => getSubIndustryList(""), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            dispatch(setSubIndustryOptions(result?.data));
        },
        onError: (err) => {
            console.error("Industry ERROR", err);
        }
    })
    const { isLoading } = useSWR(["get_industry_list"], () => getIndustryList(""), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            dispatch(setIndustryOptions(result?.data));
        },
        onError: (err) => {
            console.error("Industry ERROR", err);
        }
    })
    const { isLoading: isLoadingDocList } = useSWR(["get_secondary_doc_list", page, limit, key], () => getDocumentList({ dpage: page, dlimit: limit, key: key }), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            dispatch(setDocumentList(result?.data?.data));
            dispatch(setCountData(result?.data?.countData));
        },
        onError: (err) => {
            console.error("Industry ERROR", err);
        }
    })

    const refetchDocList = useCallback(async () => {
        let res = await getDocumentList({ dpage: page, dlimit: limit, key: "" })
        dispatch(setDocumentList(res?.data?.data));
        dispatch(setCountData(res?.data?.countData));
    }, [dispatch, getDocumentList, limit, page])

    const fetchIndustryList = useCallback(
        async (key = "") => {
            let object = {};

            if (key) {
                object.key = `${key}`;
            }

            const queryParams = new URLSearchParams(object);

            try {
                const res = await fetch(`${API_URL.ADD_EXPERT_CATEGORIES}?${queryParams?.toString()}&limit=50`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });

                const result = await res.json();
                dispatch(setIndustryOptions(result?.data));

            } catch (error) {
                return error
            };
        },
        [dispatch, userData?.token]
    );

    const fetchSubIndustryList = useCallback(
        async (key = "") => {
            let object = {};
            if (key) {
                object.key = `${key}`;
            }

            const queryParams = new URLSearchParams(object);
            try {
                const res = await fetch(
                    `${API_URL.ADD_EXPERT_INDUSTRY
                    }?${queryParams?.toString()}&page=1&limit=30`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                const result = await res.json();
                dispatch(setSubIndustryOptions(result?.data));
            } catch (error) {
                console.error("Industry Error", error);

            }
        },
        [dispatch]
    );
    const uploadDocument = useCallback(async () => {
        dispatch(setIsLoadingSubmit(true))
        const formData = new FormData()
        formData.append("title", formvalues?.title)
        formData.append("industry", JSON.stringify(formvalues?.industry))
        formData.append("country", JSON.stringify(formvalues?.country))
        formData.append("region", JSON.stringify(formvalues?.region))
        formData.append("sub_industry", JSON.stringify(formvalues?.subIndustry))
        formData.append("keyword", JSON.stringify(formvalues?.keywords))
        formData.append("admin_id", userData?.data?._id)
        formData.append("document", fileList?.[0]?.originFileObj)
        try {
            let res = await axios.post(`${API_URL.UPLOAD_DOCUMENT}`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(setOpenAddDocumentModal(false));
                dispatch(clearAll());
                dispatch(setIsLoadingSubmit(false))
                refetchDocList()
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Meeting Data Uploaded Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);

        }
    }, [dispatch, fileList, form, formvalues?.country, formvalues?.industry, formvalues?.keywords, formvalues?.region, formvalues?.subIndustry, formvalues?.title, refetchDocList, userData?.data?._id, userData?.token])

    const updateDocument = useCallback(async (id) => {
        dispatch(setIsLoadingSubmit(true))
        const formData = new FormData()
        formData.append("title", formvalues?.title)
        formData.append("industry", JSON.stringify(formvalues?.industry))
        formData.append("country", JSON.stringify(formvalues?.country))
        formData.append("region", JSON.stringify(formvalues?.region))
        formData.append("sub_industry", JSON.stringify(formvalues?.subIndustry))
        formData.append("keyword", JSON.stringify(formvalues?.keywords))
        formData.append("admin_id", userData?.data?._id)
        if (fileList?.[0]?.originFileObj) {
            formData.append("document", fileList?.[0]?.originFileObj)
        }
        try {
            let res = await axios.put(`${API_URL.UPLOAD_DOCUMENT}/${id}`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(setOpenAddDocumentModal(false));
                dispatch(clearAll());
                dispatch(setIsLoadingSubmit(false))
                refetchDocList()
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Document Updated Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);

        }
    }, [dispatch, fileList, form, formvalues?.country, formvalues?.industry, formvalues?.keywords, formvalues?.region, formvalues?.subIndustry, formvalues?.title, refetchDocList, userData?.data?._id, userData?.token])

    const handleDocDelete = useCallback(async () => {
        dispatch(setIsLoadingDelete(true))
        try {
            let res = await axios.delete(`${API_URL.UPLOAD_DOCUMENT}/${selectedDoc?._id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(setOpenAddDocumentModal(false));
                dispatch(clearAll());
                dispatch(setIsLoadingDelete(false))
                dispatch(setIsOpenDelete(false))
                refetchDocList()
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Document Deleted Successfully!",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }
        } catch (error) {
            dispatch(setIsLoadingDelete(false))
            console.error('error: ', error);

        }
    }, [dispatch, form, refetchDocList, selectedDoc?._id, userData?.token])

    const debounceFetchIndustries = useMemo(() => {
        let loadOptions = async (value) => {
            let res = await fetchIndustryList(value);
        }
        return debounce(loadOptions, 800)
    }, [fetchIndustryList])

    const debounceFetchSubIndustries = useMemo(() => {
        let loadOptions = async (value) => {
            let res = await fetchSubIndustryList(value);
        }
        return debounce(loadOptions, 800)
    }, [fetchSubIndustryList])

    const debounceKey = useMemo(() => {
        let loadOptions = async (value) => {
            dispatch(setPage(1))
            dispatch(setKey(value))
        }
        return debounce(loadOptions, 800)
    }, [dispatch])


    const values = useMemo(
        () => ({
            debounceFetchSubIndustries, debounceFetchIndustries,
            isLoading, isLoadingSubIndustry, uploadDocument, form, isLoadingDocList, handleDocDelete,
            updateDocument, onlyOnce, debounceKey
        }),
        [debounceFetchIndustries, debounceFetchSubIndustries, form, isLoading, isLoadingDocList, isLoadingSubIndustry, uploadDocument, handleDocDelete, updateDocument, onlyOnce, debounceKey]
    );
    return values;
}

export default useDocumentList
