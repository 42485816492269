import React from 'react';

import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, notification, Pagination, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { BsEyeFill } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
import { setCallPage, setIsOpen, setQcExpertList } from "../../features/QCExpert.slice";

const QCExpertProfile = () => {
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState();
    const userData = JSON.parse(localStorage.getItem("userData"));

    const [loading, setLoading] = useState(false);
    const { qcExpertList, callPage } = useSelector((state) => state.qcMeeting)
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch()
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    let history = useNavigate();
    const [filteredClientList, setFilteredClientList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({
        key: "",
    });


    const columns = [
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Contact Number",
            dataIndex: "contact_number",
            key: "contact_number",
            render: (text, record) => {
                // Assuming you have a "country_code" field in your data
                const countryCode = record?.contact_code;

                // Add + if not present
                const formattedCountryCode =
                    countryCode && !countryCode.includes("+")
                        ? `+${countryCode === null ? "" : countryCode}`
                        : countryCode === null
                            ? ""
                            : countryCode;

                // Combine the country code and contact number
                const combinedNumber = `${formattedCountryCode} ${text}`;

                return <span>{combinedNumber}</span>;
            },
        },

        {
            title: "Linkedin Url",
            dataIndex: "linkendin_url",
            key: "linkendin_url",
            render: (text, record) => {
                return record.linkendin_url
                    ? record.linkendin_url
                    : record.linkedin_url;
            },
        },
        {
            title: "Action",
            dataIndex: "_id",
            key: "_id",
            align: "center",
            render: (_id) => (
                <Tooltip placement="top" title={"View"} color="#5746EB">
                    <span className='d-flex align-items-center justify-content-center'>
                        <Link
                            size="large"
                            className="viewBttnExpert"
                            to={`/dashboard/temp-expert-profile?expertId=${_id}`}
                        >
                            <BsEyeFill />
                        </Link>
                    </span>
                </Tooltip>
                // <Link
                //     style={{
                //         fontWeight: 600,
                //         color: "rgb(87, 70, 236)",
                //     }}
                //     to={`/dashboard/temp-expert-profile?expertId=${_id}`}
                // >
                //     {" "}
                //     View
                // </Link>
            ),
        },
    ];


    const paginationSettings = {
        current: currentPage,
        onChange: handlePageChange,
        pageSize: 10,
        total: callPage,
    };


    useEffect(() => {
        const filteredData = qcExpertList?.filter((record) =>
            Object.values(record)
                .join("")
                .toLowerCase()
                .includes(searchText.toLowerCase())
        );
        setFilteredClientList(filteredData);

        setPagination({
            ...pagination,
            current: 1,
            total: filteredData.length,
        });
    }, [qcExpertList, searchText]);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setSelectedOptions({
            key: inputValue,
        });
        setSearchText(inputValue);
        setCurrentPage(1);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const GetQcList = async (page, selectedOptions) => {
        setLoading(true);

        try {
            let object = {};

            if (selectedOptions?.key) {
                object.key = selectedOptions?.key;
            }
            const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

            const res = await fetch(
                `${API_PATH.Qc_EXPERT_LIST
                }?page=${page}&limit=10&${queryParams?.toString()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );

            const result = await res.json();
            if (result) {
                dispatch(setQcExpertList(result?.data));
                dispatch(setCallPage(result?.countData));
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            setLoading(false);
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    };

    const constructUrl = (options) => {
        const { key } = options;

        const projectParam = Array.isArray(key) ? key.join(", ") : key;
        const sanitizedProject = projectParam
            ? projectParam.replace(/%20/g, " ")
            : "";

        const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

        return history({
            pathname: `/dashboard/qc_experts`,
            search: `?${urlParams.join("&")}`,
        });
    };

    useEffect(() => {
        GetQcList(currentPage, selectedOptions);
        const url = constructUrl(selectedOptions);
    }, [currentPage, selectedOptions]);


    return (
        <div className="bk-div float-start w-100">
            {" "}
            {/* <hr className="sp-hr" /> */}
            {/* <div className="body-expart-div"> */}
            <div className="d-flex justify-content-between align-items-center">
                <h2>QC Experts</h2>
                <button
                    className="btn viewButton"
                    onClick={() => dispatch(setIsOpen(true))}
                    size="large"
                >
                    Update Phantom key
                </button>
            </div>

            <Input
                placeholder="Search"
                value={selectedOptions?.key}
                onChange={handleInputChange}
                prefix={<SearchOutlined />}
                style={{ marginBottom: 16 }}
                size="large"
            />
            <Table
                dataSource={filteredClientList}
                columns={columns}
                pagination={false}
                onChange={handleTableChange}
                scroll={{ x: 600 }}
                size="large"
                loading={loading}
            />
            <Pagination
                {...paginationSettings}
                showSizeChanger={false}
                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
                showTotal={(callPage, range) =>
                    `${range[0]}-${range[1]} of ${callPage} items`
                }
            />
        </div>
    )
}

export default QCExpertProfile