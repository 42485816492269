import { CheckCircleOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Pagination,
  Select,
  Table,
  Tag,
  Tooltip,
  notification
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-h5-audio-player/lib/styles.css";
import { MdEdit, MdOutlineClose } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
import { countryOptions } from "../../Constants/CountryOptions";
import UpdateZoomMeetingModal from "./UpdateZoomMeetingModal";

const ZoomPageTable = () => {
  let history = useNavigate();
  const [form] = Form.useForm();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);
  const [editZoomModal, setEditZoomModal] = useState(false);

  const [zoomId,] = useState("");
  const [zoomList, setZoomList] = useState([]);
  const [zoomAccountList, setZoomAccountList] = useState([]);
  const [zoomCompanyList, setZoomCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewId, setViewId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [phone, setPhone] = useState("");
  const [companyOption, setFilteredCompany] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({
    key: "",
    sortType: "",
    sortBy: "",
  });
  const [zoomData, setZoomData] = useState({
    zoomId: "",
    meetingId: "",
    startDate: "",
    passcode: "",
    startTime: "",
    endDate: "",
    endTime: "",
    comapnyName: "",
    client: "",
    client_user: [],
    client_user_id: [],
    client_id: viewId,
    zoommeeting_id: "",
    expertCompany: "",
    expertDesignation: "",
  });

  const [clienData, setClienData] = useState({
    name: "",
    email: "",
    comapnyName: "",
    contact_number: "",
    country: "",
    contact_code: "",
    role_id: "",
    registrationType: "",
  });
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const callOnce = useRef(false);

  const [clientView, setClientView] = useState([]);

  const GetZoomList = useCallback(
    async ({
      page = currentPage,
      key = selectedOptions.key,
      sortBy = selectedOptions.sortBy,
      sortType = selectedOptions.sortType,
    }) => {
      setLoading(true);
      try {
        let object = {};
        if (key) {
          object.key = key;
        }
        const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

        const res = await fetch(
          `${API_PATH.ZOOM_LIST
          }?page=${page}&limit=10&sortBy=${sortBy}&sortType=${sortType}${queryParams?.toString() !== "" ? "&" + queryParams?.toString() : ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setZoomList(result?.data);
          setCallPage(result?.countData);
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } catch (error) {
        setLoading(false);

        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [
      currentPage,
      selectedOptions.key,
      selectedOptions.sortBy,
      selectedOptions.sortType,
      userData?.token,
    ]
  );
  const handlePageChange = useCallback(
    async (pageNumber) => {
      setCurrentPage(pageNumber);
      await GetZoomList({ page: pageNumber });
    },
    [GetZoomList]
  );

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };
  // useEffect(() => {
  //   const filteredData = zoomList?.filter((record) =>
  //     Object.values(record)
  //       .join("")
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase())
  //   );
  //   setFilteredClientList(filteredData);
  //   setPagination({
  //     ...pagination,
  //     current: 1,
  //     total: filteredData.length,
  //   });
  // }, [zoomList, searchText, pagination]);

  const GetZoomAccountList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.ZOOM_ACCOUNT_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setZoomAccountList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const GetZoomCompanyList = useCallback(async () => {
    setLoading(true);

    let object = {};

    if (companyOption) {
      object.key = `${companyOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        // `${API_PATH.CLIENT_LIST}?page=1&limit=10&${queryParams.toString()}`,
        `${API_PATH.CLIENT_LIST}?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setZoomCompanyList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [companyOption, userData?.token]);

  const GetRoleList = useCallback(async () => {
    try {
      const res = await fetch(`${API_PATH.CLIENT_ROLE}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setRoleList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const constructUrl = useCallback(
    (options) => {
      const { key } = options;

      const projectParam = Array.isArray(key) ? key.join(", ") : key;
      const sanitizedProject = projectParam
        ? projectParam.replace(/%20/g, " ")
        : "";

      const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

      return history({
        pathname: `/dashboard/zoom-meetings`,
        search: `?${urlParams.join("&")}`,
      });
    },
    [history]
  );

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetZoomList(currentPage, selectedOptions);
      GetZoomAccountList();
      GetZoomCompanyList();
      GetRoleList();
      constructUrl(selectedOptions);
    }
  }, [
    currentPage,
    selectedOptions,
    companyOption,
    GetZoomList,
    GetZoomAccountList,
    GetZoomCompanyList,
    GetRoleList,
    constructUrl,
  ]);

  const handleNameChange = useCallback(
    async (value, option) => {
      const selectedCompanyId = option?.key;
      const selectedDomain = option?.domain;
      setViewId(selectedCompanyId);

      zoomData.client_id = selectedCompanyId;
      zoomData.comapnyName = selectedDomain;

      const selectValue = value;
      setZoomData({ ...zoomData, client: selectValue });

      setFilteredCompany(value || "");
      if (!value) {
        GetZoomCompanyList();
      }

      try {
        const res = await fetch(
          `${API_PATH.CLIENT_VIEW}/${selectedCompanyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setClientView(result?.data);
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle error
      }
    },
    [GetZoomCompanyList, userData?.token, zoomData]
  );

  // const AddZoomMeeting = useCallback(async () => {
  //   // e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const meetingDetails = await axios.post(
  //       API_PATH.FETCH_MEETING_FROM_ZOOM,
  //       {
  //         zoomId: zoomData?.zoomId,
  //         meetingId: zoomData?.meetingId?.replace(/\s/g, ""),
  //       }
  //     );
  //     const details = meetingDetails?.data?.data;
  //     const formattedZoomData = {
  //       ...zoomData,
  //       meetingId: zoomData?.meetingId?.replace(/\s/g, ""),
  //       passcode: details?.password,
  //       expert_designation: zoomData?.expertDesignation,
  //       expert_company: zoomData?.expertCompany,
  //       startDate: moment(details?.start_time).format("YYYY-MM-DD"),
  //       startTime: moment(details?.start_time).format("HH:mm"),
  //       endDate: moment(details?.start_time)
  //         .add(details?.duration, "minutes")
  //         .format("YYYY-MM-DD"),
  //       endTime: moment(details?.start_time)
  //         .add(details?.duration, "minutes")
  //         .format("HH:mm"),
  //       topic: details?.topic,
  //     };
  //     const res = await fetch(`${API_PATH.ZOOM_ADD_MEETING}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //       body: JSON.stringify(formattedZoomData),
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       GetZoomList({ page: 1, key: "" });
  //       setSelectedOptions({
  //         key: "",
  //         sortBy: "",
  //         sortType: "",
  //       });
  //       setCurrentPage(1);
  //       if (res.status === 200 || res.status === true) {
  //         form.resetFields();
  //         form.setFieldsValue({
  //           dateAndTime: [],
  //         });
  //         setZoomData({
  //           zoomId: "",
  //           meetingId: "",
  //           startDate: "",
  //           passcode: "",
  //           startTime: "",
  //           endDate: "",
  //           endTime: "",
  //           comapnyName: "",
  //           client: "",
  //           client_user: [],
  //           client_user_id: [],
  //           client_id: viewId,
  //           zoommeeting_id: "",
  //           expertCompany: "",
  //           expertDesignation: "",
  //         });
  //         setDisplayExpert(!displayExpert);
  //         notification.success({
  //           message: "Zoom meeting created",
  //           icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
  //           style: {
  //             backgroundColor: "#2ecc71",
  //             color: "#fff !important",
  //             border: "1px solid #52c41a",
  //           },
  //           duration: 5,
  //           placement: "topRight",
  //         });

  //         // window.location.reload();
  //       }
  //     } else {
  //       setDisplayExpert(!displayExpert);
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setDisplayExpert(!displayExpert);
  //     notification.error({
  //       message: "Error: Something went wrong server error",
  //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //       style: {
  //         backgroundColor: "#e74c3c",
  //         color: "#fff",
  //         border: "1px solid #c0392b",
  //       },
  //       duration: 5,
  //       placement: "topRight",
  //     });
  //   }
  //   setLoading(false);
  // }, [GetZoomList, displayExpert, form, userData?.token, viewId, zoomData]);

  const AddClientData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.CLIENT_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(clienData),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          GetZoomCompanyList();
          handleNameChange();
          notification.success({
            message: "Client created",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        } else if (
          result.status === false &&
          result.message === "Email is already exists."
        ) {
          notification.error({
            message: "Email is already exists.",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetZoomCompanyList, clienData, handleNameChange, userData?.token]);

  const DeleteZoomMeeting = useCallback(async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.ZOOM_DELETE_MEETING}/${zoomId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        GetZoomList({ page: 1, key: "" });
        setCurrentPage(1);
        if (res.status === 200 || res.status === true) {

          notification.success({
            message: "Successfully deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetZoomList, userData?.token, zoomId]);

  // const handleInputs = useCallback(
  //   (e) => {
  //     const name = e.target.name;
  //     const value = e.target.value;

  //     setZoomData({ ...zoomData, [name]: value });
  //   },
  //   [zoomData]
  // );

  const handleInputsClient = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setClienData({ ...clienData, [name]: value });
    },
    [clienData]
  );

  // const handleChange = useCallback(
  //   (value) => {
  //     const selectValue = value;
  //     setZoomData({ ...zoomData, zoomId: selectValue });
  //   },
  //   [zoomData]
  // );

  // const handleNameChangeClient = useCallback((values, options) => {
  //   const updatedClientUser = values;
  //   const updatedClientUserId = options
  //     ?.map((option) => option?.key)
  //     ?.filter(Boolean);

  //   setZoomData((prevData) => ({
  //     ...prevData,
  //     client_user: updatedClientUser,
  //     client_user_id: updatedClientUserId,
  //   }));
  // }, []);

  // const getZoomId = useCallback((_id) => {
  //   setZoomId(_id);
  // }, []);

  // const dataSource = filteredClientList?.map((data, index) => ({
  const dataSource = zoomList?.map((data, index) => ({
    meetingId: data?.meetingId,
    comapnyName: data?.client,
    startDate:
      data.startDate && data.startTime ? (
        <>
          <div>{moment(data?.startDate)?.format("DD MMM YYYY")}</div>
          <div className="text-muted" style={{ fontSize: "12px" }}>
            {data?.startTime} - {data?.endTime}
          </div>
        </>
      ) : (
        "-"
      ),

    assembly_response_status: (
      <>
        <Tooltip
          color="#5746EB"
          title={`${!data?.transcriptStatus
            ? "Waiting for recording"
            : !data?.assembly_response_status && data?.transcriptStatus
              ? "Transcript is in process"
              : "Transcript Generated"
            }`}
        >
          <Tag
            color={
              !data?.transcriptStatus
                ? "red"
                : !data?.assembly_response_status && data?.transcriptStatus
                  ? "yellow"
                  : "green"
            }
          >
            {!data?.transcriptStatus
              ? "W"
              : !data?.assembly_response_status && data?.transcriptStatus
                ? "P"
                : "S"}
          </Tag>
        </Tooltip>
        <Tooltip
          color="#5746EB"
          title={`${data?.transcriptStatus
            ? "Recording Downloaded"
            : "Waiting for Recording"
            }`}
        >
          <Tag color={data?.transcriptStatus ? "green" : "red"}>
            {data?.transcriptStatus ? "S" : "W"}
          </Tag>
        </Tooltip>
        <Tooltip
          color="#5746EB"
          title={`${data?.email_sent
            ? "Email Sent"
            : "No Email Sent"
            }`}
        >
          <Tag color={data?.email_sent ? "green" : "red"}>
            {data?.email_sent ? "S" : "W"}
          </Tag>
        </Tooltip>
      </>
    ),
    _id: data?._id,
  }));
  const setUserModal = useCallback(({ data, id }) => {
    const res = data?.find((val) => val._id === id);
    setZoomData({
      zoomId: res?.zoomId,
      meetingId: res?.meetingId,
      startDate: res?.startDate,
      passcode: res?.passcode,
      startTime: res?.startTime,
      endDate: res?.endDate,
      endTime: res?.endTime,
      comapnyName: res?.comapnyName,
      expertCompany: res?.expert_company,
      expertDesignation: res?.expert_designation,
      client: res?.client,
      client_user: res?.client_user,
      client_user_id: res?.client_user_id,
      client_id: res?.client_id,
      topic: res?.topic,
      dateAndTime: [
        dayjs(`${res?.startDate} ${res?.startTime}`, "YYYY-MM-DD HH:mm"),
        dayjs(`${res?.endDate} ${res?.endTime}`, "YYYY-MM-DD HH:mm"),
      ],
      zoommeeting_id: res?._id,
      // client_id: viewId,
    });
    setViewId(res?.client_id);
    setFilteredCompany(res?.client || "");
    // setUpdateMangerId(id);
  }, []);

  const columns = [
    {
      title: "S.No",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Meeting Id",
      dataIndex: "meetingId",
      key: "meetingId",
      width: "140px",
    },
    {
      title: "Client Name",
      dataIndex: "comapnyName",
      key: "comapnyName",
      sorter: true,
      width: "auto",
    },
    {
      title: "Date/Time",
      dataIndex: "startDate", // ["startDate", "startTime"],
      key: "sname",
      sorter: true,
      width: "140px",
    },
    {
      title: "Transcript",
      dataIndex: "assembly_response_status",
      key: "assembly_response_status",
      // render: (record) => {
      //   const color =
      //     record === "Success"
      //       ? "green"
      //       : record === "Processing"
      //       ? "yellow"
      //       : "red";
      //   return <Tag color={color}>{record}</Tag>;
      // },
      width: "100px",
    },
    // {
    //   title: "Recording",
    //   dataIndex: "transcriptStatus",
    //   key: "transcriptStatus",
    //   render: (record) => {
    //     const color =
    //       record === "Success"
    //         ? "green"
    //         : record === "Processing"
    //         ? "yellow"
    //         : "red";
    //     return <Tag color={color}>{record}</Tag>;
    //   },
    //   width: "100px",
    // },
    {
      title: "Action",
      dataIndex: "_id",
      key: "add",
      align: "center",
      render: (_id) => (
        <div className="d-flex expertActions align-items-center justify-content-center gap-1">
          <Tooltip placement="top" title={"Edit"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  setEditZoomModal(true);
                  setUserModal({ data: zoomList, id: _id });
                }}
              >
                <MdEdit />
              </button>
            </span>
          </Tooltip>
          {/* 
          <Tooltip placement="top" title="Delete" color="#5746EB">
            <button
              size="large"
              className="viewBttnExpert"
              onClick={() => {
                setDeleteModal(true);
                getZoomId(_id);
              }}
            >
              <MdDelete />
            </button>
          </Tooltip> */}
        </div>
        // <Space size="middle">
        //   <Tooltip color="#5746EB" title="Edit" placement="top">
        //     <MdBorderColor
        //       onClick={() => {
        //         setEditZoomModal(true);
        //         setUserModal({ data: zoomList, id: _id });
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     ></MdBorderColor>
        //   </Tooltip>
        //   <Tooltip color="#5746EB" title="Delete" placement="top">
        //     <MdDelete
        //       onClick={() => {
        //         setDeleteModal(true);
        //         getZoomId(_id);
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>
        // </Space>
      ),
      width: "100px",
    },
  ];

  const handleTableChange = useCallback(
    async (pagination, filters, sorter) => {
      setCurrentPage(1);
      setSelectedOptions({
        ...selectedOptions,
        sortBy: sorter?.field,
        sortType: sorter?.order === "ascend" ? "1" : "-1",
      });
      await GetZoomList({
        page: 1,
        sortBy: sorter?.field,
        sortType: sorter?.order === "ascend" ? "1" : "-1",
      });
    },
    [GetZoomList, selectedOptions]
  );

  // const onChange = useCallback(
  //   (dates, dateStrings) => {
  //     const [startDate, startTime] = dateStrings[0].split(" ");
  //     const [endDate, endTime] = dateStrings[1].split(" ");

  //     setZoomData({
  //       ...zoomData,
  //       startDate: startDate,
  //       startTime: startTime,
  //       endDate: endDate,
  //       endTime: endTime,
  //     });
  //   },
  //   [zoomData]
  // );

  // const onOk = (value) => {
  // };

  const handleInputChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      setSelectedOptions({
        ...selectedOptions,
        key: inputValue,
      });
      setCurrentPage(1);
      GetZoomList({ page: 1, key: inputValue });
    },
    [GetZoomList, selectedOptions]
  );

  const handlePhoneChange = useCallback((value, country) => {
    setPhone(value);
    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );
    if (selectedCountry) {
      setClienData((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        phoneNumber: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));
    } else {
    }
  }, []);

  const handleSelectChange = useCallback(
    (value) => {
      const selectValue = value;
      setClienData({ ...clienData, role_id: selectValue });
    },
    [clienData]
  );

  const handleSelectChangeMoveTo = useCallback(
    (value) => {
      const selectValue = value;
      setClienData({ ...clienData, registrationType: selectValue });
    },
    [clienData]
  );

  const setFilteredData = useCallback(
    (value) => {
      if (!value?.trim()) {
        setFilteredCompany("");
        GetZoomCompanyList();
      } else {
        setFilteredCompany(value);
      }
    },
    [GetZoomCompanyList]
  );

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Zoom Meeting</h2>
              {/* <Link
                to={'/dashboard/draft-zoom-meetings/add-meeting'}
                className="btn viewButton"
                onClick={() => {
                  form.resetFields();
                  setZoomData({
                    zoomId: "",
                    meetingId: "",
                    startDate: "",
                    passcode: "",
                    startTime: "",
                    endDate: "",
                    endTime: "",
                    comapnyName: "",
                    client: "",
                    client_user: [],
                    client_user_id: [],
                    client_id: viewId,
                    zoommeeting_id: "",
                  });
                  // setDisplayExpert(!displayExpert);

                }}
                size="large"
              >
                Add Meeting
              </Link> */}
            </div>
            {/* 
            {displayExpert ? (
              // <AddMeetingForm />
              <>
                <div className="row d-flex justify-content-center align-items-center py-4">
                  <div className="col-md-10">
                    <div className="card bg-white p-4">
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={() => {
                          AddZoomMeeting();
                        }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item label="Select Zoom Account">
                              <Select
                                size="large"
                                placeholder="Select Zoom Account"
                                onChange={handleChange}
                                value={zoomData?.zoomId}
                                options={zoomAccountList?.map((item) => ({
                                  label: item?.email,
                                  value: item?._id,
                                }))}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Meeting Id">
                              <Input
                                size="large"
                                placeholder="89223750266"
                                name="meetingId"
                                value={zoomData?.meetingId}
                                onChange={handleInputs}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Expert Designation"
                              name="expertDesignation"
                              rules={[
                                {
                                  required: true,
                                  message: "Expert designation is required!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Expert Designation"
                                name="expertDesignation"
                                value={zoomData.expertDesignation}
                                onChange={handleInputs}
                                // onChange={(e) => {
                                //   let obj = {
                                //     ...formvalues,
                                //     meetingId: e.target.value,
                                //   };
                                //   dispatch(setFormValues(obj));
                                //   form.setFieldValue("meetingId", e.target.value);
                                // }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Expert Company"
                              name="expertCompany"
                              rules={[
                                {
                                  required: true,
                                  message: "Expert company is required.",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Expert Company"
                                name="expertCompany"
                                value={zoomData.expertCompany}
                                onChange={handleInputs}
                                // onChange={(e) => {
                                //   let obj = {
                                //     ...formvalues,
                                //     meetingId: e.target.value,
                                //   };
                                //   dispatch(setFormValues(obj));
                                //   form.setFieldValue("meetingId", e.target.value);
                                // }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item name="client" label="Client">
                              <Select
                                showSearch
                                size="large"
                                placeholder="Select Client"
                                onChange={handleNameChange}
                                name="client"
                                value={zoomData?.client}
                                onSearch={setFilteredData}
                                // optionFilterProp="children"
                                options={zoomCompanyList
                                  ?.filter((item) => item?.name)
                                  .map((item) => ({
                                    label: item?.name,
                                    value: item?.name,
                                    key: item?._id,
                                    domain: item?.domain,
                                  }))}
                                virtual={false}
                                allowClear
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item name="client_user" label="Client User">
                              <Select
                                size="large"
                                placeholder="Select Client User"
                                name="client_user"
                                mode="multiple"
                                onChange={(values, options) =>
                                  handleNameChangeClient(values, options)
                                }
                                value={zoomData?.client_user}
                                options={clientView?.map((item) => ({
                                  label: item?.name,
                                  value: item?.name,
                                  key: item?._id,
                                }))}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: "8px 0",
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: "0 8px 4px",
                                      }}
                                    >
                                      <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => setCreateModal(true)}
                                      >
                                        Client user
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                virtual={false}
                                allowClear
                              />
                            </Form.Item>
                          </div>

                          {/* <div className="col-md-6">
                            <Form.Item label="Meeting Passcode">
                              <Input
                                size="large"
                                placeholder="819302"
                                name="passcode"
                                value={zoomData?.passcode}
                                onChange={handleInputs}
                              />
                            </Form.Item>
                          </div> */}

            {/* <div className="col-md-6">
                            <Form.Item name="dateAndTime" label="Date Time">
                              <RangePicker
                                size="large"
                                showTime={{
                                  format: "HH:mm",
                                }}
                                name="dateAndTime"
                                format="YYYY-MM-DD HH:mm"
                                onChange={onChange}
                                onOk={onOk}
                              />
                            </Form.Item>
                          </div> */}

            {/* <div className="d-flex justify-content-center align-items-center">
              <Form.Item>
                <button
                  className="viewButton btn"
                  type="submit"
                // onClick={AddZoomMeeting}
                >
                  Submit
                </button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div > */}
            {/* </div >
        </>
            ) : null} * /} */}

            < Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={< SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />

            <Table
              // dataSource={filteredClientList}
              dataSource={dataSource}
              columns={columns}
              className="paddTable"
              loading={loading}
              pagination={false}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div >
        </div >
      </div >

      <div
        className="offcanvas ttt offcanvas-end"
        tabIndex="-1"
        id="submitted"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* {loading1 ? ( */}
        <>
          <div
            className="offcanvas-header align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid rgb(197, 195, 195)",
            }}
          >
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h5 id="offcanvasRightLabel">Add New Manager</h5>
          </div>
          <div className="offcanvas-body">
            <div className="experien-list mt-4">
              <div>
                <ul className="nextyn_list_items">
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">From:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">To:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Through:</span>
                      <div className="companyName_1dSwA">+17182858858</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Reference Id:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Total Talk Time:</span>
                      <div className="companyName_1dSwA">s</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Start Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">End Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Transcription:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        {/* ) : (
            ""
          )} */}
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Delete Meeting</h4>
          </div>
          <div>
            This action cannot be undone. Are you sure you want to delete this{" "}
            meeting ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <button
              onClick={() => {
                DeleteZoomMeeting();
                setDeleteModal(false);
              }}
              className="viewButton btn ms-2"
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Create Client</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Name">
                    <Input
                      size="large"
                      name="name"
                      value={clienData?.name}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Email">
                    <Input
                      size="large"
                      name="email"
                      value={clienData?.email}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Company Name">
                    <Input
                      size="large"
                      name="companyName"
                      value={zoomData?.companyName}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Contact Number">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={(value, country) => {
                        handlePhoneChange(value, country);
                      }}
                      inputStyle={{
                        width: "100%",
                      }}
                      value={phone}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Role">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.role_id}
                      onChange={handleSelectChange}
                    >
                      {roleList?.map((option, index) => (
                        <Select.Option value={option?._id} key={index}>
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Move to">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.registrationType}
                      onChange={handleSelectChangeMoveTo}
                    >
                      <Select.Option value={"cloud"}>Cloud</Select.Option>
                      <Select.Option value={"client"}>Client</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                AddClientData();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <UpdateZoomMeetingModal
        editZoomModal={editZoomModal}
        setEditZoomModal={setEditZoomModal}
        zoomData={zoomData}
        setZoomData={setZoomData}
        zoomAccountList={zoomAccountList}
        zoomCompanyList={zoomCompanyList}
        clientView={clientView}
        setCreateModal={setCreateModal}
        setFilteredData={setFilteredData}
        setViewId={setViewId}
        setFilteredCompany={setFilteredCompany}
        GetZoomCompanyList={GetZoomCompanyList}
        setClientView={setClientView}
        viewId={viewId}
        GetZoomList={GetZoomList}
      />

      {/* <Modal
        show={editZoomModal}
        onHide={() => setEditZoomModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setEditZoomModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Edit Zoom Meetingssss</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Select Zoom Account">
                    <Select
                      size="large"
                      placeholder="Select Zoom Account"
                      onChange={handleChange}
                      value={zoomData?.zoomId}
                      options={zoomAccountList?.map((item) => ({
                        label: item?.email,
                        value: item?._id,
                      }))}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item label="Meeting Id">
                    <Input
                      size="large"
                      placeholder="Meeting Id"
                      name="meetingId"
                      value={zoomData?.meetingId}
                      onChange={handleInputs}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="client" label="Client">
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select Clientssss"
                      onChange={handleNameChange}
                      name="client"
                      value={zoomData?.client}
                      options={zoomCompanyList
                        ?.filter((item) => item?.name)
                        .map((item) => ({
                          label: item?.name,
                          value: item?.name,
                          key: item?._id,
                          domain: item?.domain,
                        }))}
                      virtual={false}
                      allowClear
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="client_user" label="Client User">
                    <Select
                      size="large"
                      placeholder="Select Client User"
                      name="client_user"
                      mode="multiple"
                      onChange={(values, options) => handleNameChangeClient(values, options)}
                      value={zoomData?.client_user}
                      options={clientView?.map((item) => ({
                        label: item?.name,
                        value: item?.name,
                        key: item?._id,
                      }))}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Button
                              icon={<PlusOutlined />}
                              onClick={() => setCreateModal(true)}
                            >
                              Create client
                            </Button>
                          </Space>
                        </>
                      )}
                      virtual={false}
                      allowClear
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Meeting Passcode">
                    <Input
                      size="large"
                      placeholder="819302"
                      name="passcode"
                      // value={zoomData?.passcode}
                      // onChange={handleInputs}
                      value={zoomData?.passcode}
                      onChange={handleInputs}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="dateAndTime" label="Date Time">
                    <RangePicker
                      size="large"
                      showTime={{
                        format: "HH:mm",
                      }}
                      name="dateAndTime"
                      format="YYYY-MM-DD HH:mm"
                      onChange={onChange}
                      onOk={onOk}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setEditZoomModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                // AddClientData()
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ZoomPageTable;
