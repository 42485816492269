import React, { useState } from 'react';
import { Divider, Input, Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setOpen, setSelectedTransaction } from '../features/creditManagement.slice';
import axios from 'axios';
import API_URL from '../Constants/api-path';
import { Col, Row } from 'react-bootstrap';
const UpdateCreditsModal = ({ getTransactionHistoryById }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { open, selectedTransaction } = useSelector((state) => state.creditManagement)
    const userData = JSON.parse(localStorage.getItem("userData"))

    const dispatch = useDispatch()

    const handleOk = async () => {
        setConfirmLoading(true);
        try {
            const response = await axios
                .post(
                    `${API_URL.HANDLE_CREDIT_UPDATE}`,
                    {
                        transaction_id: selectedTransaction?._id,
                        refund_by: userData?.data?._id,
                        credit_adjusted: selectedTransaction?.credits_spend
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        }
                    }
                )
            if (response?.data?.status) {
                message.success("Credits Adjusted successfully!")
                getTransactionHistoryById({ is_paginating: true, page_limit: 10, page_no: 1 })
                dispatch(setOpen(false))
                setConfirmLoading(false);
            }
        } catch (error) {
            dispatch(setOpen(false))
            setConfirmLoading(false);
        }

    }
    return (
        <>
            <Modal
                centered
                title={<div>
                    Update Credits
                    <Divider className="m-2" />
                </div>}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    dispatch(setOpen(false))
                }}
            >
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col md={3}>Credit Value:</Col>
                    <Col md={8}>
                        <Input
                            type='number'
                            value={selectedTransaction?.credits_spend}
                            onChange={(e) => {
                                let obj = {
                                    ...selectedTransaction,
                                    credits_spend: e.target.value
                                }
                                dispatch(setSelectedTransaction(obj))
                            }}

                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default UpdateCreditsModal;