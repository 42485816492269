import * as React from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { ReorderIcon } from "./Icon";
import { Button, Checkbox, DatePicker, Input, Switch, Col, Row } from "antd";
import dayjs from "dayjs";
import { useRaisedShadow } from "./useRaisedShadow";
import { setInputss } from "../../features/addNewExpertForm.slice";
import { useDispatch } from "react-redux";

export const CurrentCompanyItem = ({ input, index, Form, inputs, form }) => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const dragControls = useDragControls();
    const dispatch = useDispatch()
    const handleInputChange = React.useCallback((value, field, index) => {
        let newArr = [...inputs]
        let newInputss = newArr?.map((input, i) => {
            if (i === index) {
                return {
                    ...input,
                    [field]:
                        (field === "startDate" || field === "endDate") && value
                            ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                            : value, // If value is null (cleared), then set field to null
                };
            }
            return input;
        })
        dispatch(setInputss(newInputss));
    }, [dispatch, inputs]);

    const handleCurrentChange = React.useCallback(
        (checked, index) => {
            const updatedInputs = [...inputs];
            let updatedArr = updatedInputs?.map((inp, ind) => {
                if (ind === index) {
                    return {
                        ...inp,
                        current_show_check: checked ? "yes" : "no"
                    }
                } else {
                    return {
                        ...inp
                    }
                }
            })

            // updatedInputs[index].current_show_check = checked ? "yes" : "no";
            dispatch(setInputss(updatedArr));
        },
        [dispatch, inputs]
    );


    const handleCheckboxChange = React.useCallback((event, index) => {
        const { checked } = event.target;
        let newInputss = inputs.map((input, i) =>
            i === index ? { ...input, till_present: checked ? "yes" : "no" } : input
        )
        dispatch(setInputss(newInputss));
    }, [dispatch, inputs]);

    const handleDeleteInput = React.useCallback(
        (index) => {
            const newInputs = [...inputs];
            newInputs.splice(index, 1);
            dispatch(setInputss(newInputs));
            let formValues = form.getFieldsValue()
            let allKeys = Object.keys(formValues)
            let keys = allKeys?.filter((key) => !isNaN(Number(key)))

            for (let i = index; i < keys.length - 1; i++) {
                formValues[i].current_start_date = formValues[i + 1].current_start_date;
                formValues[i].current_end_date = formValues[i + 1].current_end_date;
            }

            // Remove the last index's fields as they are shifted out
            const lastKey = keys[keys.length - 1];
            delete formValues[lastKey].current_start_date;
            delete formValues[lastKey].current_end_date;
            form.setFieldsValue(formValues)
        },
        [dispatch, form, inputs]
    );
    return (
        <Reorder.Item
            value={input}
            id={input?.id || index}
            style={{ boxShadow, y }}
            dragListener={false}
            dragControls={dragControls}

        >
            <ReorderIcon dragControls={dragControls} />
            <div>
                <div className="d-flex align-items-center justify-content-start mb-2">
                </div>
                <div>
                    <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center"
                    >
                        <Col span={12}>
                            <Input
                                size="large"
                                autoComplete="off"
                                placeholder="Job Title"
                                name="title"
                                value={input?.title}
                                onChange={(event) =>
                                    handleInputChange(
                                        event.target.value,
                                        "title",
                                        index
                                    )
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Input
                                size="large"
                                autoComplete="off"
                                placeholder="Company"
                                name="company"
                                value={input?.company}
                                onChange={(event) =>
                                    handleInputChange(
                                        event.target.value,
                                        "company",
                                        index
                                    )
                                }
                            />
                        </Col>
                    </Row>

                    <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center mt-4"
                    >
                        <Col span={12}>
                            <Form.Item
                                name={[index, "current_start_date"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Start date is required.",
                                    },
                                ]}
                            >
                                <DatePicker
                                    size="large"
                                    style={{ width: "100%" }}
                                    placeholder="Start Date"
                                    name="startDate"
                                    value={
                                        input?.startDate
                                            ? dayjs(input?.startDate, "MM, YYYY")
                                            : null
                                    }
                                    onChange={(value) =>
                                        handleInputChange(value, "startDate", index)
                                    }
                                    picker="month"
                                    format="MM, YYYY" // Include the day of the month in the format
                                    disabledDate={(current) =>
                                        current && current.isAfter(dayjs(), "month")
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={[index, "current_end_date"]}
                                rules={[
                                    {
                                        required: input?.till_present === "yes" ? false : true,
                                        message: "End date is required.",
                                    },
                                ]}
                            >
                                <DatePicker
                                    size="large"
                                    style={{ width: "100%" }}
                                    placeholder="End Date"
                                    name="endDate"
                                    disabled={
                                        input?.endDate && input?.till_present === "yes"
                                            ? false
                                            : input?.till_present === "yes"
                                                ? true
                                                : false
                                    }
                                    value={
                                        input?.endDate
                                            ? dayjs(input?.endDate, "MM, YYYY")
                                            : null
                                    }
                                    onChange={(value) =>
                                        handleInputChange(value, "endDate", index)
                                    }
                                    picker="month"
                                    format="MM, YYYY" // Include the day of the month in the format
                                    disabledDate={(current) =>
                                        current && current.isAfter(dayjs(), "month")
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center mt-4"
                    >
                        <Col span={12} className="">
                            <Switch
                                checked={input?.current_show_check === "yes"}
                                title="Highlight Designation"
                                onChange={(checked) =>
                                    handleCurrentChange(checked, index)
                                }
                            />
                            <span className="ms-3 ">Highlight Designation</span>
                        </Col>

                        <Col span={12}>
                            <Checkbox
                                disabled={
                                    input?.endDate && input?.till_present === "yes"
                                        ? false
                                        : input?.endDate
                                            ? true
                                            : false
                                }
                                checked={input?.till_present === "yes"}
                                onChange={(event) =>
                                    handleCheckboxChange(event, index)
                                }
                                size="large"

                            >
                                <span className="dragCheck">Present</span>
                            </Checkbox>
                        </Col>
                    </Row>
                </div>
                <div>
                    {inputs.length > 1 ? (
                        <div className="d-flex justify-content-end align-items-center my-2">
                            <Button
                                type="primary"
                                onClick={() => handleDeleteInput(index)}
                            >
                                Remove
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </Reorder.Item>
    );
};
