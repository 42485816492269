import { Form, Input, Select, Button, Radio } from "antd";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import useStep from "../../../CustomHooks/UseStep";
import API_PATH from "../../../Constants/api-path";
import dayjs from "dayjs";
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import ExpertAuth from "../../components/ExpertAuth";
import { City, Country } from "country-state-city";
import { projectSpecific, projectSubType, projectType } from "../../../Constants/ProjectTypeOptions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

dayjs.extend(customParseFormat);

const { Option } = Select;

function RegisterConsultantStep3() {
    const history = useNavigate();
    const { registrationType, setCurrentStepConsultant } = useStep();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState("");
    const [selectedIndustOptions, setSelectedIndustOptions] = useState([]);
    const [industOption, setFilteredIndust] = useState("");
    const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);

    const [code, setCode] = useState(null);
    const [step3, setStep3] = useState([{
        project_name: null,
        project_scope: null,
        project_description: null,
        industry: [],
        project_type: null,
        project_sub_type: null,
        project_specific: null,
        project_duration: null,
        size_of_organization: null,
        country: {},
        city: "",
        function: ""

    }]);

    const editorRef = useRef();

    const [projectSubTypeOptions, setProjectSubTypeOptions] = useState([])
    const [projectSpecificOptions, setProjectSpecificOptions] = useState([])

    const countries = Country?.getAllCountries();

    const updatedCountries = useMemo(() => countries?.map((country, index) => {
        return <Option key={country.isoCode} value={country.name} >{country.name}</Option>
    }), [countries]);
    const updatedCities = useCallback((countryId) => {
        return City?.getCitiesOfCountry(countryId)?.map((city, index) => {
            return <Option value={city.name} key={index}>{city.name} {city.stateCode}</Option>
        })
    }, [])

    // const onExperianceChange = (e) => {

    //     setStep3({ ...step3, "project_duration": e.target.value });
    // };
    const experience = [
        { key: 1, label: 1, value: 1 },
        { key: 2, label: 2, value: 2 },
    ];

    for (let i = 3; i <= 70; i++) {
        experience.push({ key: i, label: i, value: i });
    }
    const [inputs, setInputs] = useState([
        {
            career_title: "",
            company_name: "",
            location: "",
            date_from: null,
            date_till: null,
            till_present: "no",
        },
    ]);
    const GetIndustryList = useCallback(async (industOption) => {
        setLoading(true);

        let object = {};

        if (industOption) {
            object.key = `${industOption}`;
        }

        const queryParams = new URLSearchParams(object);

        try {
            const res = await fetch(
                `${API_PATH.ADD_EXPERT_INDUSTRY
                }?${queryParams?.toString()}&page=1&limit=30`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const result = await res.json();
            if (result) {
                setFilteredIndustOptions(result?.data);
            }
        } catch (error) {
            setLoading(false);
            console.error("Industry Error", error);
        }
        setLoading(false);
    }, []);
    // const [allDesig, setAllDesig] = useState([]);
    const handleStep3Change = useCallback((value, field, index) => {
        // let step3Array = [...step3]
        // step3Array[index][field] = value
        // setStep3(step3Array)
        setStep3(prevStep3 => {
            // Create a copy of the step3 array
            const updatedStep3 = [...prevStep3];
            // Update the value in the copy
            updatedStep3[index][field] = value;
            // Return the updated array
            return updatedStep3;
        });
    }, []);

    const handleOptionIndustChange = useCallback((selectedValues, index) => {
        setSelectedIndustOptions([...selectedValues, { [index]: selectedValues }]);
        const selectValue = selectedValues;
        handleStep3Change(selectValue, "industry", index)
    }, [handleStep3Change]);

    const handleDropdownIndustry = useCallback((open) => {
        if (open) {
            GetIndustryList("");
        }
    }, [GetIndustryList]);


    const handleAddStep3 = useCallback(() => {
        const newStep3 = [
            ...step3,
            {
                project_name: null,
                project_scope: null,
                project_description: null,
                industry: [],
                project_type: null,
                project_sub_type: null,
                project_specific: null,
                project_duration: null,
                size_of_organization: null,
                country: {},
                city: "",
                function: ""
            },
        ];
        setStep3(newStep3);
    }, [step3]);
    const handleDeleteInput = useCallback((index) => {
        const storedData = localStorage.getItem("step3");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            let newStep3Data = [...parsedData?.step3]
            newStep3Data.splice(index, 1)
            let dataToStore = {
                ...parsedData,
                step3: newStep3Data
            }
            localStorage.setItem("step3", JSON.stringify(dataToStore));
        }
        if (step3.length > 1) {
            const newInputs = [...step3];
            newInputs.splice(index, 1);
            setStep3(newInputs);
        }
    }, [step3]);

    const saveToLocalStorage = useCallback(() => {
        const data = {
            career_title: [],
            company_name: [],
            location: [],
            date_from: [],
            date_till: [],
            till_present: [],
        };

        inputs?.forEach((input) => {
            if (
                input.career_title !== "" &&
                input.company_name !== "" &&
                input.location !== ""
            ) {
                data.career_title.push(input.career_title);
                data.company_name.push(input.company_name);
                data.location.push(input.location);
                data.date_from.push(
                    input.date_from
                        ? dayjs(input.date_from, "MM, YYYY").format("MMM YYYY")
                        : ""
                );
                data.date_till.push(
                    input.date_till
                        ? dayjs(input.date_till, "MM, YYYY").format("MMM YYYY")
                        : ""
                );
                data.till_present.push(input.till_present === "yes" ? "yes" : "no");
            }
        });

        const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
        const dataToStore = {
            step3,
            inputs: data,
            expiration: expirationTime,
        };
        localStorage.setItem("step3", JSON.stringify(dataToStore));
    }, [inputs, step3]);
    const queryParameters = useMemo(() => new URLSearchParams(window.location.search), []);

    const handleSubmit = useCallback(async () => {
        saveToLocalStorage();
        history(`/expert-register?step=4&regType=${registrationType}`);
        setCurrentStepConsultant(4);
    }, [history, registrationType, saveToLocalStorage, setCurrentStepConsultant]);
    const handleSubmit2 = useCallback(() => {
        saveToLocalStorage();
        history(`/expert-register?step=4&regType=${registrationType}&type=linkedin`);
        setCurrentStepConsultant(4);
    }, [history, registrationType, saveToLocalStorage, setCurrentStepConsultant]);


    const isLocalStorageExpired = useCallback(() => {
        const storedData = localStorage.getItem("step3");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const now = new Date().getTime();
            return parsedData.expiration && now > parsedData.expiration;
        }
        return true;
    }, []);


    useEffect(() => {

        const storedData = localStorage.getItem("step3");
        if (!isLocalStorageExpired() && storedData) {
            const parsedData = JSON.parse(storedData);
            if (parsedData?.step3 && parsedData?.inputs) {
                const { step3, inputs } = parsedData;
                setStep3(step3);
                let projectSubTypeArr = []
                let projectSpecificArr = []
                step3?.forEach((proj, index) => {
                    let newArrSub = [...projectSubTypeArr, [index] = projectSubType[proj?.project_type]]
                    let newArrSpecific = [...projectSpecificArr, [index] = projectSpecific[proj?.project_sub_type]]
                    projectSpecificArr = newArrSpecific
                    projectSubTypeArr = newArrSub
                })
                setProjectSubTypeOptions(projectSubTypeArr)
                setProjectSpecificOptions(projectSpecificArr)

                const inputsArray = Array.isArray(inputs.career_title)
                    ? inputs.career_title.map((_, index) => ({
                        career_title: inputs.career_title[index] || "",
                        company_name: inputs.company_name[index] || "",
                        location: inputs.location[index] || "",
                        date_from: inputs.date_from[index]
                            ? dayjs(inputs.date_from[index], "MMM YYYY").format("MM, YYYY")
                            : "",
                        date_till: inputs.date_till[index]
                            ? dayjs(inputs.date_till[index], "MMM YYYY").format("MM, YYYY")
                            : "",
                        till_present: inputs.till_present[index] || "no",
                    }))
                    : [];

                setInputs(inputsArray);
                const initialValues = step3?.reduce((acc, input, index) => {
                    acc[index] = {
                        project_name: input?.project_name,
                        project_scope: input?.project_scope,
                        project_description: input?.project_description,
                        project_duration: input?.project_duration,
                        industry: input?.industry,
                        project_type: input?.project_type,
                        project_sub_type: input?.project_sub_type,
                        project_specific: input?.project_specific,
                        size_of_organization: input?.size_of_organization,
                        country: input?.country || {},
                        city: input?.city || "",
                        function: input?.function || ""
                    };
                    return acc
                }, {})

                const inputValues = inputsArray.reduce((acc, input, index) => {
                    acc[index] = {
                        career_title: input?.career_title,
                        company_name: input?.company_name,
                        location: input?.location,
                        date_from: input?.date_from || "",
                        date_till: input?.date_till || "",
                    };
                    return acc;
                }, {});

                form.setFieldsValue({
                    ...initialValues,
                    ...inputValues,
                });
            }
        } else {
            // Clear data and remove expired item from local storage
            localStorage.removeItem("step3");
        }
    }, [form, isLocalStorageExpired]);


    useEffect(() => {

        // Function to extract query parameters from the URL
        const getQueryParameter = (name) => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        };

        // Get the 'code' query parameter from the URL
        const typeFromURL = getQueryParameter("type");
        if (typeFromURL === "linkedin") {
            // If 'code' exists in the URL, set it in the state
            setCode(typeFromURL);
            // You can perform any additional actions with the 'code' here if needed
        }
    }, []);

    const onceCall = useRef(false)

    React.useEffect(() => {
        //!DEPENDENCY SHOULD BE ON industOption FOR A REASON
        const getData = setTimeout(() => {
            GetIndustryList(industOption);
        }, 500);

        return () => clearTimeout(getData);
    }, [industOption]);

    React.useEffect(() => {
        //!DEPENDENCY SHOULD BE ON industOption FOR A REASON
        const fetch = async () => {
            await GetIndustryList(industOption);
        }
        if (!onceCall.current) {
            onceCall.current = true
            fetch()
        }
    }, [GetIndustryList, industOption])

    const organizationSize = useMemo(() => ([

        {
            key: "1",
            value: "large_enterprise",
            label: "Large enterprise (1000+ employees)"
        },
        {
            key: "2",
            value: "medium_enterprise",
            label: "Medium Enterprise (100-1000 employees)"
        },
        {
            key: "3",
            value: "small_enterprise",
            label: "Small Enterprise (0-100 employees)"
        },
        {
            key: "4",
            value: "venture_capital_or_private_equity",
            label: "Venture Capital or Private Equity"
        }
    ]), [])



    return (
        <React.Fragment>
            <ExpertAuth />

            <div className="expertRegistration step1">
                <h5>Step 3 : Project Details</h5>
                <p>
                    Add detailed information about projects you have worked on previously
                </p>

                <div className="">
                    <Form layout="vertical" autoComplete="off" form={form} onFinish={
                        () => {
                            if (code) {
                                handleSubmit2()
                            } else {
                                handleSubmit()
                            }
                        }}>
                        {step3?.map((step3input, index) => {
                            return <div key={index} className="project-container">
                                <h3>Project {index + 1}</h3>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            name={[index, "project_type"]}
                                            label="Project Type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select your project type!",
                                                },
                                            ]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_type !== currentValues[index]?.project_type
                                            }}
                                        >
                                            <Select
                                                size="large"
                                                name="project_type"
                                                allowClear
                                                placeholder="Select Type"
                                                value={step3input.project_type}
                                                onChange={(value) => {
                                                    handleStep3Change(value, "project_type", index)
                                                    setProjectSubTypeOptions([...projectSubTypeOptions, projectSubTypeOptions[index] = projectSubType[value]])
                                                }}
                                            >
                                                {projectType?.map((item) => (
                                                    <Option key={item} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {projectSubTypeOptions[index]?.length > 0 && <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="project_sub_type"
                                            name={[index, "project_sub_type"]}
                                            label="Project Sub Type"
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_sub_type !== currentValues[index]?.project_sub_type
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select your project subtype!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                size="large"
                                                name="project_sub_type"
                                                allowClear
                                                placeholder="Select Sub Type"
                                                value={step3input.project_sub_type}
                                                onChange={(value) => {
                                                    handleStep3Change(value, "project_sub_type", index)
                                                    setProjectSpecificOptions([...projectSpecificOptions, projectSpecificOptions[index] = projectSpecific[value]])
                                                }}
                                            >
                                                {projectSubTypeOptions[index]?.map((item) => (
                                                    <Option key={item} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>}
                                {projectSpecificOptions[index]?.length > 0 && <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="project_specific"
                                            name={[index, "project_specific"]}
                                            label="Project Specific"
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_specific !== currentValues[index]?.project_specific
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select your project type!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                size="large"
                                                name="project_specific"
                                                allowClear
                                                placeholder="Select Specific"
                                                value={step3input.project_specific}
                                                onChange={(value) => {
                                                    handleStep3Change(value, "project_specific", index)
                                                }}
                                            >
                                                {projectSpecificOptions[index]?.map((item) => (
                                                    <Option key={item} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>}

                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="industry"
                                            name={[index, "industry"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.industry !== currentValues[index]?.industry
                                            }}
                                            label="Industry of the Project"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Field is required",
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{
                                                    width: "100%",
                                                }}
                                                name="industry"
                                                size="large"
                                                mode="multiple"
                                                allowClear
                                                onSearch={setFilteredIndust}
                                                placeholder="Type for search"
                                                value={selectedIndustOptions[index]}
                                                onDropdownVisibleChange={handleDropdownIndustry}
                                                loading={loading}
                                                onChange={(value) => handleOptionIndustChange(value, index)}
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        ?.toLowerCase()
                                                        .indexOf(input?.toLowerCase()) >= 0
                                                }
                                            >
                                                {filteredIndustOptions?.map((option) => (
                                                    <Option key={option?._id} value={option?.category_name}>
                                                        {option?.category_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="project_name"
                                            name={[index, "project_name"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_name !== currentValues[index]?.project_name
                                            }}
                                            label="Project Title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Project title is required!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                name="project_name"
                                                placeholder="Project Title"
                                                size="large"
                                                type="text"
                                                value={step3input?.project_name}
                                                onChange={(event) => handleStep3Change(event?.target?.value, "project_name", index)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col xs={12} md={6}>
                                        <Form.Item
                                            // name="project_scope"
                                            name={[index, "project_scope"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_scope !== currentValues[index]?.project_scope
                                            }}
                                            label="Project Scope"
                                        >
                                            <Input
                                                name="project_scope"
                                                placeholder="project scope"
                                                size="large"
                                                type="text"
                                                value={step3input?.project_scope}
                                                onChange={(event) => handleStep3Change(event?.target?.value, "project_scope", index)}
                                            />
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Item
                                            // name="project_description"
                                            name={[index, "project_description"]}
                                            // shouldUpdate={(prevValues, currentValues) => {
                                            //     return prevValues[index]?.project_description !== currentValues[index]?.project_description
                                            // }
                                            // }

                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Project summary is required!",
                                                },

                                                {
                                                    min: 100,
                                                    message: 'summary should be at least 100 character long.'
                                                }

                                            ]}
                                            label="Project Summary (Min.100 character)"
                                        >
                                            {/* <Input.TextArea
                                                name="project_description"
                                                placeholder="Please describe in as much detail what the project entailed and what your specific roles and responsibilities in achieving the project adjective"
                                                style={{
                                                    height: "250px",
                                                    // resize: 'none',
                                                }}
                                                // value={step3input?.project_description}
                                                onChange={(event) => handleStep3Change(event?.target?.value, "project_description", index)}
                                            /> */}
                                            <Col className="projectEditor mt-2 min-h-150">
                                                <CKEditor
                                                    name="project_description"
                                                    editor={Editor}
                                                    data={step3input?.project_description}
                                                    onReady={(editor) => {
                                                        editorRef.current = editor;
                                                    }}
                                                    config={{
                                                        toolbar: [
                                                            "bold",
                                                            "italic",
                                                            "link",
                                                            "bulletedList",
                                                            "numberedList",
                                                        ],
                                                        placeholder:
                                                            "Please describe in as much detail what the project entailed and what your specific roles and responsibilities in achieving the project adjective",
                                                        // extraPlugins: 'autogrow',
                                                        // autoGrow_bottomSpace: 0,
                                                        autoGrow_minHeight: 800, // Set a fixed minimum height
                                                        autoGrow_maxHeight: 500, // Set a fixed maximum height
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const newData = editor.getData();
                                                        handleStep3Change(newData, "project_description", index)
                                                        form.setFieldValue([index, "project_description"], newData)
                                                        // handleEditorChange(newData, "overview");
                                                    }}
                                                    onBlur={(editor) => {
                                                    }}
                                                    onFocus={(editor) => {
                                                    }}
                                                />
                                            </Col>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Item
                                            // name="project_duration"
                                            name={[index, "project_duration"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_duration !== currentValues[index]?.project_duration
                                            }}
                                            label="Project Duration"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Project duration is required!",
                                                },
                                            ]}
                                        >
                                            <Radio.Group name="project_duration" value={step3input.project_duration}
                                                // onChange={onExperianceChange}
                                                onChange={(event) => handleStep3Change(event?.target?.value, "project_duration", index)}>
                                                <Radio.Button className="experianceButton" value="0-6">0 - 6 Months</Radio.Button>
                                                <Radio.Button className="experianceButton" value="6-12">6 - 12 Months</Radio.Button>
                                                <Radio.Button className="experianceButton" value="12-24">12 - 24 Months</Radio.Button>
                                                <Radio.Button className="experianceButton" value="24">24 + Months</Radio.Button>
                                            </Radio.Group>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="size_of_organization"
                                            name={[index, "size_of_organization"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.size_of_organization !== currentValues[index]?.size_of_organization
                                            }}
                                            label="Size of the client organization"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select organization size!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                size="large"
                                                name="size_of_organization"
                                                placeholder="Select organization size"
                                                allowClear
                                                value={step3input.size_of_organization}
                                                onChange={(value) => {
                                                    handleStep3Change(value, "size_of_organization", index)
                                                    // setStep3({ ...step3, size_of_organization: value })
                                                }}
                                            >
                                                {organizationSize?.map((item) => (
                                                    <Option key={item?.key} value={item?.value}>
                                                        {item?.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="function"
                                            name={[index, "function"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.function !== currentValues[index]?.function
                                            }}
                                            label="Function"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please add function!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                name="function"
                                                placeholder="functions"
                                                size="large"
                                                type="text"
                                                autoComplete="off"
                                                value={step3input.function}
                                                onChange={(e) => {
                                                    handleStep3Change(e.target.value, "function", index)
                                                    // setStep3({ ...step3, function: e.target.value })
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row> */}

                                <Form.Item
                                    label={
                                        <span className="">Project Location</span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select country!",
                                        },
                                    ]}
                                >
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                // name="country"
                                                name={[index, "country"]}
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return prevValues[index]?.country !== currentValues[index]?.country
                                                }}
                                                label={
                                                    <span className="">Country</span>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select country!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    name="country"
                                                    size="large"
                                                    labelInValue
                                                    allowClear
                                                    showSearch
                                                    value={step3input?.country}
                                                    onChange={(value) => {
                                                        handleStep3Change(value, "country", index)
                                                        // setStep3({
                                                        //     ...step3,
                                                        //     country: value
                                                        // })
                                                    }}
                                                >
                                                    {updatedCountries}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                // name="city"
                                                name={[index, "city"]}
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return prevValues[index]?.city !== currentValues[index]?.city
                                                }}
                                                label={
                                                    <span className="">City</span>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select city!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    name="city"
                                                    size="large"
                                                    labelInValue
                                                    allowClear
                                                    disabled={Object.keys(step3input?.country)?.length === 0}
                                                    showSearch
                                                    value={step3input?.city}
                                                    onChange={(value) => {
                                                        handleStep3Change(value, "city", index)
                                                    }}

                                                >
                                                    {updatedCities(step3input?.country ? step3input?.country?.key : null)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <div>
                                            {step3.length > 1 ? (
                                                <div className="d-flex justify-content-end align-items-center my-2">
                                                    <Button
                                                        type="primary"
                                                        onClick={() => handleDeleteInput(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Row>
                                </Form.Item>
                            </div>

                        })}
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <Button
                                size="large"
                                className="text-center btn btn-responsive expertButtonNext"
                                onClick={handleAddStep3}
                            >
                                Add another project
                            </Button>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Form.Item>
                                {code ? (
                                    <>
                                        <Form.Item>
                                            <NavLink
                                                to={`/expert-register?regType=2&step=2&type=linkedin`}
                                                onClick={() => setCurrentStepConsultant(2)}
                                            >
                                                <Button
                                                    size="large"
                                                    className="btn btn-responsive expertButtonNext"
                                                >
                                                    Prev
                                                </Button>
                                            </NavLink>
                                        </Form.Item>
                                    </>
                                ) : (
                                    <NavLink
                                        to={`/expert-register?regType=2&step=2`}
                                        onClick={() => setCurrentStepConsultant(2)}
                                    >
                                        <Button
                                            size="large"
                                            className="btn btn-responsive expertButtonNext"
                                        >
                                            Prev
                                        </Button>
                                    </NavLink>
                                )}
                            </Form.Item>

                            {code ? <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        // onClick={handleSubmit2}
                                        className="btn btn-responsive expertButtonNext"
                                        size="large"
                                        htmlType="submit"
                                    // disabled={
                                    //     // (!code && !form.isFieldsTouched(true))
                                    //     // ||
                                    //     // (code && form.getFieldsError().filter(({ errors }) => errors.length).length
                                    //     // )
                                    // }
                                    >
                                        Next
                                    </Button>
                                )}
                            </Form.Item> :
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <Button
                                            // onClick={handleSubmit}
                                            className="btn btn-responsive expertButtonNext"
                                            size="large"
                                            htmlType="submit"
                                        // disabled={
                                        //     !form.isFieldTouched("project_type") ||
                                        //     !form.isFieldTouched("industry") ||
                                        //     !form.isFieldTouched("project_duration") ||
                                        //     !form.isFieldTouched("project_name")
                                        // }
                                        >
                                            Next
                                        </Button>
                                    )}
                                </Form.Item>}
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment >
    );
}

export default RegisterConsultantStep3;
