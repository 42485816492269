import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar'
import { Button, Input, List, Spin, Tabs, Tooltip } from 'antd'
import { MdCancel, MdDelete, MdEdit, MdOutlineCancel } from "react-icons/md";
import ConfirmationModal from '../../components/ConfirmationModal';
import axios from 'axios';
import { BsArrowRightCircle } from "react-icons/bs";
import API_URL from '../../Constants/api-path';
import { GrFormEdit } from "react-icons/gr";
import { IoMdArrowRoundForward } from "react-icons/io";

const DoNotContactList = () => {
    const [tab, setTab] = useState("1")
    const [viewAdd, setViewAdd] = useState(false)
    const [value, setValue] = useState("")
    const [current, setCurrent] = useState(1)
    const [edit, setEdit] = useState({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
    const [currentDnc, setCurrentDnc] = useState(1)
    const [isOpenDelete, setIsOpenDelete] = useState({ isOpen: false, item: {} })
    const [data, setData] = useState(["Input"])
    const [existingClientList, setExistingClientList] = useState(["Input"])
    const UserData = JSON.parse(localStorage.getItem("userData"));
    const permission = useMemo(() => UserData?.data?.roleData?.[0]?.role_permission,
        [UserData?.data?.roleData]
    );
    const TabList = [
        {
            key: '1',
            label: <div className="d-flex justify-content-between align-items-center">
                <h6>Do Not Contact (DNC)</h6>
            </div>,
            children: <>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    pagination={{
                        pageSize: 10,
                        current: currentDnc,
                        total: data?.length,
                        showSizeChanger: false,
                        onChange: (page) => {
                            setCurrentDnc(page)
                        }
                    }}
                    renderItem={(item, index) => {
                        if (item !== "Input") {
                            return (
                                <List.Item className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center' style={{
                                        width: 300
                                    }}>
                                        {(currentDnc - 1) * 10 + index + 1}.  {" "}{currentDnc === edit?.page && edit?.edit === true && edit?.tabIndex === tab && edit?.index === index ?
                                            <>
                                                <Input className='ms-1' width={"40px"} value={edit?.value} onChange={(e) => {
                                                    setEdit({ ...edit, value: e.target.value })
                                                }} />
                                            </> :
                                            item?.client_name}{" "}
                                        {edit?.edit && edit?.tabIndex === tab && edit?.index === index ?
                                            <div className='d-flex align-items-center gap-2 ms-2'>
                                                <div>
                                                    <Tooltip title={"Add Company"} color="#5746EB">
                                                        <span>
                                                            <button
                                                                size="large"
                                                                className="viewBttnExpert"
                                                                onClick={() => {
                                                                    updateCompany(item?._id)
                                                                }}
                                                            >
                                                                <IoMdArrowRoundForward />
                                                            </button>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <Tooltip title={"Cancel"} color="#5746EB">
                                                        <span>
                                                            <button
                                                                size="large"
                                                                className="viewBttnExpert"
                                                                onClick={() => {
                                                                    setEdit({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
                                                                }}
                                                            >
                                                                <MdCancel />
                                                            </button>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                {/* <span onClick={() => updateCompany(item?._id)}>
                                                    <BsArrowRightCircle style={{ fontSize: 20 }} />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        setEdit({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
                                                    }}>
                                                    <MdOutlineCancel style={{ fontSize: 20 }} />
                                                </span> */}
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className='d-flex align-items-center gap-2'>
                                        {permission?.[21]?.dncList?.edit && <div>
                                            <Tooltip title={"Edit"} color="#5746EB">
                                                <span>
                                                    <button
                                                        size="large"
                                                        className="viewBttnExpert"
                                                        onClick={() => {
                                                            setEdit({ tabIndex: tab, page: currentDnc, index: index, edit: true, value: item?.client_name })
                                                        }}
                                                    >
                                                        <MdEdit />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>}
                                        {permission?.[21]?.dncList?.delete && <div>
                                            <Tooltip title={"Delete Company"} color="#5746EB">
                                                <span>
                                                    <button
                                                        size="large"
                                                        className="viewBttnExpert"
                                                        onClick={() => {
                                                            setIsOpenDelete({ isOpen: true, item: item })
                                                        }}
                                                    >
                                                        <MdDelete />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>}
                                    </div>
                                </List.Item>
                            )
                        } else if (permission?.[21]?.dncList?.add) {
                            return (
                                <List.Item className='d-flex align-items-center'>
                                    {!viewAdd && tab === "1" ? <div className="d-flex justify-content-center align-items-center">
                                        <Button
                                            size="middle"
                                            className="text-center btn-responsive expertButtonNext"
                                            onClick={() => {
                                                setViewAdd(true)
                                            }}
                                        >
                                            Add Company
                                        </Button>
                                    </div> : viewAdd && tab === "1" &&
                                    <div className='d-flex align-items-center gap-2'>
                                        <span>{(currentDnc - 1) * 10 + index + 1}.</span>
                                        <span style={{ width: "200px" }}>
                                            <Input width={"20px"} value={value} placeholder='Company Name' onChange={(e) => {
                                                setValue(e.target.value)
                                            }} />
                                        </span>
                                        <div>
                                            <Tooltip title={"Add Company"} color="#5746EB">
                                                <span>
                                                    <button
                                                        size="large"
                                                        className="viewBttnExpert"
                                                        onClick={() => {
                                                            addCompany()
                                                        }}
                                                    >
                                                        <IoMdArrowRoundForward />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title={"Cancel"} color="#5746EB">
                                                <span>
                                                    <button
                                                        size="large"
                                                        className="viewBttnExpert"
                                                        onClick={() => {
                                                            setValue("")
                                                            setViewAdd(false)
                                                        }}
                                                    >
                                                        <MdCancel />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>

                                    </div>}
                                </List.Item>
                            )
                        }
                    }}
                />

            </>,
        },
        {
            key: '2',
            label: <div className="d-flex justify-content-between align-items-center">
                <h6>Existing Clients (Do Not Contact)</h6>
            </div>,
            children: <>
                <List
                    itemLayout="horizontal"
                    dataSource={existingClientList}
                    pagination={{
                        pageSize: 10,
                        current: current,
                        total: existingClientList?.length,
                        showSizeChanger: false,
                        onChange: (page) => {
                            setCurrent(page)
                        }
                    }}
                    renderItem={(item, index) => {
                        if (item !== "Input") {
                            return (
                                <List.Item className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center' style={{
                                        width: 300
                                    }}>
                                        {(current - 1) * 10 + index + 1}. {" "}{current === edit?.page && edit?.edit === true && edit?.tabIndex === tab && edit?.index === index ?
                                            <>
                                                <Input className='ms-1' width={"40px"} value={edit?.value} onChange={(e) => {
                                                    setEdit({ ...edit, value: e.target.value })
                                                }} />
                                            </> : item?.client_name} {" "}
                                        {edit?.edit && edit?.tabIndex === tab && edit?.index === index ?
                                            <div className='d-flex align-items-center ms-2 gap-2'>
                                                <div>
                                                    <Tooltip title={"Add Company"} color="#5746EB">
                                                        <span>
                                                            <button
                                                                size="large"
                                                                className="viewBttnExpert"
                                                                onClick={() => {
                                                                    updateCompany(item?._id)
                                                                }}
                                                            >
                                                                <IoMdArrowRoundForward />
                                                            </button>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <Tooltip title={"Cancel"} color="#5746EB">
                                                        <span>
                                                            <button
                                                                size="large"
                                                                className="viewBttnExpert"
                                                                onClick={() => {
                                                                    setEdit({ tabIndex: "2", page: null, index: null, edit: false, value: "" })
                                                                }}
                                                            >
                                                                <MdCancel />
                                                            </button>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className='d-flex align-items-center gap-2'>
                                        {permission?.[21]?.dncList?.edit && <div>
                                            <Tooltip title={"Edit"} color="#5746EB">
                                                <span>
                                                    <button
                                                        size="large"
                                                        className="viewBttnExpert"
                                                        onClick={() => {
                                                            setEdit({ page: current, tabIndex: tab, index: index, edit: true, value: item?.client_name })
                                                        }}
                                                    >
                                                        <MdEdit />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>}
                                        {permission?.[21]?.dncList?.delete && <div>
                                            <Tooltip title={"Delete"} color="#5746EB">
                                                <span>
                                                    <button
                                                        size="large"
                                                        className="viewBttnExpert"
                                                        onClick={() => {
                                                            setIsOpenDelete({ isOpen: true, item: item })
                                                        }}
                                                    >
                                                        <MdDelete />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </div>}
                                    </div>
                                </List.Item>
                            )
                        } else if (permission?.[21]?.dncList?.add) {
                            return (
                                <List.Item className='d-flex align-items-center'>
                                    {!viewAdd && tab === "2" ?
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Button
                                                size="middle"
                                                className="text-center btn-responsive expertButtonNext"
                                                onClick={() => {
                                                    setViewAdd(true)
                                                }}
                                            >
                                                Add DNC Company
                                            </Button>
                                        </div> : viewAdd && tab === "2" &&
                                        <div className='d-flex align-items-center gap-2'>
                                            <span>{(current - 1) * 10 + index + 1}.</span>

                                            <span style={{ width: "200px" }}>
                                                <Input width={"20px"} value={value} placeholder='Company Name' onChange={(e) => {
                                                    setValue(e.target.value)
                                                }} />
                                            </span>

                                            <div>
                                                <Tooltip title={"Add Company"} color="#5746EB">
                                                    <span>
                                                        <button
                                                            size="large"
                                                            className="viewBttnExpert"
                                                            onClick={() => {
                                                                addCompany()
                                                            }}
                                                        >
                                                            <IoMdArrowRoundForward />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip title={"Cancel"} color="#5746EB">
                                                    <span>
                                                        <button
                                                            size="large"
                                                            className="viewBttnExpert"
                                                            onClick={() => {
                                                                setValue("")
                                                                setViewAdd(false)
                                                            }}
                                                        >
                                                            <MdCancel />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            </div>

                                        </div>}
                                </List.Item>
                            )
                        }
                    }}
                />

            </>,
        },

    ];

    const getDNCList = React.useCallback(async ({ refetch = false }) => {
        try {
            let response = await axios.get(API_URL.GET_DNC_LIST, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${UserData?.token}`,
                },
            })
            if (response?.data) {
                let existingData = response?.data?.data?.filter((item) => item?.type === "existing")
                let dncData = response?.data?.data?.filter((item) => item?.type !== "existing")
                if (refetch) {
                    setExistingClientList([...existingData, "Input"])
                    setData([...dncData, "Input"])
                } else {
                    setExistingClientList([...existingData, ...existingClientList])
                    setData([...dncData, ...data])

                }
            }
        } catch (error) {
            console.log('error: ', error);

        }
    }, [UserData?.token, data, existingClientList])

    const addCompany = React.useCallback(async () => {
        try {
            let response = await axios.post(API_URL.ADD_DNC, {
                "client_name": value,
                "type": tab === "1" ? "not-existing" : "existing" ///existing, not-existing
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${UserData?.token}`,
                },
            })
            if (response?.data) {
                setValue("")
                setEdit({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
                setCurrent(1)
                setCurrentDnc(1)
                setIsOpenDelete({ isOpen: false, item: {} })
                getDNCList({ refetch: true })
            }
        } catch (error) {
            console.log('error: ', error);

        }
    }, [UserData?.token, getDNCList, tab, value])
    const updateCompany = React.useCallback(async (id) => {
        try {
            let response = await axios.post(API_URL.UPDATE_DNC + `/${id}`, {
                "client_name": edit?.value,
                "type": tab === "1" ? "not-existing" : "existing" ///existing, not-existing
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${UserData?.token}`,
                },
            })
            if (response?.data) {
                setValue("")
                setEdit({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
                setCurrent(1)
                setCurrentDnc(1)
                setIsOpenDelete({ isOpen: false, item: {} })
                getDNCList({ refetch: true })
            }
        } catch (error) {
            console.log('error: ', error);

        }
    }, [UserData?.token, edit?.value, getDNCList, tab])

    const callOnce = React.useRef(false)
    React.useEffect(() => {
        if (!callOnce.current) {
            callOnce.current = true
            getDNCList({ refetch: false })
        }
    }, [getDNCList])
    const handleDelete = React.useCallback(async () => {
        try {
            let response = await axios.post(API_URL.DELETE_DNC + `/${isOpenDelete?.item?._id}`, {
                "client_name": isOpenDelete?.item?.client_name,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${UserData?.token}`,
                },
            })
            if (response?.data) {
                setValue("")
                setEdit({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
                setCurrent(1)
                setCurrentDnc(1)
                setIsOpenDelete({ isOpen: false, item: {} })
                getDNCList({ refetch: true })
            }
        } catch (error) {
            console.log('error: ', error);

        }
    }, [UserData?.token, getDNCList, isOpenDelete?.item?._id, isOpenDelete?.item?.client_name])

    const onChange = (key) => {
        setTab(key)
        setViewAdd(false)
        setCurrent(1)
        setCurrentDnc(1)
        setEdit({ tabIndex: "1", page: null, index: null, edit: false, value: "" })
        setValue("")
    };
    return (
        <>
            <Helmet>
                <title>{"Nextyn | Home"}</title>
            </Helmet>
            <div className="page-wrapper chiller-theme toggled">
                <div className="main-sec d-lg-flex">
                    <Sidebar />
                </div>
                <div className="text-center loginScreenLinkedin">
                    <Spin size="large" />
                </div>
                <main className="body-total content-wrapper float-start w-100 dashboardPage">
                    <div>
                        <div className="d-block d-lg-block">
                            <div>
                                <div className="dasb-div bk-div float-start w-100">
                                    <div className="body-expart-div">
                                        <Tabs activeKey={tab} items={TabList} onChange={onChange} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div >
            <ConfirmationModal
                open={isOpenDelete?.isOpen}
                setOpen={() => {
                    setIsOpenDelete({ isOpen: false, item: {} })
                }}
                message={"Are you sure you want to remove this company from Do Not Contact list?"}
                title={"Delete from DNC List"}
                okFunction={async () => {
                    handleDelete()
                }}
                cancelFunction={() => {
                    setIsOpenDelete({ isOpen: false, item: {} })
                }}
                okText={"Yes"}
            />
        </>
    )
}

export default DoNotContactList
