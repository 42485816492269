import { DatePicker, Divider, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import axios from 'axios';
import API_URL from '../../Constants/api-path';
const { RangePicker } = DatePicker;
const CalenderModal = ({ isOpenCalModal, setIsOpenCalModal }) => {
    const [downloadRange, setDownloadRange] = useState({
        dates: null,
        dateStrings: ['', '']
    })
    const [isDownloading, setIsDownloading] = useState(false);
    const rangePresets = [
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    const onRangeChange = (dates, dateStrings) => {
        setDownloadRange({
            dates: dates,
            dateStrings: dateStrings
        })
    };
    const handleDownloadCSVData = async () => {
        setIsDownloading(true);
        await axios.get(`${API_URL.EXPORT_PROJECT}?sdate=${downloadRange?.dateStrings[0]}&edate=${downloadRange?.dateStrings[1]}`)
            .then((res) => {
                setIsOpenCalModal(false)
                setDownloadRange({
                    dates: null,
                    dateStrings: ["", ""]
                })
                setIsDownloading(false)
                window.location = `${API_URL.EXPORT_PROJECT}?sdate=${downloadRange?.dateStrings[0]}&edate=${downloadRange?.dateStrings[1]}`;
            })

    }
    return (
        <Modal
            title={<div className="modalHeader" style={{ fontSize: 18 }}>
                Select Date Range
                <Divider className="m-2" />
            </div>}
            open={isOpenCalModal}
            onOk={() => handleDownloadCSVData()}
            onCancel={() => setIsOpenCalModal(false)}
            width={450}
            className=""
            centered
            okText={isDownloading ? <><Spin indicator={<LoadingOutlined style={{
                color: "white",
            }} />} size="small" /> Downloading CSV</> : "Download CSV"}
        // footer={false}
        >
            <div className="mt-4" style={{ width: "100%", textAlign: "center" }}>
                <div style={{ cursor: "pointer", height: 50 }} >
                    <RangePicker placement="bottomRight" value={downloadRange.dates} presets={rangePresets} onChange={onRangeChange} style={{
                        width: "100%"
                    }} />

                </div>
            </div>
        </Modal>
    )
}

export default CalenderModal