import React, { useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Pagination, Popover, Space, Table, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IoDocumentText } from "react-icons/io5";

import {
    clearAll,
    setType,
} from "../../../features/internalMeeting.slice";
import _ from "lodash";
import useDocumentList from "./hooks/useDocumentList";
import { setIsOpenDelete, setOpenAddDocumentModal, setPage, setSelectedDoc } from "../../../features/documentList.slice";
import DocumentUploaderModal from "./Components/DocumentUploaderModal";
import ConfirmationModal from "../../../../src/components/ConfirmationModal";

function DocumentList({ activeTab }) {
    const dispatch = useDispatch();
    const [temp, setTemp] = useState()
    const { documentList, count, isOpenDelete, page } = useSelector(
        (state) => state.documentList
    );
    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = useMemo(
        () => userData?.data?.roleData?.[0]?.role_permission,
        [userData?.data?.roleData]
    );
    const {
        isLoadingDocList, handleDocDelete, debounceKey
    } = useDocumentList();

    dayjs.extend(customParseFormat);

    useEffect(() => {
        if (activeTab === "1") {
            setTemp("")
        }
    }, [activeTab])

    const handleDeleteDoc = (data) => {
        dispatch(setSelectedDoc(data))
        dispatch(dispatch(setIsOpenDelete(true)))

    }
    const handleDocEdit = (data) => {
        dispatch(setSelectedDoc(data))
        dispatch(setType("update"))
        dispatch(setOpenAddDocumentModal(true))
    }
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Industries",
            dataIndex: "industries",
            key: "industries",
        },
        {
            title: "Sub Industries",
            dataIndex: "sub_industries",
            key: "sub_industries",
        },
        {
            title: "Countries",
            dataIndex: "countries",
            key: "countries",
        },
        {
            title: "Regions",
            dataIndex: "regions",
            key: "regions",
        },
        {
            title: "Keywords/Tags",
            dataIndex: "keywords",
            key: "keywords",
        },
        {
            title: "Documents",
            dataIndex: "documents",
            key: "documents",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (data) => (
                <div className="d-flex gap-2">
                    <Tooltip color="#5746EB" title={!permission?.[13]?.internalMeeting?.edit ? "Permission Denied" : ""}>
                        <div
                            key={"1"}
                            className="cursor-pointer border-dark my-2"
                            onClick={() => handleDocEdit(data)}
                        >
                            <Space>
                                <button
                                    className="viewBttnExpert"
                                    disabled={!permission?.[13]?.internalMeeting?.edit}
                                >
                                    <i
                                        className="	fa fa-edit"
                                        aria-hidden="true"
                                        style={{ fontSize: "12px", cursor: "pointer" }}
                                    ></i>
                                </button>
                                {/* Edit */}
                            </Space>
                        </div>
                    </Tooltip>
                    <Tooltip color="#5746EB" title={!permission?.[13]?.internalMeeting?.delete ? "Permission Denied" : ""}>
                        <div
                            key={"2"}
                            className="cursor-pointer border-dark my-2"
                            onClick={() => handleDeleteDoc(data)}

                        >
                            <Space>
                                <button
                                    className="viewBttnExpert"
                                    disabled={!permission?.[13]?.internalMeeting?.delete}
                                >
                                    <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                        style={{ fontSize: "12px", cursor: "pointer" }}
                                    ></i>
                                </button>
                            </Space>
                        </div>
                    </Tooltip>
                </div>

            ),
        },
    ];
    const dataSource = documentList?.map((doc) => ({
        key: doc?._id,
        title: <Tooltip color="#5746ec" title={doc?.title}>{_.truncate(doc?.title, { length: 30, omission: "..." })}</Tooltip>,
        industries: <Popover
            autoAdjustOverflow overlayInnerStyle={{ maxWidth: 400 }}
            placement="rightTop" trigger={["click", "hover"]}
            content={
                <>
                    {doc?.industry?.slice(2)?.length > 0 ? doc?.industry?.slice(2)?.map((docu) => <Tag color="blue">{docu}</Tag>) : null}
                </>
            }
        >{doc?.industry?.slice(0, 2)?.map((docu) => <Tag color="blue">{docu}</Tag>)}</Popover>,
        sub_industries: <Popover
            autoAdjustOverflow overlayInnerStyle={{ maxWidth: 400 }}
            placement="rightTop"
            trigger={["click", "hover"]}
            content={
                <>
                    {doc?.sub_industry?.slice(2)?.length > 0 ? doc?.sub_industry?.slice(2)?.map((docu) => <Tag color="purple">{docu}</Tag>) : null}
                </>
            }
        >{doc?.sub_industry?.slice(0, 2)?.map((docu) => <Tag color="purple">{docu}</Tag>)}</Popover>,
        countries: <Popover
            autoAdjustOverflow overlayInnerStyle={{ maxWidth: 400 }}
            placement="rightTop"
            trigger={["click", "hover"]}
            content={
                <>
                    {doc?.country?.slice(2)?.length > 0 ? (doc?.country?.slice(2) || [])?.map((docu) => <Tag color="yellow" className="mb-2">{docu}</Tag>) : null}
                </>
            }
        >{(doc?.country?.slice(0, 2) || [])?.map((docu) => <Tag color="yellow">{docu}</Tag>)}</Popover>,
        regions: <Popover
            autoAdjustOverflow overlayInnerStyle={{ maxWidth: 400 }}
            placement="rightTop"
            trigger={["click", "hover"]}
            content={
                <>
                    {doc?.region?.slice(2)?.length > 0 ? (doc?.region?.slice(2) || [])?.map((docu) => <Tag color="processing">{docu}</Tag>) : null}
                </>
            }
        > {(doc?.region?.slice(0, 2) || [])?.map((docu) => <Tag color="processing">{docu}</Tag>)}</Popover>,
        keywords: <Popover
            autoAdjustOverflow overlayInnerStyle={{ maxWidth: 400 }}
            placement="rightTop"
            trigger={["click", "hover"]}
            content={
                <>
                    {doc?.keyword?.slice(2)?.length > 0 ? doc?.keyword?.slice(2)?.map((docu) => <Tag color="green">{docu}</Tag>) : null}
                </>
            }
        >  {doc?.keyword?.slice(0, 2)?.map((docu) => <Tag color="green">{docu}</Tag>)}</Popover>,
        documents: <span className="d-flex align-items-center justify-content-center">{doc?.documents?.slice(0, 2)?.map((docu) => <Tooltip title={docu?.file_original_name} color="#5746EB"><a href={docu?.s3url} target="_blank" rel="noreferrer"><IoDocumentText /></a></Tooltip>)}</span>,
        action: doc,
    }))
    const paginationSettings = useMemo(() => {
        return {
            current: page,
            onChange: (index) => {
                dispatch(setPage(index))
            },
            pageSize: 10,
            total: count,
        };
    }, [count, dispatch, page]);

    return (
        <div>
            <div className="bk-div float-start w-100">
                < >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <span></span>
                        <button
                            onClick={() => {
                                dispatch(setType("create"));
                                dispatch(setSelectedDoc({}));
                                dispatch(clearAll());
                                dispatch(setOpenAddDocumentModal(true));
                            }}
                            className="btn viewButton"
                            size="large"
                        >
                            Upload Report
                        </button>

                    </div>
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        value={temp}
                        style={{ marginBottom: 16 }}
                        size="large"
                        onChange={(e) => {
                            setTemp(e.target.value)
                            debounceKey(e.target.value)
                        }}
                    />
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        className="paddTable"
                        loading={isLoadingDocList}
                        pagination={false}
                        scroll={{ x: 800 }}
                        size="large"
                    />
                    <Pagination
                        {...paginationSettings}
                        showSizeChanger={false}
                        showTotal={(callPage, range) =>
                            `${range[0]}-${range[1]} of ${callPage} items`
                        }
                        style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
                    />
                </>
                {/* Create Meeting Modal */}
                <DocumentUploaderModal />
                {/* Delete Modal */}
                <ConfirmationModal
                    open={isOpenDelete}
                    setOpen={() => {
                        dispatch(setIsOpenDelete(false))
                    }}
                    message={"Are you sure you want to delete this Document?"}
                    title={"Delete Document"}
                    okFunction={async () => {
                        handleDocDelete()
                    }}
                    cancelFunction={() => {
                        dispatch(setIsOpenDelete(false))
                    }}
                    okText={"Yes"}
                />

            </div>
        </div>
    )
}

export default DocumentList;
