import { Drawer, Input } from 'antd'
import React from 'react'
import { setCreditUsageData, setSettingDrawer } from '../../../../features/creditManagement.slice'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import useCreditManagement from '../hooks/useCreditManagement'

function CreditsUsageSettingsDrawer() {
    const { settingDrawer, credit_usage_data } = useSelector((state) => state.creditManagement)
    const { updateCreditSettingById } = useCreditManagement()
    const dispatch = useDispatch()

    return (
        <Drawer
            title="Setting - Techrefic Technologies Pvt Ltd"
            placement={"right"}
            width={"500"}
            onClose={() => dispatch(setSettingDrawer(false))}
            open={settingDrawer}
        >
            <div className='d-flex flex-column h-100 justify-content-between'>
                <div>
                    <Row className="mb-2 align-items-center">
                        <Col md={9}>Credit Used for expert</Col>
                        <Col md={3}>
                            <Input value={credit_usage_data?.settings?.credits_for_expert} type='number' onChange={(e) => {
                                let obj = {
                                    ...credit_usage_data,
                                    settings: {
                                        ...credit_usage_data?.settings,
                                        credits_for_expert: parseFloat(e.target.value)
                                    }
                                }
                                dispatch(setCreditUsageData(obj))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-center">
                        <Col md={9}>Credit Used for premium expert</Col>
                        <Col md={3}>
                            <Input value={credit_usage_data?.settings?.credits_for_premium_expert} type='number' onChange={(e) => {
                                let obj = {
                                    ...credit_usage_data,
                                    settings: {
                                        ...credit_usage_data?.settings,
                                        credits_for_premium_expert: parseFloat(e.target.value)
                                    }
                                }
                                dispatch(setCreditUsageData(obj))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-center">
                        <Col md={9}>Credit Used for transcript</Col>
                        <Col md={3}>
                            <Input value={credit_usage_data?.settings?.credits_for_transcript} type='number' onChange={(e) => {
                                let obj = {
                                    ...credit_usage_data,
                                    settings: {
                                        ...credit_usage_data?.settings,
                                        credits_for_transcript: parseFloat(e.target.value)
                                    }
                                }
                                dispatch(setCreditUsageData(obj))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-center">
                        <Col md={9}>Credit Used for news</Col>
                        <Col md={3}>
                            <Input value={credit_usage_data?.settings?.credits_for_news} type='number' onChange={(e) => {
                                let obj = {
                                    ...credit_usage_data,
                                    settings: {
                                        ...credit_usage_data?.settings,
                                        credits_for_news: parseFloat(e.target.value)
                                    }
                                }
                                dispatch(setCreditUsageData(obj))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-center">
                        <Col md={9}>Credit Used for Company</Col>
                        <Col md={3}>
                            <Input value={credit_usage_data?.settings?.credits_for_company} type='number' onChange={(e) => {
                                let obj = {
                                    ...credit_usage_data,
                                    settings: {
                                        ...credit_usage_data?.settings,
                                        credits_for_company: parseFloat(e.target.value)
                                    }
                                }
                                dispatch(setCreditUsageData(obj))
                            }} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <button className="viewButton btn ms-2" onClick={() => updateCreditSettingById()}>Update</button>
                </div>
            </div>
        </Drawer>
    )
}

export default CreditsUsageSettingsDrawer