import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import API_PATH from "../../../Constants/api-path";
import axios from 'axios'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { setCallPage, setErrorProfileList, setErrorProfilePagination, setFormValues, setIsOpen, setQcExpertList, setSelectedEmails, setSelectedRowKeys } from '../../../features/QCExpert.slice';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
function useQCExpert() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const useOnlyOnce = useRef(false)
    const dispatch = useDispatch()
    const { errorProfilePagination, selectedEmails, formValues } = useSelector((state) => state.qcMeeting)

    const GetLinkedInErrorQcList = useCallback(async ({ is_paginate = false, page = errorProfilePagination.page, limit = errorProfilePagination.limit, key = "" }) => {
        // if (is_paginate) {
        dispatch(setErrorProfilePagination({ ...errorProfilePagination, page: page }))
        // }
        const res = await axios.get(
            `${API_PATH.Qc_LINKEDIN_ERROR_PROFILE_LIST
            }?page=${page}&limit=${limit}${key !== "" ? `&key=${key}` : ""}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }
        ).then((response) => {
            let obj = { data: response.data.data, count: response.data.countData }
            dispatch(setErrorProfileList(obj))
        }).catch((err) => {
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })

    }, [userData?.token, dispatch, errorProfilePagination])

    const updateTempExpert = useCallback(async ({ email = "" }) => {
        let arr
        if (email !== "") {
            arr = [email]
        } else {
            arr = [...selectedEmails?.map((row) => row.email)]
        }
        let url_params = arr.join()
        const res = await axios.get(
            `${API_PATH.UPDATE_TEMP_EXPERT
            }?email=${url_params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }
        ).then((response) => {
            GetLinkedInErrorQcList({ page: 1, limit: 10 })
            GetQcList({ page: 1, selectedOptions: { key: "" } })
            dispatch(setSelectedEmails([]))
            dispatch(setSelectedRowKeys([]))
            notification.success({
                message: "Temp Expert Updated Successfully",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
            });
        }).catch((err) => {
            GetLinkedInErrorQcList({ page: 1, limit: 10 })
            dispatch(setSelectedEmails([]))
            dispatch(setSelectedRowKeys([]))
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })

    }, [userData?.token, selectedEmails, GetLinkedInErrorQcList, dispatch])

    const updatePhantomKey = useCallback(async () => {
        let body = {
            "pt_bustor_value": formValues.phantomKey
        }
        const res = await axios.post(
            `${API_PATH.UPDATE_PT_BUSTER
            }`, body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }
        ).then((response) => {
            GetLinkedInErrorQcList({ page: 1, limit: 10 })
            dispatch(setFormValues({ phantomKey: "" }))
            dispatch(setIsOpen(false))

            notification.success({
                message: "Updated Successfully",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
            });
        }).catch((err) => {
            dispatch(setFormValues({ phantomKey: "" }))
            dispatch(setIsOpen(false))

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })

    }, [userData?.token, GetLinkedInErrorQcList, dispatch, formValues.phantomKey])

    const GetQcList = useCallback(async ({ page = 1, selectedOptions = { key: "" } }) => {
        // setLoading(true);

        try {
            let object = {};

            if (selectedOptions?.key) {
                object.key = selectedOptions?.key;
            }
            const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

            const res = await fetch(
                `${API_PATH.Qc_EXPERT_LIST
                }?page=${page}&limit=10&${queryParams?.toString()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );

            const result = await res.json();
            if (result) {
                dispatch(setQcExpertList(result?.data));
                dispatch(setCallPage(result?.countData));
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            // setLoading(false);
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        // setLoading(false);
    }, [dispatch, userData?.token])

    useEffect(() => {
        if (!useOnlyOnce.current) {
            useOnlyOnce.current = true
            GetLinkedInErrorQcList({ page: 1, limit: 10 })
        }
    }, [GetLinkedInErrorQcList])


    const values = useMemo(() => ({
        GetLinkedInErrorQcList, dispatch, updateTempExpert, updatePhantomKey, GetQcList
    }), [GetLinkedInErrorQcList, dispatch, updateTempExpert, updatePhantomKey, GetQcList])

    return values
}


export default useQCExpert