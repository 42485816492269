import { authReducer } from "../features/auth.action";
import { creditManagementReducer } from "../features/creditManagement.slice";
import { documentListReducer } from "../features/documentList.slice";
import { internalMeetingReducer } from "../features/internalMeeting.slice";
// import { creditManagementReducer } from "../pages/CloudFeatures/CMS/Slice/creditManagement.slice";
// import { internalMeetingReducer } from "../pages/CloudFeatures/InternalMeetings/Slice/internalMeeting.slice";

import { qcexpertReducer } from "../features/QCExpert.slice";
import { addProjectReducer } from "../features/addProject.slice";
import { zoomMeetingReducer } from "../features/zoomMeeting.slice";
import { addNewExpertFormReducer } from "../features/addNewExpertForm.slice";
import { authExpertReducer } from "../features/authExpert.action";

//there are the reducers we created in whole application
const reducers = {
  internalmeeting: internalMeetingReducer,
  documentList: documentListReducer,
  creditManagement: creditManagementReducer,
  qcMeeting: qcexpertReducer,
  auth: authReducer,
  authExpert: authExpertReducer,
  zoomMeetingReducer: zoomMeetingReducer,
  addProjectReducer: addProjectReducer,
  addNewExpertFormReducer: addNewExpertFormReducer
  // internalmeeting: internalMeetingReducer,
  // creditManagement: creditManagementReducer
};
export default reducers;
