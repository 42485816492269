import { Modal, Spin, Tag, Tooltip } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsBuildings } from "react-icons/bs";
import { IoMdGlobe } from "react-icons/io";
import { TbListDetails } from "react-icons/tb";
import {
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";
import company_icon3 from "../../../assests/images/company_icons3.png";
import user_icon from "../../../assests/images/user.png";
import API_PATH from "../../../Constants/api-path";
import { currencyList } from "../../../Constants/Currency";
import { BsChevronLeft } from "../../../Utils/Icons";
import ProjectCallDetailsModal from "../../../pages/projects/ProjectCallDetailsModal";
import { ProjectListModal } from "../../../pages/projects/ProjectListModal";
import "../../../pages/Experts/ExpertProfile.css";
import ProjectDetails from "../../../pages/Experts/ProjectDetails";
import moment from "moment";

const text = <span>Industry</span>;
const text2 = <span>Tags</span>;
const customColors = ["#5746ec"];
function ExpertProfileModal({ isOpen, setIsOpen, expert_id }) {
    document.title = "Expert Cloud -  Expert Profile";

    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = React.useMemo(
        () => userData?.data?.roleData?.[0]?.role_permission,
        [userData?.data?.roleData]
    );
    document.title = "Nextyn | Expert Profile";
    const [Loading, setLoading] = useState(false);
    const [open, setOpen] = useState({
        id: "",
        isOpen: false,
    });
    const [expertDetails, setExpertDetails] = useState([]);
    const [expertProjectList, setExpertProjectList] = useState([]);
    const [educationDetails, setEducationDetails] = useState([]);
    const [experienceDetails, setexperienceDetails] = useState([]);
    const [isOpenProject, setIsOpenProject] = useState(false);

    const headers = React.useMemo(
        () => ({
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
        }),
        [userData.token]
    );

    const getExpertProfileDetails = useCallback(() => {
        if (expert_id) {
            axios
                .get(`${API_PATH.EXPERT_PROFILE}/${expert_id}`, {
                    headers,
                })
                .then((res) => {
                    setExpertDetails(res.data.data);
                    setExpertProjectList(res.data.allProjects);
                    setEducationDetails(res?.data?.data[0]?.experteducations);
                    setexperienceDetails(res?.data?.data[0]?.expertexperiences);
                    setLoading(true);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }

    }, [expert_id, headers]);

    useEffect(() => {
        if (expert_id) {
            getExpertProfileDetails();
        }
    }, [expert_id, getExpertProfileDetails]);



    if (Loading) {
        var experience = [];
        for (var i = 0; i < experienceDetails?.length; i++) {
            experience?.push(experienceDetails[i]);
        }

        var CN = [];
        function uniqueData(data) {
            var companyName = [];
            for (var i = 0; i < data.length; i++) {
                if (companyName.indexOf(data[i].company_name) == -1) {
                    companyName.push(data[i].company_name);
                }
            }
            CN.push(...companyName);
            return companyName;
        }

        uniqueData(experience);
        var store = [];

        for (let i = 0; i < CN.length; i++) {
            let local = [];
            let print = [];
            for (let j = 0; j < experience.length; j++) {
                if (CN[i] == experience[j].company_name) {
                    let obj = {};
                    obj.title = experience[j].company_name;
                    obj.dateFrom = experience[j].date_from;
                    obj.dateTill = experience[j].date_till
                        ? experience[j].date_till
                        : experience[j].till_present === "yes"
                            ? "Present"
                            : experience[j].experience === "Less than a year" && obj.dateFrom;
                    obj.whatWork = experience[j].what_work;
                    obj.location = experience[j].location;
                    obj.tillPresent = experience[j].till_present;
                    obj.experience = experience[j].experience;
                    obj.logoUrl = experience[j].logo_url;
                    obj.designation = experience[j].career_title;
                    obj.timeE = new Date(obj.dateFrom).getTime();
                    obj.timeS =
                        obj.dateTill === "Present"
                            ? new Date().getTime()
                            : experience[j].experience !== "Less than a year"
                                ? new Date(obj.dateTill).getTime()
                                : new Date(obj.dateFrom).getTime();
                    local.push(obj);
                }
                var min = Math.min(...local.map((item) => item.timeE));
                var max = Math.max(...local.map((item) => item.timeS));
            }

            var timed = {
                min: min,
                max: max,
            };

            print.push(
                CN[i],
                local.sort((a, b) => {
                    return b.timeS - a.timeS;
                }),
                timed
            );

            store.push(print);
            store = store.sort((a, b) => {
                return b[1][0].timeS - a[1][0].timeS;
            });
        }
    }

    const ConstantsArray = (array) => {
        var type = typeof array;
        let newArray = array;
        if (type === "string") {
            newArray = array?.split(",");
        }
        return newArray;
    };

    function getMonthDifference(startDate, endDate) {
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }

    const Convert_Month_Year = (months) => {
        var month = months + 1;

        var year = Math.floor(month / 12);
        if (year === 0) {
            var month_year = (month % 12) + " mos";
            return month_year;
        } else if (month % 12 === 0) {
            var month_year = year + " yrs ";
            return month_year;
        } else {
            var month_year = year + " yrs " + (month % 12) + " mos";
            return month_year;
        }
    };

    const convertDate = (date) => {
        if (date !== null && date !== "") {
            var d = new Date(date);
            var day = d.getDate();
            var x = d.toDateString().substr(4, 3);
            var year = d.getFullYear();
            let p = `${x} ${day}, ${year}`;
            return p;
        } else {
            return "---------";
        }
    };

    function expertSummery(data) {
        if (data !== null && data !== "" && data !== undefined) {
            return (
                <div
                    className="comon-divs-re"
                    style={{ width: "100%", paddingRight: "20px" }}
                >
                    <div className="pvs-header__title-container">
                        <h2 className="pvs-header__title text-heading-large mt-0">
                            <span
                                aria-hidden="true"
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                About
                            </span>
                        </h2>
                    </div>

                    <div className="inline-show-more-text">
                        <span
                            aria-hidden="true"
                            data-name="background_summery"
                            style={{ fontSize: "14px" }}
                        >
                            {data}
                        </span>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = user_icon;
    };

    return (
        <>
            <Modal title={"Expert Profile"}
                open={isOpen}
                centered
                onCancel={() => {
                    setIsOpen(false)
                }}

                bodyStyle={{
                    height: 600,
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
                width={1200}
                footer={false}
                zIndex={2000}
            >
                {!Loading ?
                    <div
                        className={
                            !Loading
                                ? "text-center loginScreenLinkedin active"
                                : "text-center loginScreenLinkedin"
                        }
                    >
                        <Spin size="large" />
                    </div> :
                    <div className="page-wrapper chiller-theme toggled">

                        {/* <main className="body-total content-wrapper float-start w-100"> */}
                        <div>
                            {/* <div className="bk-div float-start w-100"> */}
                            {/* <div className="body-expart-div request-page projects-scope"> */}
                            <div className="mt-4">
                                <section className="content">
                                    <div className="findExperts">
                                        <div className="linkedinBody">
                                            <Row className="g-3">
                                                <Col>
                                                    <div className="bg-white expertBox mb-3">
                                                        <div className="p-4">
                                                            <div className="g-0 d-flex justify-content-between">
                                                                <div
                                                                    className="fixedWidthCol position-relative"
                                                                    style={{ width: "150px" }}
                                                                >
                                                                    {expertDetails[0]?.image !== null &&
                                                                        expertDetails[0]?.image !== "" &&
                                                                        expertDetails[0]?.image ? (
                                                                        <>
                                                                            <img
                                                                                src={expertDetails[0]?.image}
                                                                                alt={expertDetails[0]?.first_name}
                                                                                style={{
                                                                                    borderRadius: "50%",
                                                                                    width: "100%",
                                                                                }}
                                                                                onError={addDefaultSrc}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <img
                                                                            src={user_icon}
                                                                            alt={expertDetails[0]?.first_name}
                                                                            style={{
                                                                                borderRadius: "50%",
                                                                                width: "100%",
                                                                            }}
                                                                            loading="lazy"
                                                                        />
                                                                    )}

                                                                    <Tooltip
                                                                        placement="top"
                                                                        color="#5746EB"
                                                                        title={
                                                                            expertDetails[0]
                                                                                ?.by_linkedinAccount === "Self"
                                                                                ? "Self Registered"
                                                                                : "Added by " +
                                                                                expertDetails[0]
                                                                                    ?.by_linkedinAccount
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                expertDetails[0]
                                                                                    ?.by_linkedinAccount +
                                                                                " expertTypes"
                                                                            }
                                                                        ></div>
                                                                    </Tooltip>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <h5
                                                                            style={{ fontWeight: 600 }}
                                                                            className="m-0"
                                                                        >
                                                                            {expertDetails[0]?.hourly_currency
                                                                                ? expertDetails[0]
                                                                                    ?.hourly_currency
                                                                                : "USD"}{" "}
                                                                            {expertDetails[0]
                                                                                ?.hourly_rate_call_cunsltn && expertDetails[0]?.hourly_rate_call_cunsltn > 0
                                                                                ? expertDetails[0]
                                                                                    ?.hourly_rate_call_cunsltn
                                                                                : "250"}
                                                                            /
                                                                            <small className="text-muted fw-normal">
                                                                                {expertDetails[0]
                                                                                    ?.charge_duration || "hour"}
                                                                            </small>
                                                                        </h5>
                                                                        <h5 className="my-1">
                                                                            <svg
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    color: "rgb(87, 70, 236)",
                                                                                }}
                                                                                stroke="currentColor"
                                                                                fill="currentColor"
                                                                                stroke-width="0"
                                                                                viewBox="0 0 1024 1024"
                                                                                height="1em"
                                                                                width="1em"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
                                                                            </svg>
                                                                            <span
                                                                                className="ps-3"
                                                                                style={{ fontSize: "15px" }}
                                                                            >
                                                                                Since{" "}
                                                                                {convertDate(
                                                                                    expertDetails[0]?.createdAt
                                                                                        ? expertDetails[0]?.createdAt
                                                                                        : expertDetails[0]?.created_at
                                                                                )}
                                                                            </span>
                                                                        </h5>
                                                                        <h5 className="my-1">
                                                                            <svg
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    color: "rgb(87, 70, 236)",
                                                                                }}
                                                                                stroke="currentColor"
                                                                                fill="currentColor"
                                                                                stroke-width="0"
                                                                                viewBox="0 0 512 512"
                                                                                height="1em"
                                                                                width="1em"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    fill="none"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="32"
                                                                                    d="M320 96H88a40 40 0 00-40 40v240a40 40 0 0040 40h334.73a40 40 0 0040-40V239"
                                                                                ></path>
                                                                                <path
                                                                                    fill="none"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="32"
                                                                                    d="M112 160l144 112 87-65.67"
                                                                                ></path>
                                                                                <circle
                                                                                    cx="431.95"
                                                                                    cy="128.05"
                                                                                    r="47.95"
                                                                                ></circle>
                                                                                <path d="M432 192a63.95 63.95 0 1163.95-63.95A64 64 0 01432 192zm0-95.9a32 32 0 1031.95 32 32 32 0 00-31.95-32z"></path>
                                                                            </svg>
                                                                            <span
                                                                                className="ps-3"
                                                                                style={{ fontSize: "15px" }}
                                                                            >
                                                                                {expertDetails[0]?.email}
                                                                            </span>
                                                                        </h5>
                                                                        <h5 className="my-1">
                                                                            <svg
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    color: "rgb(87, 70, 236)",
                                                                                }}
                                                                                stroke="currentColor"
                                                                                fill="currentColor"
                                                                                stroke-width="0"
                                                                                version="1.2"
                                                                                baseProfile="tiny"
                                                                                viewBox="0 0 24 24"
                                                                                height="1em"
                                                                                width="1em"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path d="M8 19h-3v-10h3v10zm11 0h-3v-5.342c0-1.392-.496-2.085-1.479-2.085-.779 0-1.273.388-1.521 1.165v6.262h-3s.04-9 0-10h2.368l.183 2h.062c.615-1 1.598-1.678 2.946-1.678 1.025 0 1.854.285 2.487 1.001.637.717.954 1.679.954 3.03v5.647z"></path>
                                                                                <ellipse
                                                                                    cx="6.5"
                                                                                    cy="6.5"
                                                                                    rx="1.55"
                                                                                    ry="1.5"
                                                                                ></ellipse>
                                                                            </svg>
                                                                            <span
                                                                                className="ps-3"
                                                                                style={{ fontSize: "15px" }}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        expertDetails[0]?.linkedin_url
                                                                                    }
                                                                                    style={{
                                                                                        color: "#5746ec",
                                                                                        fontWeight: 500,
                                                                                    }}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    View
                                                                                </a>
                                                                            </span>
                                                                        </h5>

                                                                        {expertDetails[0]?.company !== null &&
                                                                            expertDetails[0]?.company !==
                                                                            undefined ? (
                                                                            <h5 className="my-1">
                                                                                <svg
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "rgb(87, 70, 236)",
                                                                                    }}
                                                                                    stroke="currentColor"
                                                                                    fill="currentColor"
                                                                                    stroke-width="0"
                                                                                    viewBox="0 0 16 16"
                                                                                    height="1em"
                                                                                    width="1em"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"></path>
                                                                                    <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"></path>
                                                                                </svg>
                                                                                <span
                                                                                    className="ps-3"
                                                                                    style={{ fontSize: "15px" }}
                                                                                >
                                                                                    {expertDetails[0]?.company}
                                                                                </span>
                                                                            </h5>
                                                                        ) : (
                                                                            <></>
                                                                        )}

                                                                        {expertDetails[0]?.contact_number ===
                                                                            null ||
                                                                            expertDetails[0]?.contact_number ===
                                                                            undefined ||
                                                                            expertDetails[0]?.contact_number ===
                                                                            "" ? null : (
                                                                            <h5 className="my-1">
                                                                                <svg
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "rgb(87, 70, 236)",
                                                                                    }}
                                                                                    stroke="currentColor"
                                                                                    fill="currentColor"
                                                                                    stroke-width="0"
                                                                                    viewBox="0 0 24 24"
                                                                                    height="1em"
                                                                                    width="1em"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        fill="none"
                                                                                        d="M0 0h24v24H0V0z"
                                                                                    ></path>
                                                                                    <path
                                                                                        fill-opacity=".3"
                                                                                        d="M16.49 4.5c1.79 0 3.42.73 4.59 1.91l1.06-1.06A7.94 7.94 0 0016.49 3c-2.21 0-4.21.9-5.66 2.34l1.06 1.06a6.545 6.545 0 014.6-1.9z"
                                                                                    ></path>
                                                                                    <path
                                                                                        fill-opacity=".3"
                                                                                        d="M16.49 6c-1.38 0-2.63.56-3.54 1.46l1.06 1.06c.63-.63 1.51-1.03 2.47-1.03s1.84.39 2.47 1.03l1.06-1.06A4.94 4.94 0 0016.49 6z"
                                                                                    ></path>
                                                                                    <path d="M16.49 9c-.55 0-1.05.22-1.41.59L16.49 11l1.41-1.41c-.36-.37-.86-.59-1.41-.59z"></path>
                                                                                    <path
                                                                                        d="M15 17.83c1.29.54 2.63.89 4 1.07v-2.23l-2.35-.47L15 17.83zM7.33 5H5.1c.18 1.37.53 2.7 1.07 4L7.8 7.35 7.33 5z"
                                                                                        opacity=".3"
                                                                                    ></path>
                                                                                    <path d="M20.2 14.87l-3.67-.73c-.5-.1-.83.2-.9.27l-2.52 2.5c-2.5-1.43-4.57-3.5-6-6l2.5-2.52c.23-.24.33-.57.27-.9L9.13 3.8c-.09-.46-.5-.8-.98-.8H4c-.56 0-1.03.47-1 1.03.17 2.89 1.05 5.6 2.43 7.97 1.58 2.73 3.85 4.99 6.57 6.57 2.37 1.37 5.08 2.26 7.97 2.43.55.03 1.03-.43 1.03-1v-4.15c0-.48-.34-.89-.8-.98zM5.1 5h2.23l.47 2.35L6.17 9c-.54-1.3-.9-2.63-1.07-4zM19 18.9c-1.37-.18-2.7-.53-4-1.07l1.65-1.63 2.35.47v2.23z"></path>
                                                                                </svg>
                                                                                <span
                                                                                    className="ps-3"
                                                                                    style={{ fontSize: "15px" }}
                                                                                >
                                                                                    {expertDetails[0]
                                                                                        ?.contact_code &&
                                                                                        !expertDetails[0]?.contact_code.includes(
                                                                                            "+"
                                                                                        )
                                                                                        ? `+${expertDetails[0]?.contact_code}`
                                                                                        : expertDetails[0]
                                                                                            ?.contact_code}{" "}
                                                                                    {
                                                                                        expertDetails[0]
                                                                                            ?.contact_number
                                                                                    }
                                                                                </span>
                                                                            </h5>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Row>
                                                                <Col>
                                                                    <div className="mt-3">
                                                                        {expertDetails[0]?.name !== null &&
                                                                            expertDetails[0]?.name !==
                                                                            undefined &&
                                                                            expertDetails[0]?.name !== "" ? (
                                                                            <h3 className="find_companyName mb-0 mt-0">
                                                                                {expertDetails[0]?.name}
                                                                            </h3>
                                                                        ) : (
                                                                            <h3 className="find_companyName mb-0 mt-0">
                                                                                {expertDetails[0]?.first_name}{" "}
                                                                                {expertDetails[0]?.last_name}
                                                                            </h3>
                                                                        )}

                                                                        <div className="d-flex">
                                                                            {expertDetails[0]?.country !==
                                                                                null &&
                                                                                expertDetails[0]?.country !==
                                                                                undefined ? (
                                                                                <div className="text-muted pe-4 gap-2 g-2">
                                                                                    <IoMdGlobe
                                                                                        style={{ color: "#5746ec" }}
                                                                                    />
                                                                                    <span className="card-text ps-3">
                                                                                        <small className="text-muted">
                                                                                            {expertDetails[0]?.country}
                                                                                        </small>
                                                                                    </span>
                                                                                </div>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col>
                                                                    {expertDetails[0]?.industry !== null &&
                                                                        expertDetails[0]?.industry !==
                                                                        undefined ? (
                                                                        <>
                                                                            {customColors?.map((color) => (
                                                                                <Tooltip
                                                                                    placement="top"
                                                                                    color={color}
                                                                                    title={text}
                                                                                >

                                                                                    <BsBuildings
                                                                                        style={{
                                                                                            fontSize: "20px",
                                                                                            color: "rgb(87, 70, 236)",
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            ))}

                                                                            <span
                                                                                className="ps-3"
                                                                                style={{ fontSize: "15px" }}
                                                                            >
                                                                                {ConstantsArray(
                                                                                    expertDetails[0]?.industry
                                                                                )?.map((curElem, id) => {
                                                                                    // Added By Dharmendra
                                                                                    return (
                                                                                        <>
                                                                                            <Tag
                                                                                                color="blue"
                                                                                                bordered={false}
                                                                                                key={id}
                                                                                            >
                                                                                                {curElem}
                                                                                            </Tag>
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Col>
                                                            </Row>

                                                            <Row className="mt-2">
                                                                <Col>
                                                                    {expertDetails[0]?.tags !== null &&
                                                                        expertDetails[0]?.tags !== undefined ? (
                                                                        <>
                                                                            {customColors.map((color) => (
                                                                                <Tooltip
                                                                                    placement="top"
                                                                                    color={color}
                                                                                    title={text2}
                                                                                >
                                                                                    <svg
                                                                                        style={{
                                                                                            fontSize: "20px",
                                                                                            color: "rgb(87, 70, 236)",
                                                                                        }}
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        stroke-width="1.5"
                                                                                        viewBox="0 0 24 24"
                                                                                        aria-hidden="true"
                                                                                        height="1em"
                                                                                        width="1em"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </Tooltip>
                                                                            ))}

                                                                            <span
                                                                                className="ps-3"
                                                                                style={{ fontSize: "15px" }}
                                                                            >
                                                                                {ConstantsArray(
                                                                                    expertDetails[0]?.tags
                                                                                )?.map((curElem, id) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Tag
                                                                                                color="volcano"
                                                                                                bordered={false}
                                                                                                key={id}
                                                                                            >
                                                                                                {curElem}
                                                                                            </Tag>
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>

                                                    {expertSummery(
                                                        expertDetails[0]?.background_summery
                                                    )}
                                                </Col>
                                            </Row>


                                            {store.length > 0 &&
                                                [2, "2"]?.includes(
                                                    expertDetails?.[0]?.linkedin_url_status
                                                ) ? (
                                                <div
                                                    className="comon-divs-re"
                                                    style={{ width: "100%", paddingRight: "20px" }}
                                                >
                                                    <div className="pvs-header__title-container">
                                                        <h2 className="pvs-header__title text-heading-large mt-0">
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                Experience
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    <div className="inline-show-more-text">
                                                        {store.map((curElem, id) => {
                                                            return (
                                                                <>
                                                                    <div className="companies" key={id}>
                                                                        <div className="companyLogo mb-4">
                                                                            {curElem[1][0].logoUrl !== null &&
                                                                                curElem[1][0].logoUrl !== "" &&
                                                                                curElem[1][0].logoUrl ? (
                                                                                <img
                                                                                    src={curElem[1][0].logoUrl}
                                                                                    alt="logo"
                                                                                    onError={addDefaultSrc}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={company_icon3}
                                                                                    alt={curElem[1][0].logoUrl}
                                                                                    loading="lazy"
                                                                                />
                                                                            )}

                                                                            <div style={{ marginLeft: "20px" }}>
                                                                                <h5
                                                                                    className="companyName"
                                                                                    style={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: 500,
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                >
                                                                                    {curElem[0]}
                                                                                </h5>
                                                                                {curElem[1][0].tillPresent ===
                                                                                    "yes" ? (
                                                                                    <p className="totalExperience">
                                                                                        {/* Calculate duration from dateFrom to current date */}
                                                                                        {Convert_Month_Year(
                                                                                            getMonthDifference(
                                                                                                new Date(curElem[2]?.min),
                                                                                                new Date(Date.now())
                                                                                            )
                                                                                        )}
                                                                                    </p>
                                                                                ) : (
                                                                                    <p className="totalExperience">
                                                                                        {/* Calculate duration from dateFrom to dateTill */}
                                                                                        {curElem[1][0]?.experience !==
                                                                                            "Less than a year"
                                                                                            ? Convert_Month_Year(
                                                                                                getMonthDifference(
                                                                                                    new Date(
                                                                                                        curElem[2]?.min
                                                                                                    ),
                                                                                                    new Date(
                                                                                                        curElem[2]?.max
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                            : curElem[1][0]?.dateFrom +
                                                                                            " - " +
                                                                                            curElem[1][0]?.experience}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="experienceWrapper">
                                                                            {curElem[1].map((curElem, id) => {
                                                                                return (
                                                                                    <div
                                                                                        className="experienceInfo"
                                                                                        key={id}
                                                                                    >
                                                                                        <h5
                                                                                            className="companyName mb-1"
                                                                                            style={{
                                                                                                fontSize: "18px",
                                                                                            }}
                                                                                        >
                                                                                            {curElem.designation}
                                                                                        </h5>
                                                                                        <p
                                                                                            className="text-body-small inline t-black--light break-words"
                                                                                            style={{ fontWeight: 500 }}
                                                                                        >
                                                                                            {curElem?.experience !==
                                                                                                "Less than a year" &&
                                                                                                curElem.dateFrom +
                                                                                                " - " +
                                                                                                (curElem.dateTill ===
                                                                                                    null ||
                                                                                                    curElem.dateTill ===
                                                                                                    "" ||
                                                                                                    curElem?.dateTill === 1
                                                                                                    ? "Present"
                                                                                                    : curElem.dateTill)}
                                                                                        </p>
                                                                                        {curElem.location ? (
                                                                                            <p className="text-body-small inline t-black--light break-words mb-3">
                                                                                                {curElem.location}
                                                                                            </p>
                                                                                        ) : null}
                                                                                        <div
                                                                                            style={{
                                                                                                maxWidth: "92%",
                                                                                            }}
                                                                                        >
                                                                                            {curElem.what_work !==
                                                                                                null ? (
                                                                                                <p className="experienceContent">
                                                                                                    {curElem.whatWork}
                                                                                                </p>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {educationDetails?.length > 0 ? (
                                                <div
                                                    className="comon-divs-re"
                                                    style={{ width: "100%", paddingRight: "20px" }}
                                                >
                                                    <div className="pvs-header__title-container">
                                                        <h2 className="pvs-header__title text-heading-large mt-0">
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                Education
                                                            </span>
                                                        </h2>
                                                    </div>

                                                    {educationDetails.map((curElem, id) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="companyLogo mb-2"
                                                                    key={id}
                                                                >
                                                                    {curElem.logo_url !== null ? (
                                                                        <img
                                                                            src={curElem.logo_url}
                                                                            alt="logo"
                                                                            onError={addDefaultSrc}
                                                                        />
                                                                    ) : (
                                                                        <img src={company_icon3} alt="logo" />
                                                                    )}
                                                                    <div style={{ marginLeft: "20px" }}>
                                                                        <h5
                                                                            className="companyName"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                marginBottom: "5px",
                                                                            }}
                                                                        >
                                                                            {curElem.institute_name}
                                                                        </h5>
                                                                        <p className="totalExperience">
                                                                            {curElem.degree}
                                                                        </p>
                                                                        <p className="text-body-small inline t-black--light break-words">
                                                                            {curElem.description !== null
                                                                                ? curElem.description
                                                                                : ""}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {expertDetails?.[0]?.expertpaymentdetails.length > 0 &&
                                                permission?.[2]?.expert?.paymentDetails ? (
                                                <div
                                                    className="comon-divs-re"
                                                    style={{ width: "100%", paddingRight: "20px" }}
                                                >
                                                    <div className="pvs-header__title-container">
                                                        <h2 className="pvs-header__title text-heading-large mt-0">
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                Payment Details
                                                            </span>
                                                        </h2>
                                                        {expertDetails?.[0]?.expertpaymentdetails?.map(
                                                            (curElemData, index) => (
                                                                <div key={curElemData?._id}>
                                                                    {curElemData?.payment_method.toLowerCase() ===
                                                                        "bank" &&
                                                                        curElemData?.status === true && (
                                                                            <>
                                                                                {curElemData?.country && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Country:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.country}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.account_holder_name && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Account holder name:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.account_holder_name
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}

                                                                                {curElemData?.account_number && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Account number:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.account_number
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}

                                                                                {curElemData?.bank_branch_address && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Bank branch address:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.bank_branch_address
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}

                                                                                {curElemData?.bank_name && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Bank name:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.bank_name}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}

                                                                                {curElemData?.branch_code && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Branch Code/BSB Code:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.branch_code}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.bic_or_swift && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                BIC/SWIFT:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.bic_or_swift
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}

                                                                                {curElemData?.iban_ifsc && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                IFSC/IBAN:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.iban_ifsc}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.micr_code && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                MICR/Sort Code:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.micr_code}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.ach_routing_code && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                ACH Routing Code:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.ach_routing_code
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.clabe && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                CLABE:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.clabe}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.currency && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Payment Currency:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.currency} (
                                                                                                {
                                                                                                    currencyList?.filter(
                                                                                                        (curr) =>
                                                                                                            curr.code ===
                                                                                                            curElemData?.currency
                                                                                                    )?.[0]?.name
                                                                                                }
                                                                                                )
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.expert_address && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Expert Address:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.expert_address
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.pan_number ? (
                                                                                    <>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <h2 className="pvs-header__title text-heading-large mt-0">
                                                                                                    <span
                                                                                                        aria-hidden="true"
                                                                                                        style={{
                                                                                                            fontSize: "20px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Other Details
                                                                                                    </span>
                                                                                                </h2>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="d-flex text-center align-items-center">
                                                                                                <strong className="me-2 bank_title">
                                                                                                    PAN (of the account
                                                                                                    holder):
                                                                                                </strong>
                                                                                                <p className="pymtP">
                                                                                                    {curElemData?.pan_number.toUpperCase()}
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="d-flex text-center align-items-center">
                                                                                                <strong className="me-2 bank_title">
                                                                                                    PAN Link with Aadhar:
                                                                                                </strong>
                                                                                                <p className="pymtP">
                                                                                                    {curElemData?.pan_adhar_link_status
                                                                                                        .charAt(0)
                                                                                                        .toUpperCase() +
                                                                                                        curElemData?.pan_adhar_link_status.slice(
                                                                                                            1
                                                                                                        )}
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                </div>
                                                            )
                                                        )}

                                                        {expertDetails?.[0]?.expertpaymentdetails?.map(
                                                            (curElemData, index) => (
                                                                <div key={curElemData?._id}>
                                                                    {curElemData?.payment_method.toLowerCase() ===
                                                                        "amazon" &&
                                                                        curElemData?.status === true && (
                                                                            <>
                                                                                <Row>
                                                                                    <Col className="d-flex text-center align-items-center">
                                                                                        <strong className="me-2 bank_title">
                                                                                            Amazon Email Id:
                                                                                        </strong>
                                                                                        <p className="pymtP">
                                                                                            {
                                                                                                curElemData?.amazn_email_id
                                                                                            }
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            )
                                                        )}

                                                        {expertDetails?.[0]?.expertpaymentdetails?.map(
                                                            (curElemData, index) => (
                                                                <div key={curElemData._id}>
                                                                    {curElemData.payment_method.toLowerCase() ===
                                                                        "paypal" &&
                                                                        curElemData?.status === true && (
                                                                            <>
                                                                                <Row>
                                                                                    <Col className="d-flex text-center align-items-center">
                                                                                        <strong className="me-2 bank_title">
                                                                                            Paypal registered email id:
                                                                                        </strong>
                                                                                        <p className="pymtP">
                                                                                            {curElemData?.paypal_email_id
                                                                                                ? curElemData?.paypal_email_id
                                                                                                : curElemData?.amazn_email_id}
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            )
                                                        )}

                                                        {expertDetails?.[0]?.expertpaymentdetails?.map(
                                                            (curElemData, index) => (
                                                                <div key={curElemData._id}>
                                                                    {curElemData?.payment_method.toLowerCase() ===
                                                                        "charity" &&
                                                                        curElemData?.status === true && (
                                                                            <>
                                                                                {curElemData?.name_of_charity && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Name of Charity:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.name_of_charity
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.contact_person_name && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Contact Person Name:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.contact_person_name
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.country && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Country:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.country}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.contact_number && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Contact Number With
                                                                                                Country Code:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.contact_number
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.email && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Email:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {curElemData?.email}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.website_link && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Website Link:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.website_link
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {curElemData?.donation_link && (
                                                                                    <Row>
                                                                                        <Col className="d-flex text-center align-items-center">
                                                                                            <strong className="me-2 bank_title">
                                                                                                Donation Link:
                                                                                            </strong>
                                                                                            <p className="pymtP">
                                                                                                {
                                                                                                    curElemData?.donation_link
                                                                                                }
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                </div>
                                                            )
                                                        )}
                                                        {expertDetails?.[0]?.expertpaymentdetails?.[0]?.createdAt && (
                                                            <Row>
                                                                <Col className="d-flex text-center align-items-center">
                                                                    <strong className="me-2 bank_title">
                                                                        Created At:
                                                                    </strong>
                                                                    <p className="pymtP">

                                                                        {moment(expertDetails?.[0]?.expertpaymentdetails?.[0]?.createdAt)?.format("MMMM Do YYYY")}

                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        {expertDetails?.[0]?.expertpaymentdetails?.[0]?.updatedAt && (
                                                            <Row>
                                                                <Col className="d-flex text-center align-items-center">
                                                                    <strong className="me-2 bank_title">
                                                                        Last Updated At:
                                                                    </strong>
                                                                    <p className="pymtP">

                                                                        {moment(expertDetails?.[0]?.expertpaymentdetails?.[0]?.updatedAt)?.format("MMMM Do YYYY")}

                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {expertProjectList?.length > 0 && (
                                                <div
                                                    className="comon-divs-re"
                                                    style={{ width: "100%", paddingRight: "20px" }}
                                                >
                                                    <div className="pvs-header__title-container">
                                                        <h2 className="pvs-header__title text-heading-large mt-0">
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                Projects
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    {expertProjectList.map((curElem, id) => {
                                                        return (
                                                            <div className="attachment_overview d-flex align-items-center gap-2">
                                                                <Link
                                                                    to={`/dashboard/team/project-overview?project_id=${curElem?.project_id}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="d-flex align-items-center gap-1"
                                                                >
                                                                    {curElem.project_title}
                                                                </Link>
                                                                {curElem?.call_history?.length > 0 && (
                                                                    <Tooltip
                                                                        title={"Call Details"}
                                                                        color="#5746EB"
                                                                    >
                                                                        <span
                                                                            onClick={() => {
                                                                                setOpen({
                                                                                    id: curElem?.project_id,
                                                                                    isOpen: true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <TbListDetails
                                                                                style={{ fontSize: "14px" }}
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                            </div>

                                                            // <li>
                                                            //   <p
                                                            //     onClick={() => {
                                                            //       navigate(
                                                            //         `/dashboard/team/project-overview?project_id=${curElem?.project_id}`
                                                            //       );
                                                            //     }}
                                                            //   >
                                                            //     {curElem.project_title}
                                                            //   </p>
                                                            //   {curElem?.call_history?.length > 0 && (
                                                            //     <Tooltip
                                                            //       title={"Call Details"}
                                                            //       color="#5746EB"
                                                            //     >
                                                            //       <span
                                                            //         style={{
                                                            //           fontSize: 25,
                                                            //           paddingBottom: 4,
                                                            //         }}
                                                            //         onClick={() => {
                                                            //           setOpen({
                                                            //             id: curElem?.project_id,
                                                            //             isOpen: true,
                                                            //           });
                                                            //         }}
                                                            //       >
                                                            //         <TbListDetails />
                                                            //       </span>
                                                            //     </Tooltip>
                                                            //   )}
                                                            // </li>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {expertDetails?.[0]?.project_details?.length > 0 && (
                                <div
                                    className="comon-divs-re mt-3"
                                    style={{ width: "100%", paddingRight: "20px" }}
                                >
                                    <ProjectDetails
                                        projectDetails={
                                            expertDetails?.[0]?.project_details || []
                                        }
                                    />
                                </div>
                            )}
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                        {/* </main> */}
                    </div>
                }

                <ProjectListModal
                    isOpenProject={isOpenProject}
                    setIsOpenProject={setIsOpenProject}
                    expertProjectList={expertProjectList}
                    getExpertProfileDetails={getExpertProfileDetails}
                />
                <ProjectCallDetailsModal
                    isOpen={open.isOpen}
                    project_id={open?.id}
                    setIsOpen={(val) => {
                        setOpen({
                            id: "",
                            isOpen: val,
                        });
                    }}
                    type="expert"
                />
            </Modal>
        </>
    );

}

export default ExpertProfileModal;
