import React, { useMemo } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Divider, Input, Modal, Pagination, Space, Table, Tag, Tooltip } from "antd";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearAll,
  setDeleteModal,
  setFormValues,
  setMeetingInfo,
  setOpenAddMeethingModel,
  setSelectedMeeting,
  setSelectedMeetingDetails,
  setType,
} from "../../../features/internalMeeting.slice";
import CreateMeetingModal from "./Components/CreateMeetingModal";
import useCreateMeeting from "./hooks/useCreateMeeting";
import moment from "moment";
import InternalMeetingDrawer from "./Components/InternalMeetingDrawer";
import { Link } from "react-router-dom";
import _ from "lodash";
function InternalMeetingTable() {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  const { meeting_page, deleteModal } = useSelector(
    (state) => state.internalmeeting
  );
  const {
    meetingList,
    fetchMeetings,
    replaceTxt,
    handleDeleteMeeting,
    capitalizeFirstLetter,
    isLoading,
    isValidating,
    updateTranscript,
    loadingTranscript,
    loadingTranscriptDraft,
  } = useCreateMeeting();

  dayjs.extend(customParseFormat);

  const dataSource = meetingList?.data?.map((data, index) => ({
    topic: (
      <div
        className="cursor-pointer truncate-textTable"
        onClick={(e) => {
          e.preventDefault();
          if (permission?.[13]?.internalMeeting?.view) {
            dispatch(setMeetingInfo(true));
            handleActionClick(data);
          }
        }}
      >
        <Tooltip
          color="#5746ec"
          title={capitalizeFirstLetter(
            replaceTxt(data?.zoom_meeting_data?.topic, [
              "nextyn",
              "micro",
              "consulting",
              "-consulting",
              "call - ",
              "call",
              "-",
            ])
          )}
        >
          {_.truncate(
            capitalizeFirstLetter(
              replaceTxt(data?.zoom_meeting_data?.topic, [
                "nextyn",
                "micro",
                "consulting",
                "-consulting",
                "call - ",
                "call",
                "-",
              ])
            ),
            {
              length: 40,
              omission: "...",
            }
          )}
        </Tooltip>
        <div>
          <small className="text-muted">
            Unlocked -{" "}
            <span style={{ color: "#5746ec" }}>
              {data?.credits_transaction_count || 0}
            </span>
          </small>
        </div>
      </div>
    ),
    expert: (
      <Link
        to={`/expert-profile?expertId=${data?.expert_id?.[0]?._id}`}
        className="link truncate-textTable"
      >
        {data?.expert_id?.[0]?.name ? (
          <Tooltip title={data?.expert_id?.[0]?.name} color="#5746ec">
            {_.truncate(data?.expert_id?.[0]?.name, {
              length: 15,
              omission: "...",
            })}
          </Tooltip>
        ) : (
          "-"
        )}
      </Link>
    ),
    meetingId: (
      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          if (permission?.[13]?.internalMeeting?.view) {
            dispatch(setMeetingInfo(true));
            handleActionClick(data);
          }
        }}
      >
        <Tooltip title={data?.meetingId} color="#5746ec">
          {_.truncate(data?.meetingId, { length: 11, omission: "..." })}
        </Tooltip>
      </div>
    ),
    start_time: (
      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          if (permission?.[13]?.internalMeeting?.view) {
            dispatch(setMeetingInfo(true));
            handleActionClick(data);
          }
        }}
      >
        <div>
          {moment(data?.zoom_meeting_data?.start_time)?.format("DD MMM YYYY")}
        </div>
        <div className="text-muted" style={{ fontSize: "12px" }}>
          {moment(data?.zoom_meeting_data?.start_time)?.format("LT")} -{" "}
          {moment(data?.zoom_meeting_data?.start_time)
            .add(
              data?.zoom_meeting_data?.duration
                ? data?.zoom_meeting_data?.duration
                : 0,
              "minutes"
            )
            ?.format("LT")}
        </div>
      </div>
    ), //"22 Dec 2023 15:30 - 16:30",
    recordingStatus: (
      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          if (permission?.[13]?.internalMeeting?.view) {
            dispatch(setMeetingInfo(true));
            handleActionClick(data);
          }
        }}
      >
        <Tooltip
          color="#5746EB"
          title={`Meeting is ${data?.status === 0 ? "in draft" : "Published"}`}
        >
          <Tag color={data?.status === 0 ? "red" : "green"}>
            {data?.status === 0 ? "D" : "P"}
          </Tag>
        </Tooltip>
        <Tooltip
          color="#5746EB"
          title={`${data?.recordingStatus
            ? "Recording is downloaded"
            : "Waiting for recording"
            }`}
        >
          <Tag color={data?.recordingStatus ? "green" : "red"}>
            {data?.recordingStatus ? "S" : "W"}
          </Tag>
        </Tooltip>
        <Tooltip
          color="#5746EB"
          title={`${data?.recordingStatus && !data?.transcriptStatus
            ? "Transcript is processing"
            : data?.transcriptStatus
              ? "Transcript generated"
              : "Waiting for transcript"
            }`}
        >
          <Tag
            color={
              data?.recordingStatus && !data?.transcriptStatus
                ? "yellow"
                : data?.transcriptStatus
                  ? "green"
                  : "red"
            }
          >
            {data?.recordingStatus && !data?.transcriptStatus
              ? "P"
              : data?.transcriptStatus
                ? "S"
                : "W"}
          </Tag>
        </Tooltip>
      </div>
    ),
    // transcriptStatus: (
    //   <>
    //     <div
    //       className="cursor-pointer"
    //       onClick={(e) => {
    //         e.preventDefault();
    //         dispatch(setMeetingInfo(true));
    //         handleActionClick(data);
    //       }}
    //     >

    //     </div>
    //   </>
    // ),
    action: data,
  }));

  const items = [
    {
      label: (
        <div
          href="#"
          onClick={() => {
            if (permission?.[13]?.internalMeeting?.edit) {
              dispatch(setType("edit"));
              dispatch(setOpenAddMeethingModel(true));
            }
          }}
        >
          <Tooltip color="#5746EB" title={!permission?.[13]?.internalMeeting?.edit ? "Permission Denied" : ""}>
            <Space>
              <button
                className="viewBttnExpert"
                disabled={!permission?.[13]?.internalMeeting?.edit}
              >
                <i
                  className="	fa fa-edit"
                  aria-hidden="true"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                ></i>
              </button>
              {/* Edit */}
            </Space>
          </Tooltip>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          href="#"
          onClick={() => {
            if (permission?.[13]?.internalMeeting?.view) {
              dispatch(setMeetingInfo(true));
            }
          }}
        >
          <Tooltip color="#5746EB" title={!permission?.[13]?.internalMeeting?.view ? "Permission Denied" : ""}>
            <Space>
              <button
                className="viewBttnExpert"
                disabled={!permission?.[13]?.internalMeeting?.view}
              >
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                ></i>
              </button>
              {/* View Transcript */}
            </Space>
          </Tooltip>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          href="#"
          onClick={() => {
            if (permission?.[13]?.internalMeeting?.delete) {
              dispatch(setDeleteModal(true));
            }
          }}
        >
          <Tooltip color="#5746EB" title={!permission?.[13]?.internalMeeting?.delete ? "Permission Denied" : ""}>
            <Space>
              <button
                className="viewBttnExpert"
                disabled={!permission?.[13]?.internalMeeting?.delete}
              >
                <i
                  className="fa fa-trash-o"
                  aria-hidden="true"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                ></i>
              </button>
            </Space>
          </Tooltip>
        </div>
      ),
      key: "3",
    },
  ];
  const filteredItems = (data) =>
    data?.recordingStatus && data?.transcriptStatus
      ? items.filter((item) => item.key !== "0")
      : items;
  const handleActionClick = (data) => {
    dispatch(setSelectedMeeting(data));
    dispatch(setSelectedMeetingDetails({}));
    let obj = {
      zoomEmail: [
        {
          key: data?.zoom_account_details?.[0]?.email,
          label: data?.zoom_account_details?.[0]?.email,
          value: data?.zoom_account_details?.[0]?.email,
        },
      ],
      meetingId: data?.meetingId,
      call_taken_by: data?.call_taken_by?.map((user) => {
        return {
          key: user?._id,
          label: user?.name,
          value: user?._id,
        };
      }),
      expert_id: data?.expert_id?.map((user) => {
        return {
          key: user?._id,
          label: user?.name,
          value: user?._id,
        };
      }),
      project_title: data?.project_title,
      cloud_zoom_meetings_id: data?._id,
      company_select: data?.company_details?.map((company) => {
        return {
          key: company?._id,
          label: company?.company_name,
          value: company?._id,
        };
      }),
      country: data?.country
    };
    dispatch(setFormValues(obj));
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "topic",
      key: "topic",
      sorter: true,
    },
    // {
    //   title: "Unlock",
    //   dataIndex: "unlock",
    //   key: "unlock",
    // },
    {
      title: "Expert",
      dataIndex: "expert",
      key: "expert",
      sorter: true,
      width: "150px",
    },
    {
      title: "Meeting Id",
      dataIndex: "meetingId",
      key: "meetingId",
      width: "140px",
    },
    {
      title: "Date/Time",
      dataIndex: "start_time",
      key: "start_time",
      sorter: true,
      width: "140px",
    },
    {
      title: "Recording",
      dataIndex: "recordingStatus",
      key: "recordingStatus",
      // render: (tags, status) => {
      //   const color =
      //     tags === "Success"
      //       ? "green"
      //       : tags === "Processing"
      //       ? "yellow"
      //       : "red";
      //   return <Tag color={color}>{tags}</Tag>;
      // },
      width: "100px",
    },
    // {
    //   title: "Transcript",
    //   dataIndex: "transcriptStatus",
    //   key: "transcriptStatus",
    //   width: "100px",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data) => (
        <div className="d-flex gap-2">
          {filteredItems(data)?.map((action, index) => {
            return (
              <div
                key={index}
                className="cursor-pointer border-dark my-2"
                onClick={() => handleActionClick(data)}
              >
                {action.label}
              </div>
            );
          })}
        </div>
        // <Dropdown
        //   menu={{
        //     items: filteredItems(data)
        //   }}
        //   trigger={["click"]}
        // >
        //   <span
        //     onClick={(e) => {
        //       e.preventDefault();
        //       handleActionClick(data)
        //     }}
        //   >
        //     <Space size="middle">
        //       <MdDehaze style={{ cursor: "pointer" }} />
        //     </Space>
        //   </span>
        // </Dropdown>
      ),
      width: "100px",
    },
  ];

  const paginationSettings = useMemo(() => {
    return {
      current: meeting_page,
      onChange: (index) => fetchMeetings({ page_no: index }),
      pageSize: 10,
      total: meetingList?.count,
    };
  }, [fetchMeetings, meetingList?.count, meeting_page]);

  return (
    <div>
      <div className="bk-div float-start w-100">
        {" "}
        {/* <hr className="sp-hr" /> */}
        < >
          {/* <div className="d-flex justify-content-between align-items-center">
            <h2>Internal Meetings</h2>
            {permission?.[13]?.internalMeeting?.add && (
              <button
                onClick={() => {
                  dispatch(setType("create"));
                  dispatch(setSelectedMeeting({}));
                  dispatch(clearAll());
                  dispatch(setOpenAddMeethingModel(true));
                }}
                className="btn viewButton"
                size="large"
              >
                Add Meeting
              </button>
            )}
          </div> */}
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ marginBottom: 16 }}
            size="large"
            onChange={(e) =>
              fetchMeetings({ value: e.target.value, is_filtering: true })
            }
          />
          <Table
            dataSource={dataSource}
            columns={columns}
            className="paddTable"
            onChange={(pagination, filters, sorter) => {
              fetchMeetings({
                sort_Type: sorter?.order === "ascend" ? "1" : "-1",
                sort_By: sorter?.field,
              });
            }}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (e) => {
            //       e.preventDefault();
            //       dispatch(setMeetingInfo(true))
            //       handleActionClick(record.action)

            //     }
            //   }
            // }}

            pagination={false}
            scroll={{ x: 800 }}
            size="large"
          />
          <Pagination
            {...paginationSettings}
            showSizeChanger={false}
            showTotal={(callPage, range) =>
              `${range[0]}-${range[1]} of ${callPage} items`
            }
            style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
          />
        </>
        {/* Create Meeting Modal */}
        <CreateMeetingModal />
        {/* Delete Modal */}
        <Modal
          title={
            <div >
              Delete Meeting <Divider />
            </div>
          }
          open={deleteModal}
          onOk={() => {
            handleDeleteMeeting();
            dispatch(setDeleteModal(false));
          }}
          onCancel={() => dispatch(setDeleteModal(false))}
          width={800}
          centered
        >
          <Row>
            <Col>
              <div className="position-relative">
                <div>
                  This action cannot be undone. Are you sure you want to delete
                  this meeting ?
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
      <InternalMeetingDrawer
        isLoading={isLoading}
        isValidating={isValidating}
        updateTranscript={updateTranscript}
        loadingTranscript={loadingTranscript}
        loadingTranscriptDraft={loadingTranscriptDraft}
        capitalizeFirstLetter={capitalizeFirstLetter}
      />
    </div>
  )
}

export default InternalMeetingTable;
