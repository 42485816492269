import React, { useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearAll,
  setFormValues,
  setOpenAddMeethingModel,
  setSelectedMeeting,
} from "../../../../features/internalMeeting.slice";
import DebounceSelect from "./DebounceSelect";
import useCreateMeeting from "../hooks/useCreateMeeting";
function CreateMeetingModal() {
  const [form] = Form.useForm();
  const { openAddMeetingModal, formvalues, type } = useSelector(
    (state) => state.internalmeeting
  );
  const { addMeeting, updateMeeting } = useCreateMeeting();
  const dispatch = useDispatch();

  //set initial value as well as when form value change
  useEffect(() => {
    form.setFieldsValue(formvalues);
  }, [form, formvalues]);

  return (
    <Modal
      title={
        <span className="modalHeader">
          {type === "create" ? "Add Meeting" : "Update Meeting"}
        </span>
      }
      footer={false}
      open={openAddMeetingModal}
      onOk={() => dispatch(setOpenAddMeethingModel(false))}
      onCancel={() => {
        dispatch(setOpenAddMeethingModel(false));
        dispatch(setSelectedMeeting({}));
        dispatch(clearAll());
        form.resetFields();
      }}
      width={680}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={formvalues}
        onFinish={() => {
          if (type === "create") {
            addMeeting();
          } else {
            updateMeeting();
          }
          dispatch(setOpenAddMeethingModel(false));
          form.resetFields();
        }}
      >
        <Row>
          <Col>
            <Form.Item
              label="Select Zoom Account"
              name={"zoomEmail"}
              rules={[{ required: true, message: "Zoom account is required!" }]}
            >
              <Select
                size="large"
                placeholder="Select Zoom Account"
                labelInValue
                // value={formvalues.zoomEmail}
                onChange={(value) => {
                  let obj = {
                    ...formvalues,
                    zoomEmail: [value],
                  };
                  form.setFieldValue("zoomEmail", value);
                  dispatch(setFormValues(obj));
                }}
                options={[
                  {
                    label: "info@nextyn.com",
                    value: "info@nextyn.com",
                  },
                  {
                    label: "consulting@nextyn.com",
                    value: "consulting@nextyn.com",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Meeting Id"
              name="meetingId"
              rules={[
                {
                  required: true,
                  message: "meeting id is required!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="89223750266"
                name="meetingId"
                // value={formvalues.meetingId}
                onChange={(e) => {
                  let obj = {
                    ...formvalues,
                    meetingId: e.target.value,
                  };
                  dispatch(setFormValues(obj));
                  form.setFieldValue("meetingId", e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* <Row>
                    <Col>
                        <Form.Item label="Passcode">
                            <Input size="large" placeholder="273242" name="passcode" />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item label="Meeting End Date and Time">
                            <DatePicker
                                format="YYYY-MM-DD HH:mm"
                                disabledDate={disabledDate}
                                size={"large"}
                                style={{ width: "100%" }}
                                // disabledTime={disabledDateTime}
                                showTime={{
                                    defaultValue: dayjs("00:00", "HH:mm"),
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}

        <Row>
          <Col>
            <Form.Item
              label="Call taken by"
              name="call_taken_by"
              rules={[
                { required: true, message: "Call Taken By is required!" },
              ]}
            >
              <DebounceSelect
                type="manager"
                size="large"
                mode="multiple"
                // value={formvalues?.call_taken_by}
                placeholder="Select Managers"
                onChange={(newValue) => {
                  let obj = {
                    ...formvalues,
                    call_taken_by: newValue,
                  };
                  dispatch(setFormValues(obj));
                  form.setFieldValue("call_taken_by", newValue);
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Expert Name"
              name={"expert_id"}
              rules={[{ required: true, message: "Expert name is required!" }]}
            >
              <DebounceSelect
                type="expert"
                size="large"
                // value={formvalues?.expert_id}
                placeholder="Select Expert"
                onChange={(newValue) => {
                  let obj = {
                    ...formvalues,
                    expert_id: [newValue],
                  };
                  dispatch(setFormValues(obj));
                  form.setFieldValue("expert_id", newValue);
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item
              label="Project Title (If Applicable)"
              name={"project_title"}
            >
              <Input
                size="large"
                // value={formvalues.project_title}
                placeholder="Ccass Market in India"
                name="project_title"
                onChange={(e) => {
                  let obj = {
                    ...formvalues,
                    project_title: e.target.value,
                  };
                  dispatch(setFormValues(obj));
                  form.setFieldValue("project_title", e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="d-flex justify-content-end align-items-center">
          <Form.Item style={{ marginBottom: 0 }}>
            <button
              class="secondaryBttn btn"
              type="button"
              onClick={() => {
                form.resetFields();
                dispatch(setOpenAddMeethingModel(false));
                dispatch(setSelectedMeeting({}));
                dispatch(clearAll());
              }}
            >
              Close
            </button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <button
              className="viewButton btn ms-2"
              type="submit"
              htmlType="submit"
            >
              {type === "create" ? "Add Meeting" : "Update Meeting"}
            </button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

export default CreateMeetingModal;
