import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setSelectedMeetingDetails,
  setTranscriptText,
} from "../../../../features/internalMeeting.slice";

function CustomEditor() {
  const dispatch = useDispatch();
  const [speakerView, setSpeakerView] = useState("");
  const { selectedMeetingDetails, transcriptText } = useSelector(
    (state) => state.internalmeeting
  );

  const typeOf = typeof transcriptText;
  useEffect(() => {
    let trnscrpt = "";
    if (typeOf === "object") {
      //   const trnscrpt = transcriptText?.map((value) => (
      //     <p>
      //       <strong>{value?.speaker}: </strong>
      //       {value?.text}
      //     </p>
      //   ));

      transcriptText?.map((value) => {
        trnscrpt += `<p><strong>Speaker ${value?.speaker} : </strong>${value?.text}</p>`;
      });
    }
    trnscrpt && setSpeakerView(trnscrpt);
  }, [transcriptText, typeOf]);

  const editorRef = useRef();

  const handleTranscript = useCallback(
    (editor) => {
      if (selectedMeetingDetails?.modified_transcript) {
        const newData = editor.getData();
        let newDetails = {
          ...selectedMeetingDetails,
          modified_transcript: newData,
        };
        dispatch(setSelectedMeetingDetails(newDetails));
        dispatch(setTranscriptText(newData));
      } else {
        const newData = editor.getData();
        let newDetails = { ...selectedMeetingDetails, transcript: newData };
        dispatch(setSelectedMeetingDetails(newDetails));
        dispatch(setTranscriptText(newData));
      }
    },
    [dispatch, selectedMeetingDetails],
  )

  return (
    <CKEditor
      editor={Editor}
      data={typeOf === "object" ? speakerView : transcriptText} // Prefill CKEditor with fetched overview data
      onReady={(editor) => {
        editorRef.current = editor;
      }}
      config={{
        toolbar: [
          "heading",
          "bold",
          "italic",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "underline",
          "strikethrough",
          "|",
          "alignment",
          "undo",
          "redo",
        ],
      }}
      onChange={(event, editor) => {
        handleTranscript(editor)

      }}
    />
  );
}

export default memo(CustomEditor);
