import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import OtpInput from "react-otp-input-rc-17";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import API_URL from "../../../Constants/api-path";
import { email_login, otp_login } from "../../../features/authExpert.action";

function LoginForm() {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [userData, setValue] = useState({
    password: "",
    email: "",
    loginType: "" //password or otp
  });
  const dispatch = useDispatch()
  const { error, loading } = useSelector((state) => state.authExpert)
  // const [, forceUpdate] = useState({});

  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem("expertData");
      localStorage.removeItem("isExpertAuth");
      history("/expert-login");
    };

    const lastExpertLoginTime = localStorage.getItem("lastExpertLoginTime");

    if (lastExpertLoginTime) {
      const elapsed = Date.now() - parseInt(lastExpertLoginTime, 10);
      if (elapsed >= 8 * 60 * 60 * 1000) {
        // 8 hours in milliseconds
        logoutUser();
      }
    }

    return () => {
      // Clear any cleanup you might have here
    };
  }, [history]);

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  // To disable submit button at the beginning.
  // useEffect(() => {
  //   forceUpdate({});
  // }, []);

  const handleInputs = (e) => {
    const key = e.target.name;
    let value = e.target.value;

    if (key === "email") {
      value = value?.toLowerCase();
    }

    setValue({ ...userData, [key]: value });
  };

  //ADDED TO THE LOGIN SUCCESSFUL CONDITION
  // useEffect(() => {
  //   if (isAuth) {
  //     let redirectPath = localStorage.getItem("redirectPath");
  //     if (redirectPath) {
  //       localStorage.removeItem("redirectPath");
  //       history(redirectPath);
  //     } else {
  //       history("/expert/dashboard");
  //       setTimeout(() => {
  //         notification.success({
  //           message: "Login Successful",
  //           icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
  //           style: {
  //             backgroundColor: "#2ecc71",
  //             color: "#fff !important",
  //             border: "1px solid #52c41a",
  //           },
  //           duration: 5,
  //           placement: "topRight",
  //         });
  //       }, 100);
  //     }
  //   }
  // }, [isAuth]);

  const loginUser = async (e) => {
    const { email, password } = userData
    localStorage.setItem("ChangePassword", JSON.stringify(userData.password));
    dispatch(email_login({ email, password }));
    // try {
    //   const res = await fetch(`${API_PATH.EXPERT_LOGIN}`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(userData),
    //   });

    //   const result = await res.json();
    //   if (result) {
    //     setSpinner(false);
    //     if (result?.status === 200 || result?.status === true) {
    //       localStorage.setItem("expertData", JSON.stringify(result));

    //       if (result?.token) {
    //         setIsAuth(true);
    //         // localStorage.setItem("isExpertAuth", true);
    //         // localStorage.setItem(
    //         //   "ChangePassword",
    //         //   JSON.stringify(userData.password)
    //         // );

    //         // localStorage.setItem("lastExpertActiveTime", new Date().toString()); // Set the last active time when the user logs in

    //         // localStorage.setItem(
    //         //   "lastExpertLoginTime",
    //         //   new Date().getTime().toString()
    //         // ); // Set the last login time

    //         // history("/expert/dashboard");
    //         // notification.success({
    //         //   message: "Login Successful",
    //         //   icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
    //         //   style: {
    //         //     backgroundColor: "#2ecc71",
    //         //     color: "#fff !important",
    //         //     border: "1px solid #52c41a",
    //         //   },
    //         //   duration: 5,
    //         //   placement: "topRight",
    //         // });
    //       }
    //     } else {
    //       setSpinner(false);
    //       setError("Invalid email or password");
    //     }
    //   } else {
    //     console.error("result:", result);
    //     notification.error({
    //       message: "Error: Something went wrong server error",
    //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
    //       style: {
    //         backgroundColor: "#e74c3c",
    //         color: "#fff",
    //         border: "1px solid #c0392b",
    //       },
    //       duration: 5,
    //       placement: "topRight",
    //     });
    //   }
    // } catch (error) {
    //   console.error("Expert Login", error);
    //   setError("An error occurred while logging in");
    // }
  };

  const ForgotClick = () => {
    history("/expert-forgot-password");
  };

  // useEffect(() => {
  //   if (isAuth) {
  //     history("/expert/dashboard");
  //     setIsAuth(false);
  //   }
  // }, [isAuth]);

  const something = (event) => {
    if (event.keyCode === 13) {
      loginUser(event);
    }
  };

  const sendOTP = async () => {
    try {
      setLoadingEmail(true)
      const response = await fetch(`${API_URL.SENDOTPEXPERT}/${userData?.email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      });
      let login_response = await response.json()
      if (login_response?.status === 200 || login_response?.status === "200") {
        setLoadingEmail(false)
        form.setFieldValue("password", "")
        setValue({
          ...userData,
          password: "",
          loginType: "otp"
        })

        setTimeout(() => {
          notification.success({
            message: "OTP Sent Successfully!",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      } else {
        setLoadingEmail(false)
        toast.error(login_response.message
          , {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
      }
    } catch (error) {
      setLoadingEmail(false)
      notification.error({
        message: error.message,
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }

  return (
    <>
      <Form form={form} layout="vertical" autoComplete="off" onFinish={() => {
        if (userData?.loginType === "otp") {
          let { email, password } = userData
          dispatch(otp_login({ email, password }))
        } else {
          loginUser()
        }
      }}>
        {userData?.loginType === "" && <Form.Item name="email" label="Email Address" rules={emailRules}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="text"
            name="email"
            value={userData?.email}
            onChange={handleInputs}
            autoComplete="nope"
            placeholder="Enter Your Email Address"
            size="large"
          />
        </Form.Item>}

        {userData?.loginType !== "" &&
          <div className="mb-4">
            <Tooltip title="Back">
              <MdOutlineKeyboardBackspace
                style={{
                  cursor: "pointer",
                  fontSize: 22,
                  marginRight: 4
                }}
                onClick={() => {
                  form.setFieldValue("password", "")
                  setValue({
                    ...userData,
                    password: "",
                    registrationType: "client",
                    loginType: "" //password or otp
                  })
                }} /></Tooltip><span className="fw-bold fs-5">Email -</span> <span className="fs-5"> {userData?.email}</span></div>
        }
        {userData?.loginType === "password" && <div>
          <Form.Item
            name="password"
            className="mb-0"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              name="password"
              onChange={handleInputs}
              value={userData?.password}
              id="password-field"
              size="large"
              autoComplete="nope"
              onKeyDown={(e) => something(e)}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item className="d-flex justify-content-end">
            <Button
              type="link"
              onClick={ForgotClick}
              style={{
                textDecoration: "none",
                color: "#523EE8",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              Forgot Password?
            </Button>
          </Form.Item>
        </div>}

        {userData?.loginType === "otp" && <>
          <Form.Item
            name="password"
            label="OTP"
            rules={[
              {
                required: true,
                message: "Please input your OTP!",
              },
            ]}
          >
            {/* <div
              id="otp"
              className="d-flex justify-content-center align-items-center flex-row"
            > */}
            <OtpInput
              value={userData?.password}
              onChange={(otp) => {
                setValue({
                  ...userData,
                  password: otp
                })
              }}
              numInputs={6}
              inputStyle={{
                width: "100%",
                height: "2.5rem",
                margin: "0 0.5rem",
                fontSize: "20px",
                borderRadius: 4,
                border: "1px solid #5746ec ",
              }}
            />
            {/* </div> */}
          </Form.Item>

        </>}

        {userData?.loginType === "" && <div className="d-flex align-items-center justify-content-center w-100 gap-2">
          <Form.Item shouldUpdate className="flex-1 w-100">
            <Button htmlType="button"
              className="login-btn"
              disabled={userData?.email === "" || (userData?.email && form.getFieldError("email")?.length !== 0) || loadingEmail}
              onClick={() => {
                let errorLen = form.getFieldError("email")?.length
                if (errorLen === 0) {
                  form.setFieldValue("password", "")
                  setValue({
                    ...userData,
                    password: "",
                    loginType: "password"
                  })
                }

              }}>
              Login With Password
            </Button>
          </Form.Item>
          <Form.Item shouldUpdate className="flex-1 w-100">
            <Button htmlType="button"
              className="login-btn m-0"
              disabled={userData?.email === "" || (userData?.email && form.getFieldError("email")?.length !== 0) || loading || loadingEmail}
              onClick={() => {
                let errorLen = form.getFieldError("email")?.length
                if (errorLen === 0) {
                  sendOTP()
                }
              }}>
              Login With OTP {loadingEmail && <LoadingOutlined className="p-0" size={"small"} />}
            </Button>
          </Form.Item>
        </div>}

        {userData?.loginType !== "" &&
          <div className="form-group">
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  // onClick={loginUser}
                  className="btn login-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  {loading ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      Login
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              )}
            </Form.Item>
          </div>}
        {userData?.loginType === "otp" && <div className="form-group m-0">
          <p className="text-center m-0">
            <NavLink className="register-btn px-2" onClick={() => {
              if (!loadingEmail) {
                sendOTP()
              }
            }}>
              Resend OTP
            </NavLink>
          </p>
        </div>}
      </Form>

      <div className="form-group">
        <p className="text-center">
          <NavLink to="/expert-register" className="register-btn px-2">
            Register
          </NavLink>
        </p>
      </div>
    </>
  );
}
export default LoginForm;
