import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, Pagination } from "antd";
import React from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import { setIsOpen, setSearchTextQC, setSelectedEmails, setSelectedRowKeys } from "../../features/QCExpert.slice";
import { useDispatch, useSelector } from "react-redux";
import useQCExpert from "./hook/useQCExpert";

const ErrorQcProfiles = () => {
    const dispatch = useDispatch()
    const { errorProfileList, errorProfilePagination, searchTextQC, selectedRowKeys } = useSelector((state) => state.qcMeeting)
    const { GetLinkedInErrorQcList, updateTempExpert, } = useQCExpert()

    const errorProfilecolumns = [
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        // {
        //   title: "Dail Code",
        //   dataIndex: "contact_code",
        //   key: "contact_code",
        // },
        {
            title: "Contact Number",
            dataIndex: "contact_number",
            key: "contact_number",
        },

        {
            title: "Linkedin Url",
            dataIndex: "linkendin_url",
            key: "linkendin_url"
        },
        {
            title: "Action",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => (
                <button
                    type="button"

                    // disabled={!selectedEmails.some((email) => email.key === _id)}
                    style={{
                        cursor: "pointer",
                        fontWeight: 600,
                        color: "rgb(87, 70, 236)",
                        border: "none",
                        background: "transparent"
                    }}
                    onClick={() => {
                        updateTempExpert({ email: _id?.email })
                    }}
                >
                    {" "}
                    Process
                </button>
            ),
        },
    ];

    const getFormatedContact = (record) => {
        const countryCode = record?.contact_code;
        const formattedCountryCode =
            countryCode && !countryCode.includes("+")
                ? `+${countryCode === null ? "" : countryCode}`
                : countryCode === null
                    ? ""
                    : countryCode;

        // Combine the country code and contact number
        const combinedNumber = `${formattedCountryCode} ${record?.contact_number}`;
        return combinedNumber
    }

    const dataSourceErrorProfiles = errorProfileList?.data?.map((data, index) => ({
        key: index,
        email: data?.email,
        contact_number: getFormatedContact(data),
        linkendin_url: data.linkendin_url
            ? data.linkendin_url
            : data.linkedin_url,
        _id: data,


    }))

    const paginationSettingsQcError = {
        current: errorProfilePagination.page,
        onChange: (index) => GetLinkedInErrorQcList({ page: index, is_paginate: true }),
        pageSize: errorProfilePagination.limit,
        total: errorProfileList?.count,
    };


    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch(setSelectedEmails(selectedRows))
            dispatch(setSelectedRowKeys(selectedRowKeys))
        },
        getCheckboxProps: (record) => ({
            //   disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            // name: record.name,
        }),
    };
    return (
        <div className="bk-div float-start w-100">
            {" "}
            {/* <hr className="sp-hr" /> */}
            {/* <div className="body-expart-div"> */}
            <div className="d-flex justify-content-between align-items-center">
                <h2>Error Profiles</h2>
                <div className="gap-2">
                    <button
                        className="btn viewButton mx-2"
                        onClick={() => dispatch(setIsOpen(true))}
                        size="large"
                    >
                        Update Phantom key
                    </button>
                    <button
                        className="btn viewButton"
                        onClick={() => updateTempExpert({ email: "" })}
                        size="large"
                    >
                        Process
                    </button>
                </div>

            </div>

            <Input
                placeholder="Search"
                value={searchTextQC}
                onChange={(e) => {
                    e.preventDefault()
                    dispatch(setSearchTextQC(e.target.value))
                    GetLinkedInErrorQcList({ key: e.target.value, page: 1 })
                }}
                prefix={<SearchOutlined />}
                style={{ marginBottom: 16 }}
                size="large"
            />
            <Table
                dataSource={dataSourceErrorProfiles}
                columns={errorProfilecolumns}
                pagination={false}
                rowSelection={rowSelection}
                // onChange={handleTableChange}
                scroll={{ x: 600 }}
                size="large"
            // loading={loading}
            />
            <Pagination
                {...paginationSettingsQcError}
                showSizeChanger={false}
                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
                showTotal={(callPage, range) =>
                    `${range[0]}-${range[1]} of ${callPage} items`
                }
            />
        </div>)
}

export default ErrorQcProfiles;
