import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Tooltip } from "antd";

function ScopeProjectInfomation({ datas, projectDetails }) {
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [datas]);

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  const splitArray = (array) => {
    let newarray = array.split(",");
    return newarray;
  };

  if (!Loading) {
    return (
      <>

        {datas?.project_start && <div className="d-flex align-items-center justify-content-between">
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: "14px",
            }}
          >
            Start Date:
          </span>
          <span
            className="col-indigo"
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {convertDate(datas?.project_start)}
          </span>
        </div>}

        <div className="d-flex align-items-center justify-content-between">
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: "14px",
            }}
            md={5}
          >
            Target Companies:
          </span>
          <span
            className="col-indigo"
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {datas?.target_companies?.length > 0 ? (
              <div className="">
                {<Tooltip title={datas?.target_companies}>{
                  _.truncate(splitArray(datas?.target_companies)
                    .map((curElem, id) => {
                      return curElem;
                    })
                    .join(", "), {
                    length: 20,
                    omission: "...",
                  })} </Tooltip>}

              </div>
            ) : (
              <span style={{ marginLeft: "10px" }}>No companies found</span>
            )}
          </span>
        </div>
        {datas?.expert_industry_name?.[0]?.category_name ? (
          <div className="d-flex align-items-center justify-content-between">
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
              md={5}
            >
              Industries:
            </span>
            <span
              md={7}
              className="col-indigo"
              style={{ fontWeight: 500, fontSize: "14px" }}
            >
              {datas?.expert_industry_name?.[0]?.category_name?.replace(/,(?=[^\s])/g, ", ")}
            </span>
          </div>
        ) : (
          ""
        )}
        {datas?.project_recieved_date && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Project Received Date:
              </span>
              <span
                className="col-indigo"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                {convertDate(
                  datas?.project_recieved_date
                )}
              </span>
            </div>
          </>
        )}
        {datas?.created_at && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Created At:
              </span>
              <span
                className="col-indigo"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                {convertDate(datas?.created_at)}
              </span>
            </div>
          </>
        )}

        {datas?.project_deadline && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Deadline:
              </span>
              <span
                className="col-indigo"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                {convertDate(datas?.project_deadline)}
              </span>
            </div>
          </>
        )}

        {datas?.client?.name && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Added By:
              </span>
              <span
                className="col-indigo"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                {datas?.client?.name}
              </span>
            </div>
          </>
        )}
        {datas?.linkedin_account && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Linkedin Account:
              </span>
              <span
                className="col-indigo"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                {datas?.linkedin_account}
              </span>
            </div>
          </>
        )}

        {datas?.remarks && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Additional Notes:
              </span>
              <span
                className="col-indigo"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                <Tooltip title={datas?.remarks}>
                  {_.truncate(datas?.remarks || "", {
                    length: 20,
                    omission: "...",
                  })}
                </Tooltip>
              </span>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
          <Spin size="large" />
        </div>
      </>
    );
  }

}

export default ScopeProjectInfomation;
