import axios from 'axios';
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useSWR from 'swr';
import API_PATH from "../../../../Constants/api-path";
import { clearAll, setCompanyList, setCompanyPagination, setCreditUsageData, setCreditValueInDollor, setPaymentHistoryList, setPaymentHistoryPagination, setSettingDrawer, setTransactionHistoryList, setTransactionHistoryPagination } from '../../../../features/creditManagement.slice';
import { useSelector } from 'react-redux';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import dayjs from 'dayjs';
dayjs.extend(customParseFormat);

function useCreditManagement() {
    const dispatch = useDispatch()
    const userData = JSON.parse(localStorage.getItem("userData"));
    const { paymentHistoryPagination, transactionHistoryPagination, selectedCompany, companyPagination, searchValue, formvalues, credit_usage_data } = useSelector((state) => state.creditManagement)
    const companyList = useSWR('company_list', () => getCompanies({ search: "" }), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            let obj = { data: data?.data, count: data?.count }
            dispatch(setCompanyList(obj));
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        }
    })
    const creditsValue = useSWR('credit_value', () => getCreditValueInDollor(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            dispatch(setCreditValueInDollor(data?.data[0]?.one_credit_value_in_dollor));
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        }
    })


    const getCompanies = useCallback(async ({ search = '', page_no = 1, page_limit = 10 }) => {
        const response = await axios
            .get(`${API_PATH.GET_COMPANIES_LIST}?page=${page_no}&limit=${page_limit}${search !== '' ? `&key=${search}` : ''}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },

            })
        return response.data
    }, [userData?.token]);

    const fetchCompanies = useCallback(async ({ is_refetching = false, search = searchValue, page_no = companyPagination.page, page_limit = companyPagination.limit }) => {
        if (is_refetching) {
            dispatch(setCompanyPagination({ page: 1, limit: 10 }))
            const response = await axios
                .get(`${API_PATH.GET_COMPANIES_LIST}?page=${1}&limit=${10}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }).then((res) => {
                    let obj = { data: res?.data?.data, count: res?.data?.count }
                    dispatch(setCompanyList(obj));
                }).catch((err) => {
                    console.error(err);
                })
        } else {
            dispatch(setCompanyPagination({ page: page_no, limit: page_limit }))
            const response = await axios
                .get(`${API_PATH.GET_COMPANIES_LIST}?page=${page_no}&limit=${page_limit}${search !== '' ? `&key=${search}` : ''}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }).then((res) => {
                    let obj = { data: res?.data?.data, count: res?.data?.count }
                    dispatch(setCompanyList(obj));
                }).catch((err) => {
                    console.error(err);
                })
        }


    }, [userData?.token, dispatch, companyPagination.limit, companyPagination.page, searchValue]);

    const getPaymentHistoryById = useCallback(async ({ is_paginating = false, page_no = paymentHistoryPagination?.page, page_limit = paymentHistoryPagination?.limit, company_id = selectedCompany?.company_details?._id }) => {
        if (is_paginating) {
            let obj = {
                page: page_no, limit: page_limit
            }
            dispatch(setPaymentHistoryPagination(obj))
        }

        const response = await axios
            .get(`${API_PATH.GET_PAYMENT_HISTORY_BY_ID}?page=${page_no}&limit=${page_limit}&company_id=${company_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }).then((res) => {
                let obj = {
                    data: res?.data?.data, count: res?.data?.count
                }
                dispatch(setPaymentHistoryList(obj))
            }).catch((err) => {
                console.error(err);
            })
    }, [userData?.token, dispatch, paymentHistoryPagination?.limit, paymentHistoryPagination?.page, selectedCompany?.company_details?._id])

    const getTransactionHistoryById = useCallback(async ({ is_paginating = false, page_no = transactionHistoryPagination?.page, page_limit = transactionHistoryPagination?.limit, company_id = selectedCompany?.company_details?._id }) => {
        if (is_paginating) {
            let obj = {
                page: page_no, limit: page_limit
            }
            dispatch(setTransactionHistoryPagination(obj))
        }

        const response = await axios
            .get(`${API_PATH.GET_TRANSACTION_HISTORY_BY_ID}?page=${page_no}&limit=${page_limit}&company_id=${company_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }).then((res) => {
                let obj = {
                    data: res?.data?.data, count: res?.data?.count
                }
                dispatch(setTransactionHistoryList(obj))
            }).catch((err) => {
                console.error(err);
            })
    }, [userData?.token, dispatch, transactionHistoryPagination?.limit, transactionHistoryPagination?.page, selectedCompany?.company_details?._id])

    const getCreditSettingById = useCallback(async ({ company_id = selectedCompany?.company_details?._id }) => {
        const response = await axios
            .get(`${API_PATH.GET_CREDIT_USAGE_BY_COMPANY_ID}?company_id=${company_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }).then((res) => {
                dispatch(setCreditUsageData(res?.data?.data[0]))
            }).catch((err) => {
                console.error(err);
            })
    }, [userData?.token, dispatch, selectedCompany?.company_details?._id])
    const updateCreditSettingById = useCallback(async () => {
        let body = {
            ...credit_usage_data,
            type: "private",
            company_id: selectedCompany?.company_details?._id,
            last_updated_by: userData?.data?._id
        }
        delete body.updated_at
        delete body._id
        const response = await axios
            .put(`${API_PATH.UPDATE_CREDIT_USAGE_BY_COMPANY_ID}`, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            }).then((res) => {
                dispatch(setSettingDrawer(false))
                setTimeout(() => {
                    notification.success({
                        message: "Credits Usage Values Updated Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }).catch((err) => {
                console.error('errERRRORRR: ', err);
                setTimeout(() => {
                    notification.error({
                        message: "Error While Updating Credits Usage Values",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
                console.error(err);
            })
    }, [credit_usage_data, selectedCompany?.company_details?._id, userData?.data?._id, userData?.token, dispatch])

    const addCredits = useCallback(async () => {
        let body = {
            "user_id": userData?.data?._id,
            "company_id": selectedCompany?.company_details?._id,
            "amount": Number(formvalues?.amount),
            "credits": Number(formvalues?.credits),
            "payment_date": formvalues?.payment_date !== null ? dayjs(formvalues?.payment_date).format() : "",
            "payment_refrence_id": formvalues?.payment_refrence_id,
            "credit_validity": formvalues?.credit_validity ? dayjs(formvalues?.credit_validity).format() : ""
        }
        const addCredits = await axios.post(API_PATH.ADD_CREDITS, body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.token}`,
            },
        }).then((res) => {
            fetchCompanies({ is_refetching: true })
            setTimeout(() => {
                notification.success({
                    message: "Credits Added Successfully",
                    icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                    style: {
                        backgroundColor: "#2ecc71",
                        color: "#fff !important",
                        border: "1px solid #52c41a",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }, 100);
            dispatch(clearAll())
        }).catch((err) => {
            setTimeout(() => {
                notification.error({
                    message: "Error While Adding Credits",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }, 100);
            dispatch(clearAll())
        })
    }, [formvalues, userData?.token, dispatch, fetchCompanies, selectedCompany?.company_details?._id, userData?.data?._id])

    const getCreditValueInDollor = useCallback(async () => {
        const response = await axios
            .get(`${API_PATH.GET_CREDIT_VALUE_IN_DOLLOR}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
        return response.data
    },
        [userData?.token])


    const values = useMemo(() => ({
        fetchCompanies: fetchCompanies,
        getPaymentHistoryById: getPaymentHistoryById,
        addCredits: addCredits,
        getCreditSettingById: getCreditSettingById,
        updateCreditSettingById: updateCreditSettingById,
        getTransactionHistoryById: getTransactionHistoryById,
    }), [getPaymentHistoryById, fetchCompanies, addCredits, getCreditSettingById, updateCreditSettingById, getTransactionHistoryById])
    return values
}

export default useCreditManagement