import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Space,
  Spin,
  Tooltip
} from "antd";
import axios from "axios";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Country } from "country-state-city";
import dayjs from "dayjs";
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import { Reorder } from "framer-motion";
import moment from "moment";
import "moment/locale/en-gb"; // Import locale if needed, adjust locale as per your requirements
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
import { resetFields, setAddData, setCustomIndustry, setExperts, setFilteredIndust, setFilteredIndustOptions, setInputss, setInputsTwo, setLinkedinScrapData, setLoading, setOpen, setOverview, setResExpert, setSpinner } from "../../features/addNewExpertForm.slice";
import { CurrentCompanyItem } from "./CurrentCompanyItem";
import './dragCss.css';
import { IoLogoLinkedin } from "react-icons/io5";
import { PreviousCompanyItem } from "./PreviousCompanyItem";
dayjs.extend(customParseFormat);
const { Option } = Select;

const AddNewExpertSection = () => {

  const history = useNavigate();
  const dispatch = useDispatch()
  const { inputs, inputsTwo, resExpert, filteredIndustOptions, industOption, open, customIndustry, addData, overview, linkedinScrapData } = useSelector((state) => state.addNewExpertFormReducer)

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [form] = Form.useForm();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");
  let profile_count = query.get("count") || 0;
  let redirect = query.get("redirect");
  let expertID = query.get("expertID");

  const GetIndustryList = useCallback(async (industOption) => {
    dispatch(setLoading(true));

    let object = {};

    if (industOption) {
      object.key = `${industOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_INDUSTRY
        }?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        dispatch(setFilteredIndustOptions(result?.data));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Industry Error", error);
    }
    dispatch(setLoading(false));
  }, [dispatch]);

  React.useEffect(() => {
    //!DEPENDENCY SHOULD BE ON industOption FOR A REASON
    const getData = setTimeout(() => {
      GetIndustryList(industOption);
    }, 500);

    return () => clearTimeout(getData);
  }, [industOption]);

  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetIndustryList("");
    }
  }, [GetIndustryList]);

  const handleAddInput = useCallback(() => {
    const newInputs = [
      ...inputs,
      { id: String(inputs?.length + 1 || 1), title: "", company: "", startDate: "", endDate: "" },
    ];
    dispatch(setInputss(newInputs));
  }, [dispatch, inputs]);

  const handleAddInputTwo = useCallback(() => {
    const newInputs = [
      ...inputsTwo,
      { id: String(inputsTwo?.length + 1 || 1), titlePrev: "", companyPrev: "", startDatePrev: "", endDatePrev: "" },
    ];
    dispatch(setInputsTwo(newInputs));
  }, [dispatch, inputsTwo]);


  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    let newAddData = {
      ...addData,
      [name]: value
    }
    dispatch(setAddData(newAddData));
  }, [addData, dispatch]);



  const clientCurrency = useCallback(
    (value) => {
      const selectValue = value || "USD";
      dispatch(setAddData({ ...addData, expert_client_Currency: selectValue }));
    },
    [addData, dispatch]
  );

  const clientRate = useCallback(
    (value) => {
      const selectValue = value;
      dispatch(setAddData({ ...addData, hourly_consulting_rate: selectValue }));
    },
    [addData, dispatch]
  );

  const getFormattedDateInOneFormat = useCallback((date) => {
    let trimDate = date?.trim();
    if (trimDate && ![null, "null", "Invalid date", ""]?.includes(trimDate)) {
      const possibleFormats = ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"];
      let detectedFormat = null;
      for (let format of possibleFormats) {
        const parsedDate = moment(trimDate?.trim(), format, true);
        if (parsedDate.isValid()) {
          detectedFormat = format;
          break;
        }
      }
      return dayjs(trimDate, detectedFormat)?.format("MM, YYYY");
    } else {
      return "";
    }
  }, []);

  const onChangeEmail = useCallback(
    async (value, option) => {
      const selectValue = value;
      const setSelectedID = option?.key;
      dispatch(setAddData({
        ...addData,
        email: selectValue,
        expert_id: setSelectedID,
        expert_name: option?.title,
      }));
      form.resetFields()
      dispatch(resetFields())
      try {
        const res = await axios.get(
          `${API_PATH.EXPERT_PROFILE}/${setSelectedID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          }
        }
        );
        dispatch(setAddData({
          ...addData,
          expert_id: res?.data?.data[0]?._id || "",
          expert_name:
            res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
          email: res?.data?.data[0]?.expertprofiles?.[0]?.email || res?.data?.data[0]?.email || "",
          primary_expert_rate: res?.data?.data[0]?.primary_expert_rate || "",
          link: res?.data?.data[0]?.linkedin_url || "",
          submit: res?.data?.data[0]?.submit || "",
          consulting_rate: res?.data?.data[0]?.consulting_rate || res?.data?.data[0]?.hourly_rate_call_cunsltn || "",
          expertCurrency: res?.data?.data[0]?.hourly_currency || "USD",
          // ------------DOUBT--------------
          hourly_consulting_rate: res?.data?.data[0]?.hourly_consulting_rate,
          expert_client_Currency: res?.data?.data[0]?.expert_client_Currency || "USD",
          // ------------DOUBT--------------
          current_location: res?.data?.data[0]?.country || "",
          industry_experience: res?.data?.data[0]?.years_of_experience,
          inustry_or_area_of_expertise: res?.data?.data[0]?.industries_expertise ||
            res?.data?.data[0]?.industry,
          profile_number: res?.data?.data?.[0]?.profile_number || String(Number(profile_count) + 1),
        }));

        dispatch(setOverview(res?.data?.data[0]?.overview || ""))
        const currentData = res?.data?.data[0]?.expertexperiences;
        const temDatas = currentData?.map((value, index) => {
          if (value?.till_present === "yes") {
            return {
              id: String(index + 1),
              title: value?.career_title,
              company: value?.company_name,
              startDate: getFormattedDateInOneFormat(value?.date_from),
              endDate: getFormattedDateInOneFormat(value?.date_till),
              till_present: value?.till_present,
              current_show_check: value?.show_check ? value?.show_check : "no",
            };
          }
          return null;
        });

        const filteredData = temDatas.filter((val) => val !== null);
        dispatch(setInputss(filteredData));
        const currentPrevData = res?.data?.data[0]?.expertexperiences;
        const temPrevData = currentPrevData.map((value, index) => {
          if (value?.till_present === "no") {
            return {
              id: String(index + 1),
              titlePrev: value?.career_title || "",
              companyPrev: value?.company_name || "",
              startDatePrev: getFormattedDateInOneFormat(value?.date_from), //dayjs(value?.date_from, "MMMM YYYY").format('MM-YYYY'),
              endDatePrev: getFormattedDateInOneFormat(value?.date_till), //dayjs(value?.endDate, "MMMM YYYY").format('MM-YYYY'),
              previous_show_check: value?.show_check || "no",
            };
          } else {
            return null;
          }
        });
        const filteredPrevData = temPrevData.filter((val) => val !== null);
        dispatch(setInputsTwo(filteredPrevData));


        let currentObj = filteredData?.map((data) => {
          return {
            current_end_date: data?.endDate !== "" ? dayjs(data?.endDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
            current_start_date: data?.startDate !== "" ? dayjs(data?.startDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
          }
        })
        let prevObj = filteredPrevData?.map((data) => {
          return {
            prev_end_date: data?.endDatePrev !== "" ? dayjs(data?.endDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
            prev_start_date: data?.startDatePrev !== "" ? dayjs(data?.startDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
          }
        })

        const maxLength = Math.max(currentObj?.length, prevObj?.length);
        const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
          ...currentObj[index],
          ...prevObj[index],
        }));

        form.setFieldsValue({
          ...mergedArray,
          email:
            res?.data?.data[0]?.expertprofiles?.[0]?.email ||
            res?.data?.data[0]?.email,
          link: res?.data?.data[0]?.link || res?.data?.data[0]?.linkedin_url || "",
          expert_name:
            res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
          consulting_rate: res?.data?.data[0]?.consulting_rate || res?.data?.data[0]?.hourly_rate_call_cunsltn || "",
        });

      } catch (error) {
        console.error(error);
      }
    },
    [addData, dispatch, form, getFormattedDateInOneFormat, profile_count, userData?.token]
  );


  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const AddExpert = useCallback(
    async (status) => {
      // e.preventDefault();
      dispatch(setLoading(true));

      const transformedData = inputs?.map((input) => ({
        current_designation: input?.title,
        current_compny: input?.company,
        current_designation_date: `${input?.startDate} - ${input?.endDate}`,
        current_show_check: input?.current_show_check,
        till_present: input?.till_present === "yes" ? "yes" : "no",
      }));

      // const isTillPresentChecked = transformedData.some(
      //   (data) => data?.till_present === "yes"
      // );

      const postData = {
        current_compny: [],
        current_designation: [],
        current_designation_date: [],
        current_show_check: [],
        // till_present: isTillPresentChecked ? "yes" : "no",
        till_present: [],
      };

      for (let i = 0; i < transformedData?.length; i++) {
        const data = transformedData[i];
        postData.current_compny?.push(data?.current_compny);
        postData.current_designation?.push(data?.current_designation);

        // Split the combined date string into individual start and end date values
        const [startDate, endDate] =
          data?.current_designation_date?.split(" - ");

        // Combine start and end dates into a single string with the desired separator
        postData?.current_designation_date?.push(
          `${startDate?.trim()} - ${endDate?.trim()}`
        );

        postData?.current_show_check?.push(data?.current_show_check);
        postData?.till_present?.push(data?.till_present);
        // Check if any of the inputs have till_present === "yes"
        // if (transformedData.some((data) => data?.till_present === "yes")) {
        //   postData.till_present = "yes"; // Update the till_present value to "yes"
        // }
      }
      const current_compny = postData?.current_compny;
      const current_designation = postData?.current_designation;
      const current_designation_date = postData?.current_designation_date;
      const current_show_check = postData?.current_show_check;
      const till_present = postData?.till_present;

      const transformedDataTwo = inputsTwo?.map((input) => ({
        previous_designation: input?.titlePrev,
        previous_compny: input?.companyPrev,
        previous_designation_date: `${input?.startDatePrev} - ${input?.endDatePrev}`, // Combine start and end dates into a single string
        previous_show_check: input?.previous_show_check,
      }));

      const postDataTwo = {
        previous_compny: [],
        previous_designation: [],
        previous_designation_date: [],
        previous_show_check: [],
      };

      for (let i = 0; i < transformedDataTwo?.length; i++) {
        const data = transformedDataTwo[i];
        postDataTwo?.previous_compny?.push(data?.previous_compny);
        postDataTwo?.previous_designation?.push(data?.previous_designation);

        // Split the combined date string into individual start and end date values
        const [startDatePrev, endDatePrev] =
          data?.previous_designation_date?.split(" - ");

        // Combine start and end dates into a single string with the desired separator
        postDataTwo?.previous_designation_date?.push(
          `${startDatePrev?.trim()} - ${endDatePrev?.trim()}`
        );

        postDataTwo?.previous_show_check?.push(data?.previous_show_check);
        // if (data?.till_present === "yes") {
        //   postDataTwo.till_present = "yes"; // Update the till_present value if any input has "yes"
        // }
      }

      const previous_compny = postDataTwo?.previous_compny;
      const previous_designation = postDataTwo?.previous_designation;
      const previous_designation_date = postDataTwo?.previous_designation_date;
      const previous_show_check = postDataTwo?.previous_show_check;
      try {
        if (expertID) {
          const res = await fetch(`${API_PATH?.PROJECT_EXPERT_UPDATE}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              expert_id: addData?.expert_id,
              email: addData?.email, //added by onkar
              expert_name: addData.expert_name,
              // expert_primary_rate_currency: addData.expert_primary_rate_currency,
              // primary_expert_rate: addData.primary_expert_rate,
              industry_experience: addData.industry_experience,
              inustry_or_area_of_expertise:
                addData.inustry_or_area_of_expertise,
              project_id: project_id,
              submit: status,
              link: addData?.link,
              consulting_rate: addData?.consulting_rate,
              expertCurrency: addData?.expertCurrency || "USD",
              profile_number: status === "Save as Draft" ? "" : addData?.profile_number,
              hourly_consulting_rate: addData?.hourly_consulting_rate,
              expert_client_Currency: addData?.expert_client_Currency || "USD",
              user_id: userData?.data?._id, //prev project_id,
              overview: overview,
              current_location: addData?.current_location,
              current_compny: current_compny,
              current_designation: current_designation,
              till_present: till_present, // Join the array elements with '/'
              current_designation_date: current_designation_date,
              current_show_check: current_show_check,
              previous_compny: previous_compny,
              previous_designation: previous_designation,
              previous_designation_date: previous_designation_date,
              previous_show_check: previous_show_check,
              experts_detail_id: expertID,
            }),
          });
          const result = await res.json();
          if (result) {
            dispatch(setSpinner(false));
            notification.success({
              message: "Expert has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
            if (redirect === "team") {
              form.resetFields()
              dispatch(resetFields());
              setTimeout(() => {
                history(
                  `/dashboard/team/project-overview?project_id=${project_id}`
                );
              }, 800);
            } else if (redirect !== "team" && status === "Save as Draft") {
              dispatch(resetFields());
              form.resetFields()
              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=`
                );
              }, 800);
            } else if (redirect !== "team" && status === "Submit") {
              dispatch(resetFields());
              form.resetFields()
              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=submitted`
                );
              }, 800);
            }
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        } else {
          const res = await fetch(`${API_PATH?.Add_Expert}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              expert_id: addData?.expert_id,
              email: addData?.email,
              expert_name: addData.expert_name,
              // expert_primary_rate_currency: addData.expert_primary_rate_currency,
              // primary_expert_rate: addData.primary_expert_rate,
              industry_experience: addData.industry_experience,
              inustry_or_area_of_expertise:
                addData.inustry_or_area_of_expertise,
              added_by_team: true,
              project_id: project_id,
              submit: status,
              link: addData?.link,
              consulting_rate: addData?.consulting_rate,
              expertCurrency: addData?.expertCurrency || "USD",
              hourly_consulting_rate: addData?.hourly_consulting_rate,
              expert_client_Currency: addData?.expert_client_Currency || "USD",
              profile_number: status === "Save as Draft" ? "" : addData?.profile_number,
              user_id: userData?.data?._id, //prev project_id
              overview: overview,
              current_location: addData?.current_location,
              current_compny: current_compny,
              current_designation: current_designation,
              till_present: till_present, // Join the array elements with '/'
              current_designation_date: current_designation_date,
              current_show_check: current_show_check,
              previous_compny: previous_compny,
              previous_designation: previous_designation,
              previous_designation_date: previous_designation_date,
              previous_show_check: previous_show_check,
              linkedin_scrap_data: status === "Save as Draft" ? "" : linkedinScrapData
            }),
          });

          const result = await res.json();
          if (result) {
            if (redirect === "team") {
              dispatch(resetFields());
              form.resetFields()
              setTimeout(() => {
                history(
                  `/dashboard/team/project-overview?project_id=${project_id}`
                );
              }, 800);
              if (status === "Save as Draft") {
                dispatch(resetFields());
                form.resetFields()
                notification.success({
                  message: "Expert saved as draft.",
                  icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                  style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                  },
                  duration: 5,
                  placement: "topRight",
                });
              } else if (status === "Submit") {
                dispatch(resetFields());
                form.resetFields()
                notification.success({
                  message: "Expert submitted successfully.",
                  icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                  style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                  },
                  duration: 5,
                  placement: "topRight",
                });
              }
            } else if (redirect !== "team" && status === "Save as Draft") {
              dispatch(resetFields());
              form.resetFields()
              notification.success({
                message: "Expert saved as draft.",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });

              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=`
                );
              }, 200);
            } else if (redirect !== "team" && status === "Submit") {
              dispatch(resetFields());
              form.resetFields()
              notification.success({
                message: "Expert submitted successfully.",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });

              setTimeout(() => {
                history(
                  `/dashboard/expert_list?${createSearchParams({
                    project_id,
                  })}&tab=submitted`
                );
              }, 200);
            }
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        dispatch(setLoading(false));
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      dispatch(setLoading(false));
    },
    [addData?.consulting_rate, addData?.current_location, addData?.email, addData?.expertCurrency, addData?.expert_client_Currency, addData?.expert_id, addData.expert_name, addData?.hourly_consulting_rate, addData.industry_experience, addData.inustry_or_area_of_expertise, addData?.link, addData?.profile_number, dispatch, expertID, form, history, inputs, inputsTwo, linkedinScrapData, overview, project_id, redirect, userData?.data?._id, userData?.token]
  );
  const fetchData = useCallback(async () => {
    form.resetFields()
    dispatch(resetFields());
    try {
      if (expertID) {
        const res = await axios.get(`${API_PATH.PROJECT_EXPERT_GET}/${expertID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        dispatch(setAddData({
          expert_id: res?.data?.data[0]?.expert_id,
          email:
            res?.data?.data[0]?.expertprofiles?.[0]?.email ||
            res?.data?.data[0]?.email,
          link: res?.data?.data[0]?.link,
          submit: res?.data?.data[0]?.submit,
          expert_name:
            res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
          // primary_expert_rate: res?.data?.data[0]?.expert_rate,
          // expert_primary_rate_currency: res?.data?.data[0]?.hourly_currency,
          industry_experience: res?.data?.data[0]?.years_of_experience,
          inustry_or_area_of_expertise:
            res?.data?.data[0]?.industries_expertise ||
            res?.data?.data[0]?.industry,
          consulting_rate: res?.data?.data[0]?.consulting_rate,
          expertCurrency: res?.data?.data[0]?.expertCurrency || "USD",
          hourly_consulting_rate: res?.data?.data[0]?.hourly_consulting_rate,
          expert_client_Currency: res?.data?.data[0]?.expert_client_Currency || "USD",
          overview: res?.data?.data[0]?.overview || "",
          current_location: res?.data?.data[0]?.current_location,
          profile_number: res?.data?.data?.[0]?.profile_number || String(Number(profile_count) + 1),
        }));
        dispatch(setOverview(res?.data?.data[0]?.overview || ""))
        const currentData = res?.data?.data[0]?.expert_experience;
        const temDatas = currentData?.map((value, index) => {
          if (value?.current_designation) {
            // Extract the start and end dates
            const [startDate, endDate] =
              value?.current_designation_date.split("-");
            return {
              title: value?.current_designation,
              company: value?.current_compny,
              startDate: getFormattedDateInOneFormat(startDate),
              endDate: getFormattedDateInOneFormat(endDate),
              till_present: value?.till_present,
              current_show_check: value?.show_check,
            };
          }
        });
        const filteredData = temDatas.filter(
          (val) => ![undefined, null].includes(val)
        )?.map((newData, index) => {
          return {
            ...newData,
            id: String(index + 1)
          }
        });
        dispatch(setInputss(filteredData));

        const currentPrevData = res?.data?.data[0]?.expert_experience;

        const temPrevData = currentPrevData.map((value, index) => {
          if (value?.previous_designation) {
            // Split the combined date string into individual start and end date values
            const [startDatePrev, endDatePrev] =
              value?.previous_designation_date.split("-");
            return {
              titlePrev: value?.previous_designation || "",
              companyPrev: value?.previous_compny || "",
              startDatePrev: getFormattedDateInOneFormat(startDatePrev),
              endDatePrev: getFormattedDateInOneFormat(endDatePrev),
              previous_show_check: value?.show_check || "no",
            };
          }
          // Return null for entries without a valid previous designation
          return null;
        });

        // Filter out null entries to get the valid data
        const filteredPrevData = temPrevData.filter(
          (val) => ![undefined, null].includes(val)
        )?.map((newData, index) => {
          return {
            ...newData,
            id: String(index + 1 || 1),
          }
        });
        dispatch(setInputsTwo(filteredPrevData));
        let currentObj = filteredData?.map((data) => {
          return {
            current_end_date: data?.endDate !== "" ? dayjs(data?.endDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
            current_start_date: data?.startDate !== "" ? dayjs(data?.startDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
          }
        })
        let prevObj = filteredPrevData?.map((data) => {
          return {
            prev_end_date: data?.endDatePrev !== "" ? dayjs(data?.endDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
            prev_start_date: data?.startDatePrev !== "" ? dayjs(data?.startDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
          }
        })
        const maxLength = Math.max(currentObj?.length, prevObj?.length);
        const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
          ...currentObj[index],
          ...prevObj[index],
        }));
        form.setFieldsValue({
          ...mergedArray,
          email:
            res?.data?.data[0]?.expertprofiles?.[0]?.email ||
            res?.data?.data[0]?.email,
          link: res?.data?.data[0]?.link || "",
          expert_name:
            res?.data?.data[0]?.full_Name || res?.data?.data[0]?.name || "",
          consulting_rate: res?.data?.data[0]?.consulting_rate || "",
        });
        dispatch(setLoading(false));
      } else {
        dispatch(setAddData({
          ...addData,
          profile_number: String(Number(profile_count) + 1)
        }))
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
    }
  }, [addData, dispatch, expertID, form, getFormattedDateInOneFormat, profile_count, userData?.token]);

  const getExperts = useCallback(async () => {
    dispatch(setLoading(true));
    await axios
      .get(`${API_PATH.EXPERT_LIST}?page=1&limit=1000`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        dispatch(setExperts(filterDuplicates([...res?.data?.data])));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
      });
  }, [dispatch, userData?.token]);


  const oncecall = useRef(false);
  useEffect(() => {
    if (!oncecall.current) {
      oncecall.current = true;
      fetchData();
      getExperts();
    }
  }, [fetchData, getExperts]);

  const suffixSelectorExp = useMemo(
    () => (
      <Form.Item noStyle>
        <Select
          style={{
            width: 100,
          }}
          size="large"
          name="expert_primary_rate_currency"
          value={addData?.expertCurrency || "USD"} // Set the value to the state value
          onChange={(value) => {
            dispatch(setAddData({ ...addData, expertCurrency: value, }));
          }}
          defaultValue="USD"
        >
          <Select.Option value="USD">USD</Select.Option>
          <Select.Option value="INR">INR</Select.Option>
          <Select.Option value="AED">AED</Select.Option>
          <Select.Option value="EUR">EUR</Select.Option>
          <Select.Option value="JPY">JPY</Select.Option>
          <Select.Option value="GBP">GBP</Select.Option>
          <Select.Option value="AUD">AUD</Select.Option>
          <Select.Option value="CAD">CAD</Select.Option>
          <Select.Option value="NZD">NZD</Select.Option>
          <Select.Option value="SGD">SGD</Select.Option>
          <Select.Option value="HKD">HKD</Select.Option>
        </Select>
      </Form.Item>
    ), [addData, dispatch]);


  const suffixSelectorTwo = useMemo(
    () => (
      <Form.Item noStyle>
        <Select
          style={{
            width: 100,
          }}
          size="large"
          name="expert_client_Currency"
          value={addData?.expert_client_Currency || "USD"}
          onChange={clientCurrency}
          defaultValue="USD"
        >
          <Select.Option value="USD">USD</Select.Option>
          <Select.Option value="INR">INR</Select.Option>
          <Select.Option value="AED">AED</Select.Option>
          <Select.Option value="EUR">EUR</Select.Option>
          <Select.Option value="JPY">JPY</Select.Option>
          <Select.Option value="GBP">GBP</Select.Option>
          <Select.Option value="AUD">AUD</Select.Option>
          <Select.Option value="CAD">CAD</Select.Option>
          <Select.Option value="NZD">NZD</Select.Option>
          <Select.Option value="SGD">SGD</Select.Option>
          <Select.Option value="HKD">HKD</Select.Option>
          <Select.Option value="Credits">Credits</Select.Option>
        </Select>
      </Form.Item>
    ), [addData?.expert_client_Currency, clientCurrency]);



  const countries = Country?.getAllCountries();
  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Option key={country.isoCode} value={country.name}>
            {country.name}
          </Option>
        );
      }),
    [countries]
  );
  const handleDropdownIndustry = useCallback((open) => {
    dispatch(setOpen(open));
    dispatch(setResExpert(false))
    if (open) {
      dispatch(setCustomIndustry(""))
      GetIndustryList("");
    }
  }, [GetIndustryList, dispatch]);


  const handleReorder = useCallback(
    (newOrder) => {

      let formValues = { ...form.getFieldsValue() }
      newOrder?.forEach((order, index) => {
        formValues[index] = {
          ...formValues[index],
          prev_start_date: order?.startDatePrev !== "" ? dayjs(order?.startDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
          prev_end_date: order?.endDatePrev !== "" ? dayjs(order?.endDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
        }
      })
      form.setFieldsValue(formValues)
      dispatch(setInputsTwo(newOrder));
    },
    [dispatch, form]
  );
  const handleReorderCurrent = useCallback(
    (newOrder) => {
      // Compare the new order with the existing items to avoid redundant updates
      if (JSON.stringify(newOrder) !== JSON.stringify(inputs)) {
        let formValues = { ...form.getFieldsValue() }
        newOrder?.forEach((order, index) => {
          formValues[index] = {
            ...formValues[index],
            current_start_date: order?.startDate !== "" ? dayjs(order?.startDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
            current_end_date: order?.endDate !== "" ? dayjs(order?.endDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
          }
        })
        form.setFieldsValue(formValues)
        dispatch(setInputss(newOrder));
      }
    },
    [dispatch, form, inputs]
  );
  const getLinkedinFormatedDate = (month, year) => {
    if ((month && ![null, "null", "Invalid date", ""]?.includes(month)) && (year && ![null, "null", "Invalid date", ""]?.includes(year))) {
      return dayjs(`${year}-${month}-01`).format('MM, YYYY');
    } else {
      return "";
    }

  }
  const scrapLinkedinData = useCallback(
    async () => {
      try {
        if (addData?.link) {
          setFetching(true)
          const res = await axios.get(`${API_PATH.SCRAP_LINKEDIN_PROFILE}?linkedinUrl=${addData?.link}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          });
          dispatch(setLinkedinScrapData(res?.data?.data))
          dispatch(setAddData({
            ...addData,
            expert_id: "",
            expert_name:
              res?.data?.data?.person?.firstName + " " + res?.data?.data?.person?.lastName || "",
            email: addData?.email || null,
            primary_expert_rate: "",
            link: res?.data?.data?.person?.linkedInUrl || "",
            submit: "",
            consulting_rate: addData?.consulting_rate || "",
            expertCurrency: addData?.expertCurrency || "USD",
            // ------------DOUBT--------------
            hourly_consulting_rate: addData?.hourly_consulting_rate || "",
            expert_client_Currency: addData?.expert_client_Currency || "USD",
            // ------------DOUBT--------------
            current_location: addData?.current_location || "",
            industry_experience: addData?.industry_experience || "",
            inustry_or_area_of_expertise: addData?.inustry_or_area_of_expertise || "",
            profile_number: String(Number(profile_count) + 1),
          }));

          dispatch(setOverview(res?.data?.data?.person?.summary || ""))
          const currentData = res?.data?.data?.person?.positions?.positionHistory;
          const temDatas = currentData?.map((value, index) => {
            if (value?.startEndDate?.end === null) {
              return {
                id: String(index + 1),
                title: value?.title,
                company: value?.companyName,
                startDate: getLinkedinFormatedDate(value?.startEndDate?.start?.month, value?.startEndDate?.start?.year),
                endDate: "",
                till_present: "yes",
                current_show_check: "no",
              };
            }
            return null;
          });

          const filteredData = temDatas.filter((val) => val !== null);
          console.log('filteredData: ', filteredData);
          dispatch(setInputss(filteredData));
          const currentPrevData = res?.data?.data?.person?.positions?.positionHistory;
          const temPrevData = currentPrevData.map((value, index) => {
            if (value?.startEndDate?.end !== null) {
              return {
                id: String(index + 1),
                titlePrev: value?.title,
                companyPrev: value?.companyName,
                startDatePrev: getLinkedinFormatedDate(value?.startEndDate?.start?.month, value?.startEndDate?.start?.year),
                endDatePrev: getLinkedinFormatedDate(value?.startEndDate?.end?.month, value?.startEndDate?.end?.year),
                previous_show_check: "no",
              };
            } else {
              return null;
            }
          });
          const filteredPrevData = temPrevData.filter((val) => val !== null);
          console.log('filteredPrevData: ', filteredPrevData);
          dispatch(setInputsTwo(filteredPrevData));


          let currentObj = filteredData?.map((data) => {
            return {
              current_end_date: data?.endDate !== "" ? dayjs(data?.endDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
              current_start_date: data?.startDate !== "" ? dayjs(data?.startDate, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
            }
          })
          let prevObj = filteredPrevData?.map((data) => {
            return {
              prev_end_date: data?.endDatePrev !== "" ? dayjs(data?.endDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : "",
              prev_start_date: data?.startDatePrev !== "" ? dayjs(data?.startDatePrev, ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"]) : ""
            }
          })

          const maxLength = Math.max(currentObj?.length, prevObj?.length);
          const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
            ...currentObj[index],
            ...prevObj[index],
          }));

          console.log('mergedArray: ', mergedArray);
          form.setFieldsValue({
            ...mergedArray,
            email: addData?.email || "",
            link: res?.data?.data?.person?.linkedInUrl || "",
            expert_name: res?.data?.data?.person?.firstName + " " + res?.data?.data?.person?.lastName || "",
            consulting_rate: addData?.consulting_rate || "",
          });
          setFetching(false);
        } else {
          dispatch(setAddData({
            ...addData,
            profile_number: String(Number(profile_count) + 1)
          }))
          setFetching(false);
        }
      } catch (error) {
        setFetching(false);
        console.error(error);
      }
    },
    [addData, dispatch, form, profile_count, userData?.token],
  )
  const linkedInUrlValidator = (_, value) => {
    const regex = /^https?:\/\/(www\.)?linkedin\.com\/(in|pub|company|school)\/[A-Za-z0-9_-]+\/?$/;
    if (!value || regex.test(value)) {
      setIsUrlValid(true)
      return Promise.resolve();
    }
    setIsUrlValid(false)
    return Promise.reject(new Error('Please enter a valid LinkedIn profile URL (https://www.linkedin.com/in/xyz/)'));
  };
  return (
    <div>
      <Form
        className=""
        form={form}
        scrollToFirstError
        layout="vertical"
        onFinish={() => {
          AddExpert("Submit");
        }}
      >
        <div>
          <h2 class="card-inside-title">Expert Details</h2>
          <Row md={12}>
            <Col md={6}>
              <Form.Item
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Expert email is required.",
                  },
                ]}
              >
                {/* <Select
                showSearch
                placeholder="Expert Email"
                style={{
                  width: "100%",
                }}
                size="large"
                options={optionsDatas}
                filterOption={(inputValue, option) => {
                  return (
                    option?.value
                      ?.toUpperCase()
                      ?.indexOf(inputValue?.toUpperCase()) !== -1
                  );
                }}
                name="expert_id"
                virtual={false}
                allowClear
                onChange={onChange}
                defaultValue={addData?.email}
                value={addData?.email}
              /> */}
                <DebounceSelect
                  type="expert"
                  size="large"
                  optionType="email"
                  disabled={addData?.submit === "Submit"}
                  value={addData?.email}
                  placeholder="Select Expert"
                  onChange={onChangeEmail}
                  style={{
                    width: "100%",
                  }}
                  creatableSelect={true}
                  extraFunction={(email) => {
                    dispatch(setAddData({ ...addData, email: email?.value, }));
                    form.setFieldValue("email", email?.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name={"link"}
                rules={[
                  {
                    required: true,
                    message: "Linkedin URL is required.",
                  },
                  {
                    validator: linkedInUrlValidator,
                  }
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Linkedin URL"
                  name="link"
                  value={addData?.link}
                  onChange={handleChange}
                  addonAfter={<Tooltip title="Fetch Data" color="#5746EB"><Button
                    type="default"
                    disabled={addData?.link === "" || !isUrlValid}
                    onClick={() => {
                      scrapLinkedinData()
                    }}
                    className="d-flex align-items-center">
                    {fetching ? <Spin
                      size="small"
                      indicator={
                        <LoadingOutlined className="viewBttnExpert rounded-circle" />
                      }
                    /> : <IoLogoLinkedin color="#5746EB" />}
                  </Button></Tooltip>}
                />
              </Form.Item>
            </Col>
            {/* <Col md={1} className="mt-1 ">
              <Tooltip title="Scrap From Linkedin">
                <Button
                  type="default"
                  disabled={addData?.link === "" || !isUrlValid}
                  onClick={() => {
                    scrapLinkedinData()
                  }}
                  className="d-flex align-items-center">
                  {fetching ? <Spin
                    size="small"
                    indicator={
                      <LoadingOutlined className="viewBttnExpert rounded-circle" />
                    }
                  /> : <IoLogoLinkedin />}
                </Button>
              </Tooltip>
            </Col> */}
            <Col md={6}>
              <Form.Item
                name={"expert_name"}
                rules={[
                  {
                    required: true,
                    message: "Expert Name is required.",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Expert Name"
                  autoComplete="off"
                  name="expert_name"
                  value={addData?.expert_name}
                  onChange={(e) => {
                    dispatch(setAddData({ ...addData, expert_name: e.target.value }));
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item
                name={"consulting_rate"}
                rules={[
                  {
                    required: true,
                    message: "Rate is required.",
                  },
                ]}
              >
                <InputNumber
                  addonBefore={suffixSelectorExp}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  name="consulting_rate"
                  placeholder="Consulting Rate (For Expert)"
                  value={
                    addData?.consulting_rate === ""
                      ? undefined
                      : addData?.consulting_rate
                  }
                  onChange={(value) => {
                    dispatch(setAddData({ ...addData, consulting_rate: value, }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-0" />
          <Row lg={12} className="mt-2">
            <Col lg={4}>
              {/* <Input
                size="large"
                placeholder="Location"
                name="current_location"
                value={addData?.current_location}
                onChange={handleChange}
              /> */}
              <Select
                name="current_location"
                style={{
                  width: "100%",
                }}
                size="large"
                labelInValue
                allowClear
                placeholder="Select Location"
                autoComplete="off"
                showSearch
                value={addData?.current_location || null}
                onChange={(value) => {
                  dispatch(setAddData({
                    ...addData,
                    current_location: value?.value,
                  }));
                }}
              >
                {updatedCountries}
              </Select>
            </Col>
            <Col lg={4}>
              {/* <Input
                size="large"
                placeholder="Industry/Area of Expertise"
                name="inustry_or_area_of_expertise"
                value={addData?.inustry_or_area_of_expertise}
                onChange={handleChange}
              /> */}
              <Select
                style={{
                  width: "100%",
                }}
                name="inustry_or_area_of_expertise"
                size="large"
                onSearch={(value) => dispatch(setFilteredIndust(value))}
                showSearch
                open={open}
                allowClear
                placeholder="Add Industries/Area of expertise"
                value={addData?.inustry_or_area_of_expertise || []}
                onDropdownVisibleChange={handleDropdownIndustry}
                onChange={(value) => {
                  dispatch(setAddData({
                    ...addData,
                    inustry_or_area_of_expertise: value,
                  }));
                }}
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                dropdownRender={
                  (menu) => (
                    <>
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space.Compact
                          block
                          style={{ padding: "0 8px 4px", width: "100%" }}
                          className="zum_meeting_space"
                        >
                          <Input
                            placeholder="Add Industries/Area of expertise"
                            width={100}
                            value={customIndustry}
                            onChange={(e) => {
                              dispatch(setCustomIndustry(e.target.value))
                            }}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          {resExpert ? (
                            <Button
                              type="text"
                              style={{ color: "green" }}
                              className="d-flex align-items-center"
                            >
                              <FaCheck />
                            </Button>
                          ) : (
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={(e) => {
                                dispatch(setResExpert(true))
                                dispatch(setAddData({
                                  ...addData,
                                  inustry_or_area_of_expertise: customIndustry
                                }));
                                setTimeout(() => {
                                  dispatch(setOpen(false))
                                }, 500);

                              }}
                            >
                              Add
                            </Button>

                          )}
                        </Space.Compact>
                      </>
                    </>
                  )

                }
              >
                {filteredIndustOptions?.map((option) => (
                  <Option key={option?._id} value={option?.category_name}>
                    {option?.category_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={4}>
              <Input
                size="large"
                autoComplete="off"
                placeholder="Years of Industry Experience"
                name="industry_experience"
                value={addData?.industry_experience}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <Divider />
        <div>
          <h2 class="card-inside-title">Current Designation</h2>
          <div className="drag-container">
            <Reorder.Group axis="y" onReorder={handleReorderCurrent} values={inputs} layoutScroll>
              {inputs?.map(
                (input, index) =>
                  input && (
                    <div key={index}>
                      <CurrentCompanyItem key={input?.id} Form={Form} index={index} input={input} inputs={inputs} form={form} />
                    </div>
                  )
              )}

              <div className="d-flex justify-content-center align-items-center mt-4">
                <Button
                  size="default"
                  icon={<PlusCircleOutlined />}
                  className="text-center d-flex align-items-center viewButton"
                  onClick={handleAddInput}
                >
                  Add Current Designation
                </Button>
              </div>
            </Reorder.Group>

          </div>
        </div>
        <Divider />
        <div>
          <h2 class="card-inside-title">Previous Designation</h2>
          <div className="drag-container">
            <Reorder.Group axis="y" onReorder={handleReorder} values={inputsTwo} layoutScroll >
              {inputsTwo?.map((input, index) => (
                <div key={index}>
                  <PreviousCompanyItem key={input?.id} Form={Form} index={index} input={input} inputsTwo={inputsTwo} form={form} />
                </div>
              ))}

              <div className="d-flex justify-content-center align-items-center mt-4">
                <Button
                  size="medium"
                  icon={<PlusCircleOutlined />}
                  className="text-center d-flex align-items-center viewButton"
                  onClick={handleAddInputTwo}
                >
                  Add Previous Designation
                </Button>
              </div>
            </Reorder.Group>
          </div>
        </div>
        <Divider />
        <div>
          <h2 class="card-inside-title">Overview</h2>
          <Row
            gutter={[24, 24]}
            className="d-flex justify-content-between align-items-center"
          >
            <Col span={24}>
              <CKEditor
                editor={Editor}
                data={overview || ""}
                // onReady={(editor) => {
                //   console.info("Editor is ready to use!", editor);
                // }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "undo",
                    "redo",
                  ],
                  placeholder: "To reference an expert type @",
                }}

                onChange={(event, editor) => {
                  const newData = editor.getData();
                  dispatch(setOverview(newData));
                }}
              />

            </Col>
          </Row>
        </div>
        <Divider />
        <div>
          <Row className="d-flex align-items-center mt-4">
            <Col lg={4}>
              <Form.Item label="Expert Profile Number">
                <div>
                  <Input
                    size="large"
                    autoComplete="off"
                    type="number"
                    disabled
                    placeholder="Expert Profile Number"
                    name="profile_number"
                    value={addData?.profile_number}
                    onChange={handleChange}
                  />
                </div>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
                <Form.Item label="Expert: Hourly Consulting Rate" />
                <InputNumber
                  addonBefore={suffixSelector}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  name="consulting_rate"
                  value={addData?.consulting_rate}
                  onChange={expertRate}
                />
              </Col> */}

            <Col lg={6}>
              <Form.Item
                label="Client: Hourly Consulting Rate"
                layout="vertical"
              >
                <InputNumber
                  addonBefore={suffixSelectorTwo}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  name="hourly_consulting_rate"
                  placeholder="Hourly Consulting Rate for client"
                  value={addData?.hourly_consulting_rate}
                  onChange={clientRate}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <Button
            size="default"
            className="text-center me-2 viewBttn"
            onClick={() => AddExpert("Save as Draft")}
            htmlType="button"
          >
            Save as Draft
          </Button>
          <Button
            size="default"
            className="text-center viewButton"
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewExpertSection;
