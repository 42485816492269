import { Select } from "antd";
import { Country } from "country-state-city";
import dayjs from "dayjs";
import moment from "moment";

export const toQueryString = (paramsArray) => {
    let params_obj = {};
    Object.entries(paramsArray)
        ?.filter(
            ([key, value]) =>
                value !== "" &&
                value !== null &&
                value !== undefined &&
                value?.length !== 0
        )
        .forEach(([key1, value]) => {
            if (Array.isArray(value)) {
                params_obj = {
                    ...params_obj,
                    [key1]: JSON.stringify(value),
                };
            } else {
                if (
                    [
                        "page",
                        "limit",
                        "key",
                        "expert_type",
                        "search",
                        "searchkey",
                    ].includes(key1)
                ) {
                    params_obj = {
                        ...params_obj,
                        [key1]: value,
                    };
                } else {
                    params_obj = {
                        ...params_obj,
                        [key1]: JSON.stringify([value]),
                    };
                }
            }
        });
    return params_obj;
};

export const getFinancialYear = () => {
    const today = moment();
    const financialYearStart = moment().month(3).date(1); // April 1st of the current year
    let financialYear;
    if (today.isBefore(financialYearStart)) {
        financialYear = today.subtract(1, "year").format("YYYY");
    } else {
        financialYear = today.format("YYYY");
    }
    return financialYear;
};

export const Calculate_days = (projectStart, projectEnd) => {
    // const startDate = new Date(projectStart);
    if (
        ![null, "", "null", undefined, "undefined"].includes(projectStart) &&
        ![null, "", "null", undefined, "undefined"].includes(projectEnd)
    ) {
        let startDate = new Date();
        if (projectEnd) {
            startDate = new Date();
        } else {
            startDate = new Date(projectStart);
        }
        const currentDate = !["", "undefined", undefined, null, "null"]?.includes(
            projectEnd
        )
            ? new Date(projectEnd)
            : new Date();
        const timeDifference = currentDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        return `${daysDifference} days`;
    } else {
        return "- -";
    }
};

export const formatNumber = (value) => {
    // Check if the number is an integer
    if (Number.isInteger(value)) {
        return value;
    } else {
        // If it's a floating-point number, format it to 2 decimal places
        return value.toFixed(1);
    }
};

export const getQueryParams = (query) => {
    if (query?.startsWith("?")) {
        query = query?.slice(1);
    }

    const result = {};
    let pairs = [];
    let buffer = "";
    let inBrackets = false;

    for (let i = 0; i < query?.length; i++) {
        const char = query[i];

        if (char === "[") inBrackets = true;
        if (char === "]") inBrackets = false;

        if (char === "&" && !inBrackets) {
            pairs?.push(buffer);
            buffer = "";
        } else {
            buffer += char;
        }
    }
    pairs?.push(buffer); // push the last part after the loop
    pairs.forEach((pair) => {
        const [key, value] = pair?.split("=");
        const decodedValue = decodeURIComponent(value?.replace(/\+/g, " "));
        // Check if the decoded value is a JSON array
        if (decodedValue?.startsWith("[") && decodedValue?.endsWith("]")) {
            try {
                result[key] = JSON.parse(decodedValue);
            } catch (e) {
                result[key] = decodedValue;
            }
        } else {
            result[key] = decodedValue;
        }
    });
    return result;
};

export const convertToTitleCase = (inputString = "") => {
    return inputString?.toLowerCase()?.replace(/\b\w/g, function (match) {
        return match?.toUpperCase();
    });
};

export const getUpdatedCountriesTimeZone = (countryName) => {
    const countries = Country?.getAllCountries();
    if (!["", undefined, "undefined", null, "null"]?.includes(countryName)) {
        let timeZones = countries?.filter(
            (country) => country?.name === countryName
        )?.[0]?.timezones;
        // let unique = []
        // timeZones?.forEach((gmt, index) => {
        //     if (unique.filter((elem) => elem.gmtOffsetName === gmt.gmtOffsetName)?.length === 0) {
        //         unique.push(gmt)
        //     }
        // })

        timeZones?.sort((a, b) => {
            if (a.abbreviation < b.abbreviation) {
                return -1;
            }
            if (a.abbreviation > b.abbreviation) {
                return 1;
            }
            return 0;
        });
        return timeZones?.map((country, index) => {
            return (
                <Select.Option key={country?.zoneName} value={country?.zoneName} title={country?.abbreviation}>
                    {country?.abbreviation} - {country?.gmtOffsetName} -{" "}
                    {country?.zoneName}
                </Select.Option>
            );
        });
    }
};

export const getUpdatedTimeZone = (countryName, timezone) => {
    const countries = Country?.getAllCountries();
    if (
        !["", undefined, "undefined", null, "null"]?.includes(countryName) &&
        !["", undefined, "undefined", null, "null"]?.includes(timezone)
    ) {
        let timeZones = countries?.filter(
            (country) => country?.name === countryName
        )?.[0]?.timezones;

        timeZones?.sort((a, b) => {
            if (a.abbreviation < b.abbreviation) {
                return -1;
            }
            if (a.abbreviation > b.abbreviation) {
                return 1;
            }
            return 0;
        });
        let filteredTz = timeZones.filter(
            (country) => country?.zoneName === timezone
        )?.[0];
        return `${filteredTz?.abbreviation} - ${filteredTz?.gmtOffsetName}`;
    }
};

export const statusSetter = (status) => {
    if (status === "Edit") {
        return (
            <p>
                Are you sure you want to schedule a call with this expert? Once you
                confirm, our team will get in touch with you to lock in a convenient day
                and time for the same.{" "}
            </p>
        );
    } else if (status === "Request") {
        return <p>Are you sure, you want to mark this call as Requested?</p>;
    } else if (status === "Schedule") {
        return <p>Are you sure, you want to mark this call as Scheduled?</p>;
    } else if (status === "Complete") {
        return <p>Are you sure, you want to mark this call as completed?</p>;
    } else if (status === "Decline") {
        return <p>Are you sure, you want to mark this call as Declined?</p>;
    } else if (status === "Delete") {
        return <p>Are you sure, you want to delete this expert?</p>;
    }
};

export const statusButton = (status) => {
    if (status === "Edit") {
        return "Edit";
    } else if (status === "Request") {
        return "Request";
    } else if (status === "Schedule") {
        return "Schedule";
    } else if (status === "Complete") {
        return "Complete";
    } else if (status === "Decline") {
        return "Decline";
    } else if (status === "Delete") {
        return "Delete";
    }
};

export const getMonthName = (monthNum) => {
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const index = parseInt(monthNum, 10) - 1;
    return monthNames[index];
};

export const convertDate = (date) => {
    if (
        date !== null &&
        date !== "" &&
        date !== "null" &&
        date !== "null - null"
    ) {
        // Check if the date is in the format "08, 2023 - 09, 2023"
        if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
            const [startDate] = date.split(" - ");
            const [startMonthNum, startYear] = startDate.trim().split(", ");
            const startMonth = getMonthName(startMonthNum);
            return `${startMonth}, ${startYear}`;
        }

        // Check if the date is in the format "08, 2023 - "
        if (/^\d{2}, \d{4} - $/.test(date)) {
            const [startDate] = date.split(" - ");
            const [startMonthNum, startYear] = startDate.trim().split(", ");
            const startMonth = getMonthName(startMonthNum);
            return `${startMonth}, ${startYear}`;
        }

        // Check if the date is in the format "08, 2023 "
        if (/^\d{2}, \d{4}$/.test(date)) {
            const [startMonthNum, startYear] = date.trim().split(", ");
            const startMonth = getMonthName(startMonthNum);
            return `${startMonth}, ${startYear}`;
        }

        // Check if the date is in the format "2018-04"
        if (/^\d{4}-\d{2}$/.test(date)) {
            const [year, monthNum] = date.split("-");
            const month = getMonthName(monthNum);
            return `${month}, ${year}`;
        }

        // Check if the date is in the format "10, 2014 - 01, 2020"
        if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
            const [startDate] = date.split(" - ");
            const [startMonthNum, startYear] = startDate.trim().split(", ");
            const startMonth = getMonthName(startMonthNum);
            return `${startMonth}, ${startYear}`;
        }

        // Check if the date is in the format "02, 2018   -    "
        if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
            const [startDate] = date.split(" - ");
            const [startMonthNum, startYear] = startDate.trim().split(", ");
            const startMonth = getMonthName(startMonthNum);
            return `${startMonth}, ${startYear}`;
        }

        // For other formats, convert the date to the desired format
        var d = new Date(date);
        var day = d.getDate();
        var monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        var month = monthNames[d.getMonth()];
        var year = d.getFullYear();
        let formattedDate = `${day} ${month}, ${year}`;
        return formattedDate;
    } else {
        return "";
    }
};

export const getFormattedDate = (date) => {
    if (!date || typeof date !== "string") {
        return null;
    }

    const dates = date.split("-");
    const possibleFormats = ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"];

    const startDate = ![
        null,
        undefined,
        "undefined",
        "",
        " ",
        "null",
        "null - null",
    ]?.includes(dates[0]?.trim())
        ? convertDate(dates[0]?.trim(), "month") ||
        moment(dates[0]?.trim(), possibleFormats)?.format("MMM YYYY")
        : "";
    const endDate = ![
        null,
        undefined,
        "undefined",
        "",
        " ",
        "null",
        "null - null",
    ]?.includes(dates[1]?.trim())
        ? convertDate(dates[1]?.trim(), "month") ||
        moment(dates[1]?.trim(), possibleFormats)?.format("MMM YYYY")
        : "";

    return `${startDate} - ${endDate}`;
};

export const getFormattedDateInOneFormat = (date) => {
    let trimDate = date?.trim();
    if (trimDate && trimDate != null && trimDate != "null") {
        const possibleFormats = ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"];
        let detectedFormat = null;
        for (let format of possibleFormats) {
            const parsedDate = moment(trimDate?.trim(), format, true);
            if (parsedDate.isValid()) {
                detectedFormat = format;
                break;
            }
        }
        return dayjs(trimDate, detectedFormat)?.format("MM, YYYY");
    } else {
        return "";
    }
};
export const regions = [
    'APAC', 'Asia', "Global", "SAARC",
    'Benelux', 'Caribbean',
    'Central Asia', 'East Asia',
    'Europe', 'GCC',
    'Latin America', 'MENA',
    'Mainland North America', 'Middle East',
    'Nordics', 'North America',
    'Oceania', 'South America',
    'South Asia', 'Southeast Asia'
]
export const modifiedContent = (cntnt) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(cntnt, "text/html");

    // Find all anchor tags within the parsed document
    const anchors = doc.querySelectorAll("a");

    // Add the target="_blank" attribute to each anchor tag
    anchors.forEach((anchor) => {
        let href = anchor.getAttribute("href");

        // Check if href doesn't start with 'https'
        if (href && !href.startsWith("https")) {
            // If href starts with 'http', replace with 'https'
            if (href.startsWith("http")) {
                href = href.replace(/^http:/, "https:");
            }
            // If href doesn't start with any protocol, add 'https://'
            else if (!href.startsWith("//") && !href.startsWith("/")) {
                href = `https://${href}`;
            }

            // Set the modified href back to the anchor tag
            anchor.setAttribute("href", href);
        }

        // Set target="_blank" to open the link in a new tab
        anchor.setAttribute("target", "_blank");
    });

    cntnt = doc.body.innerHTML;

    return cntnt;
};


export const BANK_DETAILS_COUNTRIES = ["United States", "United Kingdom", "Belgium", "France", "Italy", "Netherland", "Malaysia", "Australia", "Singapore", "United Arab Emirates", "China", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "India", "Germany", "Sri Lanka", "Poland", "Thailand", "Czech Republic", "Pakistan", "New zealand", "Botswana", "Vietnam", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "Philippines", "Morocco", "Mexico", "Others"]

export const ACCOUNT_NAME_COUNTRIES = ["United States", "United Kingdom", "Belgium", "France", "Italy", "Netherland", "Malaysia", "Australia", "Singapore", "United Arab Emirates", "China", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "Germany", "Sri Lanka", "Poland", "Thailand", "Czech Republic", "Pakistan", "New zealand", "Botswana", "Vietnam", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "Philippines", "Morocco", "Mexico", "India", "Others"]
export const ACCOUNT_NAME_MANDATORY_COUNTRIES = ["United States", "United Kingdom", "Belgium", "France", "Italy", "Netherland", "Malaysia", "Australia", "Singapore", "United Arab Emirates", "China", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "Germany", "Sri Lanka", "Poland", "Thailand", "Czech Republic", "Pakistan", "New zealand", "Botswana", "Vietnam", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "Philippines", "Morocco", "Mexico", "India", "Others"]



export const ACCOUNT_NUMBER_COUNTRIES = ["United States", "United Kingdom", "Malaysia", "Australia", "Singapore", "China", "China", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "Sri Lanka", "Thailand", "New zealand", "Botswana", "Vietnam", "Sweden", "Philippines", "Morocco", "India", "Others"]
export const ACCOUNT_NUMBER_MANDATORY_COUNTRIES = ["United States", "United Kingdom", "Malaysia", "Australia", "Singapore", "China", "China", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "Sri Lanka", "Thailand", "New zealand", "Botswana", "Vietnam", "Sweden", "Philippines", "Morocco", "India", "Others"]

export const ADDRESS_COUNTRIES = ["United States", "United Kingdom", "Belgium", "France", "Italy", "Netherland", "Malaysia", "Australia", "Singapore", "China", "United Arab Emirates", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "Germany", "Sri Lanka", "Poland", "Thailand", "Czech Republic", "Pakistan", "New zealand", "Botswana", "Vietnam", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "Philippines", "Morocco", "Mexico", "India", "Others"]
export const ADDRESS_MANDATORY_COUNTRIES = ["United States", "United Kingdom", "Belgium", "France", "Italy", "Netherland", "Malaysia", "Australia", "Singapore", "China", "United Arab Emirates", "Japan", "South Korea", "Hong Kong S.A.R.", "Canada", "Brazil", "Indonesia", "South Africa", "Nigeria", "Germany", "Sri Lanka", "Poland", "Thailand", "Czech Republic", "Pakistan", "New zealand", "Botswana", "Vietnam", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "Philippines", "Morocco", "Mexico", "India", "Others"]

export const PAN_COUNTRIES = ["India"]
export const PAN_MANDATORY_COUNTRIES = ["India"]

export const BENEFICIARY_ACCOUNT_COUNTRY = ["India"]
export const BENEFICIARY_ACCOUNT_MANDATORY_COUNTRY = ["India"]

export const ACH_ROUTING_NUMBER_COUNTRIES = ["United States"]
export const ACH_ROUTING_NUMBER_MANDATORY_COUNTRIES = ["United States"]

export const SORT_CODE_COUNTRIES = ["United States", "United Kingdom"]
export const SORT_CODE_MANDATORY_COUNTRIES = ["United Kingdom"]

export const RECIPIENT_NAME_COUNTRIES = ["China", "Others"]
export const RECIPIENT_NAME_MANDATORY_COUNTRIES = ["China", "Others"]

export const ACCOUNT_TYPE_COUNTRIES = ["Japan", "Others"]
export const ACCOUNT_TYPE_MANDATORY_COUNTRIES = ["Japan"]

export const FINANCE_INSTITUTE_COUNTRIES = ["Canada"]
export const FINANCE_INSTITUTE_MANDATORY_COUNTRIES = ["Canada"]

export const TRANSIT_NUMBER_COUNTRIES = ["Canada"]
export const TRANSIT_NUMBER_MANDATORY_COUNTRIES = ["Canada"]

export const INDIVIDUAL_TAX_ID_COUNTRIES = ["Brazil"]
export const INDIVIDUAL_TAX_ID_MANDATORY_COUNTRIES = ["Brazil"]

export const IBAN_COUNTRIES = ["Belgium", "France", "Italy", "Netherland", "United Arab Emirates", "Germany", "Poland", "Czech Republic", "Pakistan", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "Others"]
export const IBAN_MANDATORY_COUNTRIES = ["Belgium", "France", "Italy", "Netherland", "United Arab Emirates", "Germany", "Poland", "Czech Republic", "Pakistan", "Turkey", "Switzerland", "Sweden", "Saudi Arabia", "India", "Others"]

export const IFSC_COUNTRIES = ["India"]
export const IFSC_MANDATORY_COUNTRIES = ["India"]

export const SWIFT_CODE_COUNTRIES = ["Malaysia", "Singapore", "China", "Indonesia", "South Africa", "Nigeria", "Sri Lanka", "Thailand", "Botswana", "Vietnam", "Philippines", "Others"]
export const SWIFT_CODE_MANDATORY_COUNTRIES = ["Malaysia", "Singapore", "China", "Indonesia", "South Africa", "Nigeria", "Sri Lanka", "Thailand", "Botswana", "Vietnam", "Philippines", "Others"]

export const BSB_CODE_COUNTRIES = ["Australia", "Japan", "Brazil", "Others"]
export const BSB_CODE_MANDATORY_COUNTRIES = ["Australia", "Japan", "Brazil", "Others"]

export const BANK_NAME_OR_CODE_COUNTRIES = ["Japan", "South Korea", "Hong Kong S.A.R.", "Brazil", "Botswana", "Sweden", "Others"]
export const BANK_NAME_OR_CODE_MANDATORY_COUNTRIES = ["Japan", "South Korea", "Hong Kong S.A.R.", "Brazil", "Botswana", "Sweden", "Others"]

export const CLABE_COUNTRIES = ["Mexico"]
export const CLABE_MANDATORY_COUNTRIES = ["Mexico"]

export const BANK_BRANCH_NAME_COUNTRIES = ["Others"]
export const BANK_BRANCH_NAME_MANDATORY_COUNTRIES = []
