import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { resetZoomMeeting, setAllResult, setInputs, setIsAutoRecordingCheckboxChecked, setIsCheckboxChecked, setIsExpertCheckboxChecked, setIsMediatorCheckboxChecked, setIsMeetingLinkCheckboxChecked, setMediator, setStep1, setStep3, setUserProjectList, setZoomAccountList, setZoomCompanyList, setZoomExpertList, setZoomHostAccountList } from "../../features/zoomMeeting.slice";
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce'

import useSWR from "swr";
import { Form, notification } from "antd";
import API_URL from "../../Constants/api-path";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { convertToTitleCase } from "../../Utils/util";
import moment from "moment";

const useZoomMeeting = () => {
    const { isCheckboxChecked, zoomHostAccountList, zoomAccountList, step1, step3, inputs, mediators, allResult, zoomCompanyList, isExpertCheckboxChecked, userProjectList, zoomExpertList, isMediatorCheckboxChecked, autoRecordingCheckboxChecked, meetingLinkCheckboxChecked } = useSelector((state) => state.zoomMeetingReducer);


    const dispatch = useDispatch();
    const navigate = useNavigate()
    let { zoomId } = useParams();
    let [searchParams] = useSearchParams();
    let project_id = searchParams.get("pid") || null
    let project_name = searchParams.get("pn") || null
    let expert_id = searchParams.get("eid") || null
    let zoomId1 = searchParams.get("id");
    const backendDateFormat = "YYYY-MM-DD";
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [loadingClient, setLoadingClient] = useState(false)
    const [loadingExpert, setLoadingExpert] = useState(false)
    const userData = JSON.parse(localStorage.getItem("userData"));
    const projectPrefillOnce = useRef(false)

    const GetProjectDetails = useCallback(async () => {
        setLoading(true);
        form.resetFields();
        try {
            const res = await fetch(`${API_URL.TEAM_PROJECT_ADD}/${project_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            if (result) {
                let emailList = [...result?.data?.clientemail, ...result?.data?.clientteamemail]
                if (project_id !== null && project_name !== null && expert_id !== null) {
                    dispatch(setStep1({
                        ...step1,
                        project_name: result?.data?.project_title || project_name,
                        project_id: project_id,
                        client: result?.data?.company_id
                    }))
                    form.setFieldsValue({
                        project_name: result?.data?.project_title || project_name,
                        project_id: project_id,
                        engagement_type: "micro_consulting_call"
                    })
                    dispatch(setInputs(
                        emailList?.map((client) => {
                            return {
                                client_email: client?.email,
                                client_name: convertToTitleCase(client?.name?.trim()),
                                in_list: [undefined, null]?.includes(client?.in_list) ? true : client?.in_list
                            };
                        }))
                    );
                    let clientObj = emailList?.map((client, index) => {
                        return {
                            client_email: client?.email,
                            client_name: convertToTitleCase(client?.name?.trim()),
                            in_list: [undefined, null]?.includes(client?.in_list) ? true : client?.in_list
                        };
                    });

                    let formValues = form.getFieldsValue()
                    const maxLength = clientObj?.length;
                    const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
                        ...formValues[index],
                        ...clientObj[index],
                    }));
                    let obj = {
                        ...formValues,
                        ...mergedArray,
                    };
                    form.setFieldsValue(obj)
                }
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            setLoading(false);
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    }, [dispatch, expert_id, form, project_id, project_name, step1, userData?.token]);

    const resetCodeOnce = useRef(false)
    useEffect(() => {
        if (!resetCodeOnce.current) {
            resetCodeOnce.current = true
            form.resetFields()
            dispatch(resetZoomMeeting())
        }
    }, [dispatch, form])


    useEffect(() => {
        const setProjectData = async () => {
            projectPrefillOnce.current = true
            await GetProjectDetails()
        }
        if (project_id !== null && project_name !== null && expert_id !== null && !projectPrefillOnce.current) {
            setProjectData()
        }
    }, [GetProjectDetails, dispatch, expert_id, form, project_id, project_name, step1, step3])

    const setDefaultOnce = useRef(false)
    useEffect(() => {
        if (!setDefaultOnce.current) {
            setDefaultOnce.current = true
            form.setFieldValue("engagement_type", "micro_consulting_call")
        }
    }, [form])




    const GetZoomAccountList = useCallback(async () => {
        try {
            const res = await fetch(`${API_URL.ZOOM_ACCOUNT_LIST}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            return result;
        } catch (error) {
            console.error("error: ", error);
        }
    }, [userData?.token]);
    const GetUserProjectList = useCallback(async () => {
        try {
            const res = await fetch(`${API_URL.USER_PROJECT_LIST}/${userData?.data?.email}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            return result;
        } catch (error) {
            console.error("error: ", error);
        }
    }, [userData?.data?.email, userData?.token]);

    const GetMeetingById = useCallback(async () => {
        try {
            const res = await fetch(`${API_URL.DRAFT_MEETING_BY_ID}?id=${zoomId || zoomId1}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            return result;
        } catch (error) {
            console.error("error: ", error);
        }
    }, [userData?.token, zoomId, zoomId1]);

    const GetZoomCompanyList = useCallback(async () => {
        try {
            const res = await fetch(
                // `${API_PATH.CLIENT_LIST}?page=1&limit=10&${queryParams.toString()}`,
                `${API_URL.CLIENT_LIST}?page=1&limit=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );

            const result = await res.json();
            return result

        } catch (error) {

            return error
        }
    }, [userData?.token]);

    const GetAllClientLlist = useCallback(async () => {
        try {
            const res = await fetch(
                `${API_URL.PROJECT_CLIENT_LIST}/${step1?.project_id}?page=1&limit=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );

            const result = await res.json();
            return result

        } catch (error) {

            return error
        }
    }, [step1?.project_id, userData?.token]);
    const GetAllExpertLlist = useCallback(async () => {
        try {
            const res = await fetch(
                `${API_URL.PROJECT_EXPERT_LIST_ZOOM}/${step1?.project_id}?page=1&limit=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );

            const result = await res.json();
            return result

        } catch (error) {

            return error
        }
    }, [step1?.project_id, userData?.token]);

    const { isLoading: isLoadingClients } = useSWR(["get_all_client_list_data", step1?.project_id], () => step1?.project_id ? GetAllClientLlist() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        dedupingInterval: 0,
        onSuccess: (result) => {
            if (result !== null) {
                if (result) {
                    dispatch(setZoomAccountList(result?.data));
                    // dispatch(setStep1({
                    //     ...step1,
                    //     client: result?.data?.[0]?.company_id
                    // }))
                }
                // if (project_id !== null && project_name !== null && expert_id !== null) {
                //     dispatch(setInputs(
                //         result?.data?.map((client) => {
                //             return {
                //                 client_email: client?.email,
                //                 client_name: convertToTitleCase(client?.name),
                //                 in_list: [undefined, null]?.includes(client?.in_list) ? true : client?.in_list
                //             };
                //         }))
                //     );
                //     let clientObj = result?.data?.map((client, index) => {
                //         return {
                //             client_email: client?.email,
                //             client_name: convertToTitleCase(client?.name?.trim()),
                //             in_list: [undefined, null]?.includes(client?.in_list) ? true : client?.in_list
                //         };
                //     });

                //     let formValues = form.getFieldsValue()
                //     const maxLength = clientObj?.length;
                //     const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
                //         ...formValues[index],
                //         ...clientObj[index],
                //     }));
                //     let obj = {
                //         ...formValues,
                //         ...mergedArray,
                //     };
                //     form.setFieldsValue(obj)
                // }
            }
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });

    const { isLoading: isLoadingExperts } = useSWR(["get_projects_experts", step1?.project_id], () => step1?.project_id ? GetAllExpertLlist() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        dedupingInterval: 0,
        onSuccess: (result) => {
            if (result !== null) {
                if (result) {
                    dispatch(setZoomExpertList(result?.data));
                }
                if (project_id !== null && project_name !== null && expert_id !== null) {
                    let selectedExpert = result?.data?.filter((exp) => exp?._id === expert_id)
                    dispatch(setStep3(selectedExpert?.map((expert) => {
                        return {
                            expert_name: convertToTitleCase(expert?.name),
                            expert_designation: expert?.admin_expert_experience?.current_designation,
                            expert_company: expert?.admin_expert_experience?.current_compny,
                            expert_email: expert?.email,
                            expert_hourly_rate_call_cunsltn: step1?.engagement_type === "introductory_call" ? 0 : expert?.consulting_rate,
                            expert_hourly_currency: expert?.expertCurrency || "USD",
                            country: {},
                            timezone: "",
                            additional_details: "",
                            opt_out_check: false,
                            pre_call_email_sent: false,
                            in_list: true,
                        };
                    })));
                    let expertObj = selectedExpert?.map((expert) => {
                        return {
                            country: null,
                            expert_designation: expert?.admin_expert_experience?.current_designation,
                            expert_company: expert?.admin_expert_experience?.current_compny,
                            expert_email: expert?.email,
                            expert_hourly_rate_call_cunsltn: step1?.engagement_type === "introductory_call" ? 0 : expert?.consulting_rate,
                            expert_name: convertToTitleCase(expert?.name),
                            additional_details: "",
                            timezone: null,
                            expert_call_rate_currency: expert?.expertCurrency || "USD",
                        };
                    });

                    let formValues = form.getFieldsValue()
                    const maxLength = Math.max(expertObj?.length, inputs?.length);;
                    const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
                        ...formValues[index],
                        ...expertObj[index],
                    }));
                    let obj = {
                        ...formValues,
                        ...mergedArray,
                    };
                    form.setFieldsValue(obj)
                    dispatch(setStep1({
                        ...step1,
                        hourly_rate_call_cunsltn: step1?.engagement_type === "introductory_call" ? 0 : selectedExpert?.[0]?.hourly_consulting_rate,
                        hourly_currency: selectedExpert?.[0]?.hourly_consulting_rate_currency
                    }))
                    form.setFieldsValue({
                        hourly_rate_call_cunsltn: step1?.engagement_type === "introductory_call" ? 0 : selectedExpert?.[0]?.hourly_consulting_rate,
                        hourly_currency: selectedExpert?.[0]?.hourly_consulting_rate_currency
                    })
                }
            }
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });


    const { isLoading: isLoadingCompanyList, isValidating: isValidatingCompanyList } = useSWR("get_company_list", () => GetZoomCompanyList(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            if (result !== null) {
                if (result) {
                    dispatch(setZoomCompanyList(result?.data));
                }
            }
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });

    const { isLoading: isLoadingMeetingData, isValidating: isValidatingMeetingData } = useSWR(["get_zoom_meeting_by_id", zoomId, zoomId1], () => (!["undefined", undefined, "", null]?.includes(zoomId) || !["undefined", undefined, "", null]?.includes(zoomId1)) ? GetMeetingById() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            if (result !== null) {
                // if (result?.data?.[0]?.client) {
                //     fetchZoomAccountList({ key: result?.data?.[0]?.client })
                // }
                dispatch(setStep1({
                    project_name: result?.data?.[0]?.topic,
                    engagement_type: result?.data?.[0]?.engagement_type,
                    location_address: result?.data?.[0]?.location_address,
                    call_name: result?.data?.[0]?.call_name,
                    meeting_link: result?.data?.[0]?.meeting_link,
                    meeting_id: result?.data?.[0]?.meeting_id,
                    meeting_passcode: result?.data?.[0]?.meeting_passcode,
                    project_id: result?.data?.[0]?.project_id,
                    call_date: ["Invalid date", "null", "", "undefined"]?.includes(result?.data?.[0]?.call_date) ? moment().format("DD/MM/YYYY") : result?.data?.[0]?.call_date,
                    call_time: result?.data?.[0]?.call_time || null,
                    call_time_string: result?.data?.[0]?.call_time,
                    country: result?.data?.[0]?.client_country,
                    timezone: result?.data?.[0]?.client_timezone,
                    abbreviation: result?.data?.[0]?.abbreviation,
                    duration: result?.data?.[0]?.duration,
                    hourly_rate_call_cunsltn: result?.data?.[0]?.client_call_rate,
                    hourly_currency: result?.data?.[0]?.client_call_rate_currency,
                    zoom_id: result?.data?.[0]?.zoom_account,
                    client: result?.data?.[0]?.client,
                    client_company_name: result?.data?.[0]?.clientname?.[0]?.companyName
                }));

                dispatch(setInputs(
                    result?.data?.[0]?.client_details?.map((client) => {
                        return {
                            client_email: client?.client_email,
                            client_name: convertToTitleCase(client?.client_name),
                            in_list: [undefined, null]?.includes(client?.in_list) ? true : client?.in_list

                        };
                    }))
                );

                dispatch(setMediator(
                    result?.data?.[0]?.mediator_details?.map((mediat) => {
                        return {
                            mediator_email: mediat?.mediator_email,
                            mediator_name: mediat?.mediator_name,
                            mediator_country: mediat?.mediator_country,
                            mediator_timezone: mediat?.mediator_timezone,
                            abbreviation: mediat?.abbreviation,


                        };
                    }) || [{
                        mediator_email: "",
                        mediator_name: "",
                        mediator_country: "",
                        mediator_timezone: "",

                    }])
                );
                dispatch(setStep3(result?.data?.[0]?.expert_details?.map((expert) => {
                    return {
                        expert_name: convertToTitleCase(expert?.expert_name),
                        expert_designation: expert?.expert_designation,
                        expert_company: expert?.expert_company,
                        expert_email: expert?.expert_email,
                        expert_hourly_rate_call_cunsltn: expert?.expert_call_rate,
                        expert_hourly_currency: expert?.expert_call_rate_currency,
                        country: expert?.expert_country,
                        timezone: expert?.expert_timezone,
                        abbreviation: expert?.abbreviation,
                        additional_details: expert?.additional_details,
                        opt_out_check: expert?.optout_expert_for_pre_and_post_calls,
                        pre_call_email_sent: expert?.pre_call_email_sent || false,
                        in_list: expert?.in_list,
                    };
                })));
                dispatch(setIsCheckboxChecked(result?.data?.[0]?.is_internal_meeting));
                dispatch(setIsExpertCheckboxChecked(result?.data?.[0]?.optout_expert_for_pre_and_post_calls));
                dispatch(setIsMediatorCheckboxChecked(result?.data?.[0]?.is_mediator_added));
                dispatch(setIsAutoRecordingCheckboxChecked(result?.data?.[0]?.auto_recording));
                dispatch(setIsMeetingLinkCheckboxChecked(result?.data?.[0]?.is_meeting_link_provided));
                let clientObj = result?.data?.[0]?.client_details?.map((client, index) => {
                    return {
                        client_email: client?.client_email,
                        client_name: convertToTitleCase(client?.client_name),
                        in_list: [undefined, null]?.includes(client?.in_list) ? true : client?.in_list
                    };
                });
                let mediatorObj = result?.data?.[0]?.mediator_details?.map((mediat, index) => {
                    return {
                        mediator_email: mediat?.mediator_email,
                        mediator_name: mediat?.mediator_name,
                        mediator_country: mediat?.mediator_country,
                        mediator_timezone: mediat?.mediator_timezone,
                    };
                }) || [{
                    mediator_email: "",
                    mediator_name: "",
                    mediator_country: null,
                    mediator_timezone: null,

                }];
                let expertObj = result?.data?.[0]?.expert_details?.map((expert) => {
                    return {
                        country: expert?.expert_country,
                        expert_designation: expert?.expert_designation,
                        expert_company: expert?.expert_company,
                        expert_email: expert?.expert_email,
                        expert_hourly_rate_call_cunsltn: expert?.expert_call_rate,
                        expert_name: convertToTitleCase(expert?.expert_name),
                        additional_details: expert?.additional_details,
                        timezone: expert?.expert_timezone,
                        expert_call_rate_currency: expert?.expert_call_rate_currency,
                    };
                });
                const maxLength = Math.max(expertObj?.length, clientObj?.length, mediatorObj?.length);
                const mergedArray = Array.from({ length: maxLength }, (_, index) => ({
                    ...expertObj[index],
                    ...clientObj[index],
                    ...mediatorObj[index]
                }));
                let obj = {
                    ...mergedArray,
                    call_date: ["Invalid date", "null", "", "undefined"]?.includes(result?.data?.[0]?.call_date) ? dayjs() : dayjs(result?.data?.[0]?.call_date, backendDateFormat),
                    call_time: result?.data?.[0]?.call_time ? dayjs(result?.data?.[0]?.call_time, "HH:mm") : null,
                    client_information: "",
                    country: result?.data?.[0]?.client_country,
                    duration: result?.data?.[0]?.duration,
                    client: result?.data?.[0]?.clientname?.[0]?.companyName,
                    expert_information: "",
                    hourly_rate_call_cunsltn: result?.data?.[0]?.client_call_rate,
                    project_information: "",
                    project_name: result?.data?.[0]?.topic,
                    engagement_type: result?.data?.[0]?.engagement_type,
                    location_address: result?.data?.[0]?.location_address,
                    call_name: result?.data?.[0]?.call_name,
                    meeting_link: result?.data?.[0]?.meeting_link,
                    meeting_id: result?.data?.[0]?.meeting_id,
                    meeting_passcode: result?.data?.[0]?.meeting_passcode,
                    timezone: result?.data?.[0]?.client_timezone,
                    zoom_id: result?.data?.[0]?.zoom_account || undefined,
                };
                form.setFieldsValue(obj);
                dispatch(setAllResult(result?.data?.[0]))
            }
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });

    const { isLoading: isLoadingList } = useSWR("get_zoom_account_list", () => GetZoomAccountList(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            dispatch(setZoomHostAccountList(result?.data));
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });

    const { isLoading: isLoadingUserProject } = useSWR("get_user_project_list", () => GetUserProjectList(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            dispatch(setUserProjectList(result?.data));
        },
        onError: (error) => {
            console.error("EXPERT ERROR", error);
        },
    });

    const fetchCompanyList = useCallback(async (key = "") => {
        let object = { key: key, page: 1, limit: 10 };
        const queryParams = new URLSearchParams(object);
        try {
            const res = await fetch(
                `${API_URL.CLIENT_LIST}?${queryParams.toString()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );
            const result = await res.json();
            if (result !== null) {
                if (result) {
                    dispatch(setZoomCompanyList(result?.data));
                }
            }

        } catch (error) {
            return error
        }
    }, [dispatch, userData?.token]);

    const fetchZoomAccountList = useCallback(
        async (option) => {
            const selectedCompanyId = option?.key;
            if (selectedCompanyId) {
                try {
                    const res = await fetch(
                        `${API_URL.CLIENT_VIEW}/${selectedCompanyId}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userData?.token}`,
                            },
                        }
                    );

                    const result = await res.json();
                    if (result) {
                        dispatch(setZoomAccountList(result?.data));
                    }
                } catch (error) {
                    console.error('error: ', error);
                    // Handle error
                }
            }

        },
        [dispatch, userData?.token]
    );
    const fetchAllClientList = useCallback(
        async (key = "") => {
            try {
                const res = await fetch(
                    `${API_URL.PROJECT_CLIENT_LIST}/${step1?.project_id}?page=1&limit=10&key=${key}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    }
                );

                const result = await res.json();
                if (result) {
                    dispatch(setZoomAccountList(result?.data));
                }
                return result
            } catch (error) {
                console.error('error: ', error);
                // Handle error
            }
        },
        [dispatch, step1?.project_id, userData?.token]
    );
    const fetchAllExpertList = useCallback(
        async ({ value = "" }) => {
            try {
                const res = await fetch(
                    `${API_URL.PROJECT_EXPERT_LIST_ZOOM}/${step1?.project_id}?page=1&limit=10&key=${value}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    }
                );

                const result = await res.json();
                if (result) {
                    dispatch(setZoomExpertList(result?.data));
                }
                return result
            } catch (error) {
                console.error('error: ', error);
                // Handle error
            }
        },
        [dispatch, step1?.project_id, userData?.token]
    );

    const fetchUserProjectList = useCallback(
        async (key) => {
            try {
                const res = await fetch(
                    `${API_URL.USER_PROJECT_LIST}/${userData?.data?.email}?page=1&limit=10&key=${key}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    }
                );

                const result = await res.json();
                if (result) {
                    dispatch(setUserProjectList(result?.data));
                }
                return result
            } catch (error) {
                console.error('error: ', error);
                // Handle error
            }
        },
        [dispatch, userData?.data?.email, userData?.token]
    );

    const handleAddInput = useCallback(() => {
        const newInputs = [
            ...inputs,
            {
                client_name: "",
                client_email: "",
            },
        ];
        dispatch(setInputs(newInputs));
    }, [dispatch, inputs]);

    const handleAddMediator = useCallback(() => {
        const newmediators = [
            ...mediators,
            {
                mediator_email: "",
                mediator_name: "",
                mediator_country: "",
                mediator_timezone: "",

            }
        ];
        dispatch(setMediator(newmediators));
    }, [dispatch, mediators]);

    const handleAddInputExpert = useCallback(() => {
        const newInputs = [
            ...step3,
            {
                expert_name: null,
                expert_designation: null,
                expert_company: null,
                expert_email: null,
                expert_hourly_rate_call_cunsltn: step1?.engagement_type === "introductory_call" ? 0 : "",
                expert_hourly_currency: "USD",
                country: {},
                timezone: "",
                opt_out_check: false,
                in_list: true,
                additional_details: "",
                pre_call_email_sent: false,
            },
        ];
        dispatch(setStep3(newInputs));
    }, [dispatch, step1?.engagement_type, step3]);

    const handleInputChange = useCallback(
        (value, field, index) => {
            let oldArray = [...inputs];
            let newArray = oldArray?.map((input, i) => {
                if (i === index) {
                    if (typeof value === "object") {
                        return {
                            ...input,
                            client_email: value?.value,
                            client_name: convertToTitleCase(value?.title),
                            in_list: value?.in_list
                        };
                    } else {
                        return {
                            ...input,
                            [field]: value,
                        };
                    }
                }
                return input;
            });
            dispatch(setInputs(newArray));
            // setInputs((prevInputs) =>
            //     prevInputs?.map((input, i) => {
            //         if (i === index) {
            //             return {
            //                 ...input,
            //                 [field]: value,
            //             };
            //         }
            //         return input;
            //     })
            // );
        }, [dispatch, inputs]);

    const handleMediatorChange = useCallback(
        (value, field, index) => {
            let oldArray = [...mediators];
            let newArray = oldArray?.map((input, i) => {
                if (i === index) {
                    return {
                        ...input,
                        [field]: value,
                    };
                }
                return input;
            });
            dispatch(setMediator(newArray));

        }, [dispatch, mediators]);

    const handleMediatorCountryChange = useCallback((country, index) => {
        let oldArray = [...mediators];
        let newArray = oldArray?.map((input, i) => {
            if (i === index) {
                return {
                    ...input,
                    "mediator_country": country,
                    "mediator_timezone": "",
                    "abbreviation": ""

                };
            }
            return input;
        });
        dispatch(setMediator(newArray));
    }, [dispatch, mediators])

    const handleExpertCountryChange = useCallback((country, index) => {
        let oldArray = [...step3];
        let newArray = oldArray?.map((input, i) => {
            if (i === index) {
                return {
                    ...input,
                    "country": country,
                    "timezone": "",
                    "abbreviation": ""

                };
            }
            return input;
        });
        dispatch(setStep3(newArray));
    }, [dispatch, step3])

    const handleInputChangeExpert = useCallback(
        (value, field, index) => {
            let oldArray = [...step3];
            let newArray = oldArray?.map((input, i) => {
                if (i === index) {
                    if (typeof value === "object") {
                        return {
                            ...input,
                            expert_email: value?.value,
                            expert_name: convertToTitleCase(value?.title?.name?.trim()),
                            expert_designation: value?.title?.admin_expert_experience?.current_designation || "",
                            expert_company: value?.title?.admin_expert_experience?.current_compny || "",
                            expert_hourly_rate_call_cunsltn: value?.title?.hourly_consulting_rate,
                            expert_hourly_currency: value?.title?.hourly_consulting_rate_currency || "USD",
                            in_list: value?.in_list !== undefined ? value?.in_list : true,
                        };
                    } else {
                        return {
                            ...input,
                            [field]: value,
                        };
                    }
                }
                return input;
            });
            dispatch(setStep3(newArray));
        }, [dispatch, step3]);

    const handleInputChangeExpertTimezone = useCallback(
        (value, index) => {
            let oldArray = [...step3];
            let newArray = oldArray?.map((input, i) => {
                if (i === index) {
                    if (typeof value === "object") {
                        return {
                            ...input,
                            timezone: value?.value,
                            abbreviation: value?.title
                        };
                    }
                }
                return input;
            });
            dispatch(setStep3(newArray));
        }, [dispatch, step3]);

    const handleInputChangeMediatorTimezone = useCallback(
        (value, index) => {
            let oldArray = [...mediators];
            let newArray = oldArray?.map((input, i) => {
                if (i === index) {
                    if (typeof value === "object") {
                        return {
                            ...input,
                            mediator_timezone: value?.value,
                            abbreviation: value?.title
                        };
                    }
                }
                return input;
            });
            dispatch(setMediator(newArray));
        }, [dispatch, mediators]);

    const handleStep1Change = useCallback((event) => {
        const { name, value } = event.target;
        dispatch(setStep1({
            ...step1,
            [name]: value,
        }))
    }, [dispatch, step1]);

    const dateFormat = "DD/MM/YYYY";

    const handleDeleteInput = useCallback((index) => {
        if (inputs.length > 1) {
            const newInputs = [...inputs];
            newInputs.splice(index, 1);
            dispatch(setInputs(newInputs));
        }
    }, [dispatch, inputs]);

    const handleDeleteMediator = useCallback((index) => {
        if (mediators.length > 1) {
            const newmediators = [...mediators];
            newmediators.splice(index, 1);
            dispatch(setMediator(newmediators));
        }
    }, [dispatch, mediators]);

    const handleDeleteInputExpert = useCallback((index) => {
        if (step3.length > 1) {
            const newInputs = [...step3];
            newInputs.splice(index, 1);
            dispatch(setStep3(newInputs));
        }
    }, [dispatch, step3]);

    const expertCurrency = useCallback((value) => {
        const selectValue = value || "USD"; // Set "USD" if the value is falsy
        dispatch(setStep1({ ...step1, hourly_currency: selectValue }));
    }, [dispatch, step1]);

    const saveDraftMeeting = useCallback(async (saveAs = "submit") => {
        let body = {}
        // let domain = inputs?.[0]?.client_email?.split("@")?.[1]
        // let filterDomain = inputs.filter((client) => client?.client_email?.split("@")?.[1]?.trim() !== domain?.trim())
        // if (filterDomain?.length === 0) {
        if (saveAs === "draft") {
            body = {
                // "zoom_account": step1.zoom_id,
                "topic": step1?.project_name?.trim(),
                "engagement_type": step1?.engagement_type,
                "location_address": step1?.location_address,
                "call_name": step1?.call_name?.trim(),
                "meeting_link": step1?.meeting_link?.trim(),
                "meeting_id": step1?.meeting_id?.trim(),
                "meeting_passcode": step1?.meeting_passcode?.trim(),
                "project_id": step1?.project_id,
                "call_date": step1?.call_date,
                "call_time": step1?.call_time_string,
                "duration": step1?.duration?.value,
                "admin_id": userData?.data?._id,
                "client": step1?.client,
                "client_country": step1?.country,
                "client_timezone": step1?.timezone,
                "abbreviation": step1?.abbreviation,
                "client_call_rate_currency": step1?.hourly_currency || "USD",
                "client_call_rate": step1?.hourly_rate_call_cunsltn,
                "client_details": inputs?.map((input) => {
                    return {
                        client_name: input?.client_name?.trim(),
                        client_email: input?.client_email?.trim(),
                        in_list: input?.in_list

                    }
                }),
                "expert_details": step3?.map((step) => {
                    return {
                        "expert_email": step?.expert_email?.trim(),
                        "expert_name": step?.expert_name?.trim(),
                        "expert_country": step?.country,
                        "expert_timezone": step?.timezone,
                        "abbreviation": step?.abbreviation,
                        "expert_call_rate_currency": step?.expert_hourly_currency,
                        "expert_call_rate": step?.expert_hourly_rate_call_cunsltn,
                        "expert_designation": step?.expert_designation,
                        "expert_company": step?.expert_company,
                        "additional_details": step?.additional_details,
                        "optout_expert_for_pre_and_post_calls": step.opt_out_check,
                        "pre_call_email_sent": step.pre_call_email_sent,
                        "in_list": step?.in_list
                    }
                }),
                "mediator_details": isMediatorCheckboxChecked ? mediators?.map((mediat) => {
                    return {
                        mediator_email: mediat?.mediator_email?.trim(),
                        mediator_name: mediat?.mediator_name?.trim(),
                        mediator_country: mediat?.mediator_country,
                        mediator_timezone: mediat?.mediator_timezone,
                        abbreviation: mediat?.abbreviation,

                    }
                }) : undefined,
                "is_mediator_added": isMediatorCheckboxChecked,
                "is_draft": true,
                "is_internal_meeting": isCheckboxChecked,
                "auto_recording": autoRecordingCheckboxChecked,
                "is_meeting_link_provided": meetingLinkCheckboxChecked,
                "optout_expert_for_pre_and_post_calls": isExpertCheckboxChecked
            }
        } else {
            body = {
                // "zoom_account": step1.zoom_id,
                "topic": step1?.project_name?.trim(),
                "engagement_type": step1?.engagement_type,
                "location_address": step1?.location_address,
                "call_name": step1?.call_name?.trim(),
                "meeting_link": step1?.meeting_link?.trim(),
                "meeting_id": step1?.meeting_id?.trim(),
                "meeting_passcode": step1?.meeting_passcode?.trim(),
                "project_id": step1?.project_id,
                "call_date": step1?.call_date,
                "call_time": step1?.call_time_string,
                "client": step1?.client,
                "duration": step1?.duration?.value,
                "admin_id": userData?.data?._id,
                "client_country": step1?.country,
                "client_timezone": step1?.timezone,
                "abbreviation": step1?.abbreviation,
                "client_call_rate_currency": step1?.hourly_currency || "USD",
                "client_call_rate": step1?.hourly_rate_call_cunsltn,
                "client_details": inputs?.map((input) => {
                    return {
                        client_name: input.client_name?.trim(),
                        client_email: input.client_email?.trim(),
                        in_list: input.in_list
                    }
                }),
                "expert_details": step3?.map((step) => {
                    return {
                        "expert_email": step?.expert_email?.trim(),
                        "expert_name": step?.expert_name?.trim(),
                        "expert_country": step?.country,
                        "expert_timezone": step?.timezone,
                        "abbreviation": step?.abbreviation,
                        "expert_call_rate_currency": step?.expert_hourly_currency,
                        "expert_call_rate": step?.expert_hourly_rate_call_cunsltn,
                        "expert_designation": step?.expert_designation,
                        "expert_company": step?.expert_company,
                        "additional_details": step?.additional_details,
                        "optout_expert_for_pre_and_post_calls": step.opt_out_check,
                        "pre_call_email_sent": step.pre_call_email_sent,
                        "in_list": step?.in_list
                    }
                }),
                "mediator_details": isMediatorCheckboxChecked ? mediators?.map((mediat) => {
                    return {
                        mediator_email: mediat?.mediator_email?.trim(),
                        mediator_name: mediat?.mediator_name?.trim(),
                        mediator_country: mediat?.mediator_country,
                        mediator_timezone: mediat?.mediator_timezone,
                        abbreviation: mediat?.abbreviation,
                    }
                }) : undefined,
                "is_mediator_added": isMediatorCheckboxChecked,
                "is_internal_meeting": isCheckboxChecked,
                "auto_recording": autoRecordingCheckboxChecked,
                "is_meeting_link_provided": meetingLinkCheckboxChecked,
                "optout_expert_for_pre_and_post_calls": isExpertCheckboxChecked
            }
        }

        try {
            setLoading({ type: saveAs, load: true })
            let response = await axios.post(API_URL.SAVE_DRAFT_MEETING, body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (response?.data?.status) {
                setLoading({ type: saveAs, load: false })
                form.resetFields()
                dispatch(resetZoomMeeting())
                setTimeout(() => {
                    notification.success({
                        message: "Meeting Data Added Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);

                navigate("/dashboard/draft-zoom-meetings")
            }
        } catch (error) {
            setLoading({ type: saveAs, load: false })
            notification.error({
                message: error?.response?.data?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        // } else {
        //     let findInd = inputs?.findIndex(user => user?.client_email === filterDomain?.[0]?.client_email)
        //     form.setFields([{
        //         name: [findInd, "client_email"],
        //         errors: ["Clients of different companies are not allowed"]
        //     }])

        // }
    }, [autoRecordingCheckboxChecked, dispatch, form, inputs, isCheckboxChecked, isExpertCheckboxChecked, isMediatorCheckboxChecked, mediators, meetingLinkCheckboxChecked, navigate, step1?.abbreviation, step1?.call_date, step1?.call_name, step1?.call_time_string, step1?.client, step1?.country, step1?.duration?.value, step1?.engagement_type, step1?.hourly_currency, step1?.hourly_rate_call_cunsltn, step1?.location_address, step1?.meeting_id, step1?.meeting_link, step1?.meeting_passcode, step1?.project_id, step1?.project_name, step1?.timezone, step3, userData?.data?._id, userData?.token])

    const updateDraftMeeting = useCallback(async (type = "submit") => {
        let body = {}
        // let domain = inputs?.[0]?.client_email?.split("@")?.[1]
        // let filterDomain = inputs.filter((client) => client?.client_email?.split("@")?.[1]?.trim() !== domain?.trim())
        // if (filterDomain?.length === 0) {
        if (type === "submit" || type === "draft") {
            body = {
                "id": zoomId,
                "zoom_account": step1.zoom_id,
                "topic": step1.project_name?.trim(),
                "engagement_type": step1?.engagement_type,
                "location_address": step1?.location_address,
                "call_name": step1?.call_name?.trim(),
                "meeting_link": step1.meeting_link?.trim(),
                "meeting_id": step1?.meeting_id?.trim(),
                "meeting_passcode": step1?.meeting_passcode?.trim(),
                "project_id": step1?.project_id,
                "client": step1?.client,
                // "call_date": moment(step1.call_date, dateFormat).format(dateFormat),
                "call_date": moment(step1.call_date, [dateFormat, backendDateFormat]).format(dateFormat),
                "call_time": step1.call_time_string,
                "duration": step1?.duration?.value || step1?.duration,
                // "admin_id": userData?.data?._id,
                "client_country": step1.country,
                "client_timezone": step1.timezone,
                "abbreviation": step1?.abbreviation,
                "client_call_rate_currency": step1.hourly_currency || "USD",
                "client_call_rate": step1.hourly_rate_call_cunsltn,
                "client_details": inputs?.map((input) => {
                    return {
                        client_name: input.client_name?.trim(),
                        client_email: input.client_email?.trim(),
                        in_list: input.in_list
                    }
                }),
                "expert_details": step3?.map((step) => {
                    return {
                        "expert_email": step.expert_email?.trim(),
                        "expert_name": step.expert_name?.trim(),
                        "expert_country": step.country,
                        "expert_timezone": step.timezone,
                        abbreviation: step?.abbreviation,
                        "expert_call_rate_currency": step.expert_hourly_currency || "USD",
                        "expert_call_rate": step.expert_hourly_rate_call_cunsltn,
                        "expert_designation": step.expert_designation,
                        "expert_company": step.expert_company,
                        "additional_details": step?.additional_details,
                        "optout_expert_for_pre_and_post_calls": step.opt_out_check,
                        "pre_call_email_sent": step.pre_call_email_sent,
                        "in_list": step?.in_list
                    }
                }),
                "mediator_details": isMediatorCheckboxChecked ? mediators?.map((mediat) => {
                    return {
                        mediator_email: mediat?.mediator_email?.trim(),
                        mediator_name: mediat?.mediator_name?.trim(),
                        mediator_country: mediat?.mediator_country,
                        mediator_timezone: mediat?.mediator_timezone,
                        abbreviation: mediat?.abbreviation,
                    }
                }) : undefined,
                "is_mediator_added": isMediatorCheckboxChecked,
                is_draft: type === "draft" ? true : false,
                "is_internal_meeting": isCheckboxChecked,
                "auto_recording": autoRecordingCheckboxChecked,
                "is_meeting_link_provided": meetingLinkCheckboxChecked,
                "optout_expert_for_pre_and_post_calls": isExpertCheckboxChecked,
                "zoom_meetingId": allResult?.zoom_meeting_data?.zoom_meeting_data?.id
            }
        } else {
            body = {
                "id": zoomId || zoomId1,
                "zoom_account": step1.zoom_id,
                "client": step1?.client,
                "topic": step1.project_name?.trim(),
                "engagement_type": step1?.engagement_type,
                "location_address": step1?.location_address,
                "call_name": step1?.call_name?.trim(),
                "meeting_link": step1.meeting_link?.trim(),
                "meeting_id": step1?.meeting_id?.trim(),
                "meeting_passcode": step1?.meeting_passcode?.trim(),
                "project_id": step1?.project_id,
                // "call_date": moment(step1.call_date).format(dateFormat),
                "call_date": moment(step1.call_date, [dateFormat, backendDateFormat]).format(dateFormat),
                "call_time": step1.call_time_string,
                "duration": step1?.duration?.value || step1?.duration,
                // "admin_id": userData?.data?._id,
                "client_country": step1.country,
                "client_timezone": step1.timezone,
                "abbreviation": step1?.abbreviation,
                "client_call_rate_currency": step1.hourly_currency || "USD",
                "client_call_rate": step1.hourly_rate_call_cunsltn,
                "client_details": inputs?.map((input) => {
                    return {
                        client_name: input.client_name?.trim(),
                        client_email: input.client_email?.trim(),
                        in_list: input.in_list
                    }
                }),
                "expert_details": step3?.map((step) => {
                    return {
                        "expert_email": step.expert_email?.trim(),
                        "expert_name": step.expert_name?.trim(),
                        "expert_country": step.country,
                        "expert_timezone": step.timezone,
                        "abbreviation": step?.abbreviation,
                        "expert_call_rate_currency": step.expert_hourly_currency || "USD",
                        "expert_call_rate": step.expert_hourly_rate_call_cunsltn,
                        "expert_designation": step.expert_designation,
                        "expert_company": step.expert_company,
                        "additional_details": step?.additional_details,
                        "optout_expert_for_pre_and_post_calls": step.opt_out_check,
                        "pre_call_email_sent": step.pre_call_email_sent,
                        "in_list": step?.in_list
                    }
                }),
                "mediator_details": isMediatorCheckboxChecked ? mediators?.map((mediat) => {
                    return {
                        mediator_email: mediat?.mediator_email?.trim(),
                        mediator_name: mediat?.mediator_name?.trim(),
                        mediator_country: mediat?.mediator_country,
                        mediator_timezone: mediat?.mediator_timezone,
                        abbreviation: mediat?.abbreviation,
                    }
                }) : undefined,
                "is_mediator_added": isMediatorCheckboxChecked,
                "is_internal_meeting": isCheckboxChecked,
                "auto_recording": autoRecordingCheckboxChecked,
                "is_meeting_link_provided": meetingLinkCheckboxChecked,
                "optout_expert_for_pre_and_post_calls": isExpertCheckboxChecked,
                "is_approve": true,
                "approve_by": userData?.data?._id,
                zoom_meetingId: allResult?.zoom_meeting_data?.zoom_meeting_data?.id
            }
        }
        try {
            setLoading({ type: type, load: true })
            let response = await axios.post(API_URL.UPDATE_DRAFT_MEETING, body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (response?.data?.status) {
                setLoading({ type: type, load: false })
                form.resetFields()
                dispatch(resetZoomMeeting())
                setTimeout(() => {
                    let message = type === "submit" ? "meeting updated successfully!" : type === "draft" ? "Meeting Added to draft" : "Meeting Approve Successfully!"
                    notification.success({
                        message: message,
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                    navigate("/dashboard/draft-zoom-meetings")
                }, 100);
                return true
            }
        } catch (error) {
            setLoading({ type: type, load: false })
            notification.error({
                message: error?.response?.data?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        // } else {
        //     let findInd = inputs?.findIndex(user => user?.client_email === filterDomain?.[0]?.client_email)
        //     form.setFields([{
        //         name: [findInd, "client_email"],
        //         errors: ["Clients of different companies are not allowed"]
        //     }])
        // }

    }, [allResult?.zoom_meeting_data?.zoom_meeting_data?.id, autoRecordingCheckboxChecked, dispatch, form, inputs, isCheckboxChecked, isExpertCheckboxChecked, isMediatorCheckboxChecked, mediators, meetingLinkCheckboxChecked, navigate, step1?.abbreviation, step1.call_date, step1?.call_name, step1.call_time_string, step1?.client, step1.country, step1?.duration, step1?.engagement_type, step1.hourly_currency, step1.hourly_rate_call_cunsltn, step1?.location_address, step1?.meeting_id, step1.meeting_link, step1?.meeting_passcode, step1?.project_id, step1.project_name, step1.timezone, step1.zoom_id, step3, userData?.data?._id, userData?.token, zoomId, zoomId1])


    const debounceFetcher = useMemo(() => {
        let loadOptions = (value) => {
            fetchCompanyList(value);
        }
        return debounce(loadOptions, 800)
    }, [fetchCompanyList])

    const debounceFetchClient = useMemo(() => {
        let loadOptions = async (value) => {
            setLoadingClient(true)
            let res = await fetchAllClientList(value);
            if (res) {
                setLoadingClient(false)
            }
        }
        return debounce(loadOptions, 800)
    }, [fetchAllClientList])

    const debounceFetchExpert = useMemo(() => {
        let loadOptions = async (value) => {
            setLoadingExpert(true)
            let res = await fetchAllExpertList({ value: value });
            if (res) {
                setLoadingExpert(false)
            }
        }
        return debounce(loadOptions, 800)
    }, [fetchAllExpertList])

    const debounceFetcherProject = useMemo(() => {
        let loadOptions = (value) => {
            fetchUserProjectList(value);
        };

        return debounce(loadOptions, 1000);
    }, [fetchUserProjectList]);

    const values = useMemo(
        () => ({
            saveDraftMeeting,
            updateDraftMeeting,
            handleAddInput,
            handleInputChangeExpert,
            handleInputChangeExpertTimezone, handleInputChangeMediatorTimezone,
            handleInputChange,
            handleAddInputExpert,
            expertCurrency, handleStep1Change,
            handleDeleteInputExpert,
            handleDeleteInput,
            isCheckboxChecked,
            zoomAccountList,
            userProjectList,
            step1,
            step3,
            inputs, mediators,
            form, allResult, isLoadingMeetingData,
            isValidatingMeetingData, zoomHostAccountList, autoRecordingCheckboxChecked, meetingLinkCheckboxChecked,
            fetchZoomAccountList, zoomCompanyList,
            fetchCompanyList,
            debounceFetcher, debounceFetchClient,
            isExpertCheckboxChecked, isMediatorCheckboxChecked, fetchAllClientList, loading, loadingClient, loadingExpert, isLoadingClients, isLoadingExperts, fetchUserProjectList, debounceFetcherProject, zoomExpertList, fetchAllExpertList, debounceFetchExpert, handleAddMediator,
            handleMediatorChange,
            handleDeleteMediator, handleMediatorCountryChange,
            handleExpertCountryChange
        }),
        [saveDraftMeeting, updateDraftMeeting, handleAddInput, handleInputChangeExpert, handleInputChangeExpertTimezone, handleInputChangeMediatorTimezone, handleInputChange, handleAddInputExpert, expertCurrency, handleStep1Change, handleDeleteInputExpert, handleDeleteInput, isCheckboxChecked, zoomAccountList, userProjectList, step1, step3, inputs, mediators, form, allResult, isLoadingMeetingData, isValidatingMeetingData, zoomHostAccountList, autoRecordingCheckboxChecked, meetingLinkCheckboxChecked, fetchZoomAccountList, zoomCompanyList, fetchCompanyList, debounceFetcher, debounceFetchClient, isExpertCheckboxChecked, isMediatorCheckboxChecked, fetchAllClientList, loading, loadingClient, loadingExpert, isLoadingClients, isLoadingExperts, fetchUserProjectList, debounceFetcherProject, zoomExpertList, fetchAllExpertList, debounceFetchExpert, handleAddMediator, handleMediatorChange, handleDeleteMediator, handleMediatorCountryChange, handleExpertCountryChange]
    );
    return values;
};

export default useZoomMeeting;
