import { Button, Divider, Form, Input, Modal, notification, Select, Upload } from 'antd'
import React, { useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { currencyList } from '../../Constants/Currency';
import "../../assests/css/custom.css"
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import axios from 'axios';
import API_URL from '../../Constants/api-path';

const { Option } = Select;

const PostPaymentModal = ({ open, index, setOpen, data, getZoomList }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [values, setValues] = useState({
        payment_mode: "bank",
        amount_paid: "",
        amazon_email: "",
        attachement: [],
        currency: ""
    });
    const callOnce = useRef(false)
    React.useEffect(() => {
        if (data && !callOnce.current && open) {
            callOnce.current = true
            let expertSelectedPaymentMode = data?.expert_payment_details?.filter((det) => det?.status === true)?.[0]
            let obj = {
                payment_mode: data?.post_payment_mode ? data?.post_payment_mode : expertSelectedPaymentMode?.payment_method,
                amount_paid: data?.post_amount_paid ? data?.post_amount_paid : data?.expert_rate,
                amazon_email: data?.amazon_email ? data?.amazon_email : expertSelectedPaymentMode?.amazn_email_id,
                attachement: data?.attachement ? data?.attachement?.map((att) => {
                    return {
                        uid: att?.s3key, // unique identifier for the file
                        name: att?.file_original_name, // name of the file
                        status: "done", // status 'done' because it's already uploaded
                        url: att?.s3url, // use the link you already have for the file
                    }
                }) : [],
                currency: data?.post_currency ? data?.post_currency : expertSelectedPaymentMode?.currency ? expertSelectedPaymentMode?.currency : data?.expert_currency,
                data: data
            }
            form.setFieldsValue(obj)
            setValues(obj)
        }
    }, [data, form, index, open])
    const once = useRef(false)
    const handleReset = () => {
        once.current = false
        form.resetFields()
    }

    const updatePostPaymentDetails = async () => {
        try {
            setLoading(true)
            let formData = new FormData()
            formData.append("post_payment_mode", values?.payment_mode)
            formData.append("post_amount_paid", values?.amount_paid)
            if (values?.amazon_email !== "") {
                formData.append("amazon_email", values?.amazon_email)
            }
            if (values?.attachement?.length > 0) {
                values?.attachement?.forEach((file) => {
                    if (file?.originFileObj) {
                        formData.append("attachment", file?.originFileObj);
                    }
                });
            }
            formData.append("post_currency", values?.currency)
            formData.append("id", data?._id)

            await axios.post(`${API_URL.UPDATE_POST_PAYMENT_DETAILS}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            setOpen(false)
            getZoomList(1)
            setLoading(false)
            handleReset()
            notification.success({
                message: "Call Details Updated Successfully!",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
            });

        } catch (error) {
            setOpen(false)
            setLoading(false)
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
    }


    return (
        <Modal
            title={<div>
                Send Post Payment Email
                <Divider className="m-2" />
            </div>}
            open={open}
            centered
            width={1000}
            onCancel={() => {
                setOpen(false)
                callOnce.current = false
                handleReset()
            }}
            bodyStyle={{
                overflowY: "scroll",
                overflowX: "hidden",
            }}
            className="modal_scroll removeScrollbar"
            footer={false}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                name='bank_details_form'
                initialValues={values}
                form={form}
                onFinish={() => {
                    updatePostPaymentDetails()
                }}
            >
                <Row >
                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Item
                                name={"payment_mode"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Field is required",
                                    },
                                ]}
                                label="Select Payment Mode"
                            >
                                <Select
                                    name="payment_mode"
                                    size="large"
                                    allowClear
                                    value={values?.payment_mode}
                                    placeholder="Select Payment Mode"
                                    autoComplete="off"
                                    showSearch
                                    onChange={(value) => {
                                        setValues({
                                            ...values,
                                            payment_mode: value
                                        })

                                    }}
                                >
                                    <Option key={"Amazon"} value={"amazon"}>
                                        Amazon Gift Card
                                    </Option>
                                    <Option key={"Bank"} value={"bank"}>
                                        Bank Transfer
                                    </Option>
                                    <Option key={"Charity"} value={"charity"}>
                                        Charity
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item name={"amount_paid"} label="Amount Paid"
                                rules={[
                                    {
                                        required: true,
                                        message: "Amount Paid is required",
                                    },
                                ]}>
                                <Input
                                    name="amount_paid"
                                    type="number"
                                    placeholder="Amount Paid"
                                    addonBefore={<div className="d-flex flex-row">
                                        <Select
                                            style={{
                                                width: 80,
                                            }}
                                            defaultValue="USD"
                                            size="large"
                                            value={values?.currency || "USD"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
                                            onChange={(value) => {
                                                setValues({
                                                    ...values,
                                                    currency: value
                                                })
                                            }}
                                        >
                                            {currencyList?.map((item) => (
                                                <Select.Option value={item?.code}>{item?.code}</Select.Option>
                                            ))}

                                        </Select>
                                    </div>}
                                    size="large"
                                    value={values?.amount_paid}
                                    onChange={(e) => {
                                        setValues({
                                            ...values,
                                            amount_paid: e?.target?.value
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {values?.payment_mode === "amazon" && <Col xs={12} md={6}>
                            <Form.Item name={"amazon_email"} label="Amazon Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Amazon Email is required",
                                    },
                                ]}>
                                <Input
                                    name="amazon_email"
                                    placeholder="Amazon Email"
                                    size="large"
                                    value={values?.amazon_email}
                                    onChange={(e) => {
                                        setValues({
                                            ...values,
                                            amazon_email: e?.target?.value
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                        {values?.payment_mode === "charity" && <Col xs={12} md={6}>
                            <Form.Item
                                name={"attachement"}
                                label="Attachement"
                            >
                                <Upload
                                    name={"file"}
                                    multiple={false}
                                    beforeUpload={(file) => {
                                        const isPNG = file.type === "image/png";
                                        const isJPEG = file.type === "image/jpeg";
                                        const isPDF = file.type === "application/pdf";
                                        if (!isPNG && !isJPEG && !isPDF) {
                                            form.setFields([
                                                {
                                                    name: "attachement",
                                                    errors: [`${file.name} does not have a PDF, PNG, or JPEG format.`],
                                                },
                                            ]);
                                        }
                                        return isPNG || isJPEG || isPDF || Upload.LIST_IGNORE;
                                    }}
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0)
                                    }}

                                    onChange={async (info) => {
                                        const nextState = {};
                                        switch (info.file.status) {
                                            case "uploading":
                                                nextState.selectedFileList = [info.file];
                                                break;
                                            case "done":
                                                nextState.selectedFile = info.file;
                                                nextState.selectedFileList = info.fileList;
                                                break;

                                            default:
                                                // error or removed
                                                nextState.selectedFile = null;
                                                nextState.selectedFileList = [];
                                        }

                                        setValues({
                                            ...values,
                                            attachement: nextState?.selectedFileList
                                        })
                                        form.setFieldValue("attachement", nextState?.selectedFileList);
                                    }}

                                    fileList={values?.attachement}>
                                    {values?.attachement?.length === 0 && (
                                        <Button
                                            className="d-flex align-items-center"
                                            icon={<UploadOutlined />}
                                        >
                                            Click to Upload
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </Col>}
                    </Row>

                </Row>
                <Row>
                    <Col className='text-end d-flex align-items-center justify-content-end gap-2'>
                        <Form.Item className='m-0'>
                            <Button
                                className="btn-responsive expertButtonNext"
                                onClick={() => {
                                    setOpen(false)
                                    callOnce.current = false
                                    handleReset()
                                }}
                                htmlType='button'
                            >
                                Close
                            </Button>
                        </Form.Item>
                        <Form.Item className='m-0'>
                            <Button
                                className="btn-responsive viewButton"
                                htmlType='submit'
                            >
                                {loading ? <LoadingOutlined /> : "Submit"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default PostPaymentModal
