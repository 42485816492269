import { Checkbox, Button, Radio } from "antd";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStep from "../../../CustomHooks/UseStep";
import "./Register.css";

function Introduction() {
  const history = useNavigate();
  const {
    setCurrentStep,
    registrationType,
    setCurrentStepConsultant,
    setRegistrationType,
  } = useStep();

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const queryParameters = useMemo(() => new URLSearchParams(window.location.search), []);

  const handlClicked = () => {

    if (registrationType === 1) {
      localStorage.removeItem("step1")
      localStorage.removeItem("step2")
      localStorage.removeItem("step3")
      if (queryParameters.get("app") === "aotp5261j") {
        localStorage.setItem("app_code", true)
      } else {
        localStorage.removeItem("app_code")
      }
      setCurrentStep(1);
      history(`/expert-register?regType=1&step=1`);
    } else {
      localStorage.removeItem("step1")
      localStorage.removeItem("step2")
      localStorage.removeItem("step3")
      if (queryParameters.get("app") === "aotp5261j") {
        localStorage.setItem("app_code", true)
      } else {
        localStorage.removeItem("app_code")
      }
      setCurrentStepConsultant(1);
      history(`/expert-register?regType=2&step=1`);
    }
  };

  return (
    <React.Fragment>
      <div className="expertRegistration step1">
        <h5>Introduction</h5>
        <p>
          Welcome to the induction process of the Nextyn professional network.
        </p>
        <p>
          This process is mandatory for all professionals who wish to be
          registered as a part of the Network.
        </p>
        <p>The process has the following three steps:</p>
        <ul>
          <li>
            <strong>Step 1: Register with Linkedin or Email</strong>
          </li>
          <li>
            <strong>Step 2: Contact Information</strong>
          </li>
          <li>
            <strong>Step 3: Nextyn Agreement</strong>
          </li>
        </ul>
        <p>
          Please note, the process must be completed in a single sitting and
          cannot be saved as a draft for completion in the future.
        </p>
        <>
          <h5 className="my-2">Register as an :</h5>
          <Radio.Group
            className="expertRegistrationRadio mb-3"
            onChange={(e) => {
              e.preventDefault();
              setRegistrationType(e.target.value);
            }}
            value={registrationType}
          >
            <Radio value={1}>Expert <span style={{
              fontSize: 14,
              color: "grey",
              fontStyle: "italic"
            }}>(Short term consulting calls (Hourly))</span></Radio>
            <Radio value={2}>Independent Consultant <span style={{
              fontSize: 14,
              color: "grey",
              fontStyle: "italic"
            }}>(Mid to Long term consulting (Daily, Weekly, Monthly))</span></Radio>
          </Radio.Group>
        </>

        <div class="row top-padding">
          <div class="col-12 col-sm-12">
            <div className="d-flex algin-items-sm-start">
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              <p className="mb-0 ms-2 position-relative">
                By proceeding, you confirm that the information you will be
                sharing is true, accurate, and not misleading.
              </p>
            </div>
          </div>
        </div>

        <div className="text-end">
          {/* <NavLink
            to={"/expert/register?step=1"}
            onClick={() => setCurrentStep(1)}
          > */}
          <Button
            disabled={!isChecked}
            onClick={handlClicked}
            size="large"
            className="btn btn-responsive expertButtonNext"
          >
            Proceed
          </Button>
          {/* </NavLink> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Introduction;
