import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import API_PATH from "../../../Constants/api-path";
import useStep from "../../../CustomHooks/UseStep";
import ExpertAuth from "../../components/ExpertAuth";
dayjs.extend(customParseFormat);

const { Option } = Select;

const RegistarConsultantStep2 = ({ defaultFileList, setDefaultFileList }) => {
  const history = useNavigate();
  const { registrationType, setCurrentStepConsultant } = useStep();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState("");
  const [industOption, setFilteredIndust] = useState("");
  const [selectedIndustOptions, setSelectedIndustOptions] = useState([]);
  const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [tagtOption, setFilteredTag] = useState("");
  const [code, setCode] = useState(null);
  // const [, setCaptchaVerify] = useState(false);

  // const dialogRef = useRef();
  const [step2, setStep2] = useState({
    highest_education: "",
    highest_professional_level: "",
    years_of_experience: "",
    linkedin_url: "",
    industry: "",
    tags: [],
    project_name: "",
    project_scope: "",
    project_description: "",
  });
  // const countries = Country?.getAllCountries();
  // const updatedCountries = useMemo(
  //   () =>
  //     countries?.map((country, index) => {
  //       return (
  //         <Option key={country.isoCode} value={country.name}>
  //           {country.name}
  //         </Option>
  //       );
  //     }),
  //   [countries]
  // );

  // const updatedCities = (countryId) =>
  //   City.getCitiesOfCountry(countryId).map((city, index) => {
  //     return (
  //       <Option value={city.name} key={index}>
  //         {city.name} {city.stateCode}
  //       </Option>
  //     );
  //   });
  const experience = [
    { key: 1, label: 1, value: 1 },
    { key: 2, label: 2, value: 2 },
  ];

  for (let i = 3; i <= 70; i++) {
    experience.push({ key: i, label: i, value: i });
  }
  const [inputs, setInputs] = useState([
    {
      career_title: "",
      company_name: "",
      location: "",
      country: "",
      city: "",
      date_from: null,
      date_till: null,
      till_present: "no",
    },
  ]);

  // const [allDesig, setAllDesig] = useState([]);

  const handleStep2Change = (event) => {
    const { name, value } = event.target;
    setStep2((step2) => ({
      ...step2,
      [name]: value,
    }));
  };

  const handleStep2ChangeExp = (value) => {
    const selectValue = value;
    setStep2({ ...step2, years_of_experience: selectValue });
  };

  const handleStep2ChangehigEdu = (value) => {
    const selectValue = value;
    setStep2({ ...step2, highest_education: selectValue });
  };

  const handleStep2ChangeHigprof = (value) => {
    const selectValue = value;
    setStep2({ ...step2, highest_professional_level: selectValue });
  };

  // const handleInputChange = (value, field, index) => {
  //   setInputs((prevInputs) =>
  //     prevInputs?.map((input, i) => {
  //       if (i === index) {
  //         if (field === "date_from") {
  //           form.setFieldValue([index, "date_till"], null);
  //           return {
  //             ...input,
  //             date_from:
  //               value !== null ? dayjs(value)?.format("MM, YYYY") : null,
  //             date_till: null,
  //           };
  //         } else if (field === "date_till") {
  //           return {
  //             ...input,
  //             date_till:
  //               value !== null ? dayjs(value)?.format("MM, YYYY") : null,
  //           };
  //         } else {
  //           return {
  //             ...input,
  //             [field]: value,
  //           };
  //         }
  //         // return {
  //         //     ...input,
  //         //     [field]:
  //         //         (field === "date_from" || field === "date_till") && value
  //         //             ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
  //         //             : value, // If value is null (cleared), then set field to null
  //         // };
  //       }
  //       return input;
  //     })
  //   );
  // };

  const saveToLocalStorage = React.useCallback(() => {
    const data = {
      career_title: [],
      company_name: [],
      location: [],
      country: [],
      city: [],
      date_from: [],
      date_till: [],
      till_present: [],
    };

    inputs?.forEach((input) => {
      if (
        input.career_title !== "" &&
        input.company_name !== "" &&
        input.city !== "" &&
        input.country !== ""
      ) {
        data.career_title.push(input.career_title);
        data.company_name.push(input.company_name);
        data.city.push(input.city);
        data.country.push(input.country);
        data.location.push(input.location);
        data?.date_from?.push(
          input?.date_from
            ? dayjs(input?.date_from, "MM, YYYY").format("MMM YYYY")
            : ""
        );
        data?.date_till?.push(
          input?.date_till
            ? dayjs(input?.date_till, "MM, YYYY").format("MMM YYYY")
            : ""
        );
        data?.till_present?.push(input?.till_present === "yes" ? "yes" : "no");
      }
    });

    const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
    const dataToStore = {
      step2,
      inputs: data,
      // defaultFileList: defaultFileList,
      expiration: expirationTime,
    };
    localStorage.setItem("step2", JSON.stringify(dataToStore));
  }, [inputs, step2]);
  const queryParameters = useMemo(() => new URLSearchParams(window.location.search), []);

  const handleSubmit = React.useCallback(() => {
    // Handle form submission
    saveToLocalStorage();
    history(`/expert-register?step=3&regType=${registrationType}`);
    setCurrentStepConsultant(3);
  }, [history, registrationType, saveToLocalStorage, setCurrentStepConsultant]);
  const handleSubmit2 = React.useCallback(() => {
    // Handle form submission
    saveToLocalStorage();
    history(
      `/expert-register?step=3&regType=${registrationType}&type=linkedin`
    );
    setCurrentStepConsultant(3);
  }, [history, registrationType, saveToLocalStorage, setCurrentStepConsultant]);

  // const handleAddInput = () => {
  //   const newInputs = [
  //     ...inputs,
  //     {
  //       career_title: "",
  //       company_name: "",
  //       location: "",
  //       country: "",
  //       city: "",
  //       date_from: null,
  //       date_till: null,
  //       till_present: "no",
  //     },
  //   ];
  //   setInputs(newInputs);
  // };

  // const handleDeleteInput = (index) => {
  //   if (inputs.length > 1) {
  //     const newInputs = [...inputs];
  //     newInputs.splice(index, 1);
  //     setInputs(newInputs);
  //   }
  // };

  const getExpertsOptions = () => {
    setLoading(true);

    axios
      .get(`${API_PATH.EXPERT_OPTION}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // setAllDesig(filterDuplicates(res?.data?.designationNew));
        setLoading(false); // Move setLoading to false here to
      })
      .catch((error) => {
        console.error("Expert Options: ", error);
        setLoading(false);
      });
    setLoading(false);
  };

  const isLocalStorageExpired = () => {
    const storedData = localStorage.getItem("step2");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const now = new Date().getTime();
      return parsedData.expiration && now > parsedData.expiration;
    }
    return true;
  };

  const GetIndustryList = async (industOption) => {
    setLoading(true);

    let object = {};

    if (industOption) {
      object.key = `${industOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_INDUSTRY
        }?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredIndustOptions(result?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Industry Error", error);
    }
    setLoading(false);
  };
  const handleDropdownIndustry = (open) => {
    if (open) {
      GetIndustryList("");
    }
  };

  const handleOptionIndustChange = (selectedValues) => {
    setSelectedIndustOptions(selectedValues);
    const selectValue = selectedValues;
    setStep2({ ...step2, industry: selectValue });
  };
  const GetTagsList = async (tagtOption) => {
    setLoading(true);

    let object = {};

    if (tagtOption) {
      object.key = `${tagtOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_TAG}?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredOptions(result?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("tag_list_error", error);
    }
    setLoading(false);
  };
  const handleDropdownTagList = (open) => {
    if (open) {
      GetTagsList("");
    }
  };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    const selectValue = selectedValues;
    setStep2({ ...step2, tags: selectValue });
  };

  const props = {
    name: "file",
    multiple: false,
    // openFileDialogOnClick: false,
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isJPEG = file.type === "image/jpeg";
      const isPDF = file.type === "application/pdf";
      if (!isPNG && !isJPEG && !isPDF) {
        form.setFields([
          {
            name: "resume",
            errors: [`${file.name} does not have a PDF, PNG, or JPEG format.`],
          },
        ]);
      }
      return isPNG || isJPEG || isPDF || Upload.LIST_IGNORE;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: async (info) => {
      const nextState = {};
      // let fileList = [...info.fileList];

      switch (info.file.status) {
        case "uploading":
          nextState.selectedFileList = [info.file];
          break;
        case "done":
          nextState.selectedFile = info.file;
          nextState.selectedFileList = info.fileList;
          break;

        default:
          // error or removed
          nextState.selectedFile = null;
          nextState.selectedFileList = [];
      }
      setDefaultFileList(() => nextState?.selectedFileList);
      form.setFieldValue("resume", nextState?.selectedFileList);
    },
    onDrop(e) {
      console.info("Dropped files", e.dataTransfer.files);
    },
  };
  // const captchaRef = useRef(null);
  // const handleChange = async (value) => {
  //   try {
  //     // Sending secret key and response token to Google Recaptcha API for authentication.
  //     const response = await axios.post(
  //       `https://www.google.com/recaptcha/api/siteverify?secret=${ENV.REACT_APP_SECRET_KEY}&response=${value}`
  //     );
  //     // Check response status and send back to the client-side
  //     if (response.data.success) {
  //       message.success("Human 👨 👩");
  //       setCaptchaVerify(true);
  //       // captchaRef.current.reset()
  //       dialogRef.current?.upload.uploader.fileInput.click();
  //       // setLoad(false)
  //     } else {
  //       message.error("Robot 🤖");
  //       setCaptchaVerify(false);
  //       // setLoad(false)
  //     }
  //   } catch (error) {
  //     // Handle any errors that occur during the reCAPTCHA verification process
  //     console.error(error);
  //     // setLoad(false)W
  //     setCaptchaVerify(false);
  //   }
  // };

  // const asyncScriptOnLoad = () => {
  //   // this.setState({ callback: "called!" });
  //   console.info("scriptLoad - reCaptcha Ref-", captchaRef);
  // };

  React.useEffect(() => {
    //!DEPENDENCY SHOULD BE ON industOption FOR A REASON
    const getData = setTimeout(() => {
      GetIndustryList(industOption);
    }, 500);

    return () => clearTimeout(getData);
  }, [industOption]);

  React.useEffect(() => {
    //!DEPENDENCY SHOULD BE ON tagtOption FOR A REASON
    const getData = setTimeout(() => {
      GetTagsList(tagtOption);
    }, 500);

    return () => clearTimeout(getData);
  }, [tagtOption]);

  useEffect(() => {
    const storedData = localStorage.getItem("step2");
    if (!isLocalStorageExpired() && storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData?.step2 && parsedData?.inputs) {
        const { step2, inputs, defaultFileList } = parsedData;
        setStep2(step2);
        setDefaultFileList(defaultFileList || []);

        const inputsArray =
          Array.isArray(inputs.career_title) && inputs?.career_title?.length > 0
            ? inputs.career_title.map((_, index) => ({
              career_title: inputs.career_title[index] || "",
              company_name: inputs.company_name[index] || "",
              location: inputs.location[index] || "",
              city: inputs.city[index] || "",
              country: inputs.country[index] || "",
              date_from: inputs?.date_from[index]
                ? dayjs(inputs?.date_from[index], "MMM YYYY").format(
                  "MM, YYYY"
                )
                : "",
              date_till: inputs?.date_till[index]
                ? dayjs(inputs?.date_till[index], "MMM YYYY").format(
                  "MM, YYYY"
                )
                : "",
              till_present: inputs?.till_present[index] || "no",
            }))
            : [
              {
                career_title: "",
                company_name: "",
                location: "",
                country: "",
                city: "",
                date_from: null,
                date_till: null,
                till_present: "no",
              },
            ];

        setInputs(inputsArray);

        const initialValues = {
          highest_education: step2?.highest_education,
          highest_professional_level: step2?.highest_professional_level,
          years_of_experience: step2?.years_of_experience,
          linkedin_url: step2?.linkedin_url,
          industry: step2?.industry,
          tags: step2?.tags,
          project_name: step2?.project_name,
          project_scope: step2?.project_scope,
          project_description: step2?.project_description,
          resume: defaultFileList,
        };

        const inputValues = inputsArray.reduce((acc, input, index) => {
          acc[index] = {
            career_title: input?.career_title,
            company_name: input?.company_name,
            location: input?.location,
            country: input?.country,
            city: input?.city,
            date_from:
              input?.date_from !== "" && input?.date_from !== null
                ? dayjs(input?.date_from, "MM, YYYY")
                : null,
            date_till:
              input?.date_till !== "" && input?.date_till !== null
                ? dayjs(input?.date_till, "MM, YYYY")
                : null,
          };
          return acc;
        }, {});

        // inputs?.filter((input) => Object.values(input).some(
        //     (value) => value !== "" && value !== null)).map((input, index) => {
        //         form.setFieldsValue({
        //             [index]: {
        //                 date_from: input?.date_from ? dayjs(input?.date_from, "MM, YYYY") : null,
        //             },
        //         });
        //         form.setFieldsValue({
        //             [index]: {
        //                 date_till: input?.date_till ? dayjs(input?.date_till, "MM, YYYY") : null,
        //             },
        //         });
        //     })

        form.setFieldsValue({
          ...initialValues,
          ...inputValues,
        });
      }
    } else {
      // Clear data and remove expired item from local storage
      localStorage.removeItem("step2");
    }

    // Assuming this function is defined elsewhere and used appropriately
    getExpertsOptions();
  }, [form, setDefaultFileList]);

  useEffect(() => {
    // Function to extract query parameters from the URL
    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'code' query parameter from the URL
    const typeFromURL = getQueryParameter("type");
    if (typeFromURL === "linkedin") {
      // If 'code' exists in the URL, set it in the state
      setCode(typeFromURL);
      // You can perform any additional actions with the 'code' here if needed
    }
  }, []);

  return (
    <React.Fragment>
      <ExpertAuth />

      <div className="expertRegistration step1">
        <h5>Step 2 : Professional Background</h5>
        <p>
          Kindly complete this section by either sharing your LinkedIn profile
          link or Curriculum Vitae (CV). Alternatively, if the first two options
          are unavailable, you have the option of completing this section
          manually.
        </p>

        <div>
          <Form
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={() => {
              if (code) {
                handleSubmit2();
              } else {
                handleSubmit();
              }
            }}
          >
            {!code && (
              <Row>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="highest_education"
                    label="Highest Education"
                    rules={[
                      {
                        required: true,
                        message: "Highest Education is required",
                      },
                    ]}
                  >
                    <Select
                      name="highest_education"
                      size="large"
                      placeholder="Select Highest Education"
                      value={step2?.highest_education}
                      onChange={handleStep2ChangehigEdu}
                    >
                      <Select.Option value="High School">
                        High School
                      </Select.Option>
                      <Select.Option value="Bachelors">Bachelors</Select.Option>
                      <Select.Option value="Masters">Masters</Select.Option>
                      <Select.Option value="PHD">PHD</Select.Option>
                      <Select.Option value="Additional">
                        Additional
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="highest_professional_level"
                    label="Highest Professional Level"
                    rules={[
                      {
                        required: true,
                        message: "Highest Professional Level is required",
                      },
                    ]}
                  >
                    <Select
                      name="highest_professional_level"
                      size="large"
                      placeholder="Select Highest Professional Level"
                      value={step2?.highest_professional_level}
                      onChange={handleStep2ChangeHigprof}
                    >
                      <Select.Option value="Advisor">Advisor</Select.Option>
                      <Select.Option value="CXO">CXO</Select.Option>
                      <Select.Option value="VP">VP</Select.Option>
                      <Select.Option value="AVP">AVP</Select.Option>
                      <Select.Option value="Manager & Below">
                        Manager & Below{" "}
                      </Select.Option>
                      <Select.Option value="Self Employed">
                        Self Employed{" "}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              {!code && (
                <Col xs={12} md={6}>
                  <Form.Item
                    name="years_of_experience"
                    label="Years of Experience"
                    rules={[
                      {
                        required: true,
                        message: "Years of Experience is required",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      name="years_of_experience"
                      placeholder="Select Year"
                      value={step2?.years_of_experience}
                      onChange={handleStep2ChangeExp}
                    >
                      {experience?.map((item) => (
                        <Option key={item?.key} value={item?.value}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col xs={12} md={code ? 12 : 6}>
                <Form.Item
                  name="linkedin_url"
                  label="Linkedin URL"
                  rules={[
                    {
                      required: true,
                      message: "LinkedIn URL required",
                    },
                  ]}
                >
                  <Input
                    name="linkedin_url"
                    placeholder="Url"
                    size="large"
                    // type="url"
                    value={setStep2?.linkedin_url}
                    onChange={handleStep2Change}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Item
                  name="resume"
                  label="Upload CV"
                // rules={[
                //     {
                //         required: true,
                //         message: "Please upload your resume",
                //     },
                // ]}
                >
                  <Upload {...props} fileList={defaultFileList}>
                    {defaultFileList?.length === 0 && (
                      <Button
                        className="d-flex align-items-center"
                        icon={<UploadOutlined />}
                      >
                        Click to Upload
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Item
                  name="industry"
                  label={
                    <div className="d-flex flex-column">
                      <div>Main Industry</div>
                      <div
                        className="fw-normal text-muted"
                        style={{ fontSize: "smaller" }}
                      >
                        (Please choose multiple tags from the drop down, to help
                        us match you to consulting opportunities)
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Main Industry is required",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    name="industry"
                    size="large"
                    mode="tags"
                    onSearch={setFilteredIndust}
                    placeholder="Type for search"
                    value={selectedIndustOptions}
                    onDropdownVisibleChange={handleDropdownIndustry}
                    loading={loading}
                    onChange={handleOptionIndustChange}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {filteredIndustOptions?.map((option) => (
                      <Option key={option?._id} value={option?.category_name}>
                        {option?.category_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Item
                  name="tags"
                  label={
                    <div className="d-flex flex-column">
                      <div>Expertise Tags</div>
                      <div
                        className="fw-normal text-muted"
                        style={{ fontSize: "smaller" }}
                      >
                        (Please choose multiple tags from the drop down, to help
                        us match you to consulting opportunities)
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Expertise Tags are required",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    name="tags"
                    size="large"
                    mode="tags"
                    placeholder="Type for search"
                    value={selectedOptions}
                    onSearch={setFilteredTag}
                    loading={loading}
                    onDropdownVisibleChange={handleDropdownTagList}
                    onChange={handleOptionChange}
                    filterOption={(input, option) => {
                      if (typeof option.children === "string") {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                      return false;
                    }}
                  >
                    {filteredOptions?.map((option) => (
                      <Option key={option?._id} value={option?.name}>
                        {typeof option?.name === "string"
                          ? option?.name.charAt(0).toUpperCase() +
                          option?.name.slice(1)
                          : option?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* {!code && <Row>
                            <Col xs={12}>
                                <Form.Item label="Latest Career Experience" name="past_career_experiance"
                                    required
                                >
                                    {inputs
                                        ?.filter((input) =>
                                            Object.values(input).some(
                                                (value) => value !== "" && value !== null
                                            )
                                        )
                                        .map((input, index) => {
                                         

                                            return (
                                                <div key={index} className="career-container">
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <Form.Item
                                                                className="mb-2"
                                                                name={[index, "career_title"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Career title is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    autoComplete="nope"
                                                                    size="large"
                                                                    placeholder="Title"
                                                                    name="career_title"
                                                                    value={input?.career_title}
                                                                    onChange={(event) =>
                                                                        handleInputChange(
                                                                            event.target.value,
                                                                            "career_title",
                                                                            index
                                                                        )
                                                                    }
                                                                ></Input>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <Form.Item
                                                                className="mb-2"
                                                                name={[index, "company_name"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Company name is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    autoComplete="nope"
                                                                    size="large"
                                                                    placeholder="Company Name"
                                                                    name="company_name"
                                                                    value={input?.company_name}
                                                                    onChange={(event) =>
                                                                        handleInputChange(
                                                                            event.target.value,
                                                                            "company_name",
                                                                            index
                                                                        )
                                                                    }
                                                                ></Input>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center">
                                                        <Col xs={12} md={3}>
                                                            <Form.Item
                                                                name={[index, "country"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    name="country"
                                                                    size="large"
                                                                    labelInValue
                                                                    placeholder="Country"
                                                                    allowClear
                                                                    autoComplete="off"
                                                                    showSearch
                                                                    value={input.country}
                                                                    onChange={(value) => {
                                                                        handleInputChange(value, "country", index)
                                                                    }}
                                                                >
                                                                    {updatedCountries}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <Form.Item
                                                                name={[index, "city"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "City is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    name="city"
                                                                    size="large"
                                                                    labelInValue
                                                                    autoComplete="off"
                                                                    placeholder="City"
                                                                    allowClear
                                                                    disabled={Object?.keys(input?.country || {})?.length === 0}
                                                                    showSearch
                                                                    value={input?.city}
                                                                    onChange={(value) => {
                                                                        handleInputChange(value, "city", index)
                                                                    }}

                                                                >
                                                                    {updatedCities(input?.country ? input?.country?.key : null)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        
                                                        <Col xs={12} md={3}>
                                                            <Form.Item
                                                                name={[index, "date_from"]}
                                                                className="mb-2"
                                                                rules={[
                                                                    {
                                                                        type: 'object',
                                                                        required: true,
                                                                        message: 'Start Date is required',
                                                                    },
                                                                ]}
                                                            >
                                                                <DatePicker
                                                                    className="mb-2 w-100"
                                                                    size="large"
                                                                    format="MM, YYYY"
                                                                    placeholder="From"
                                                                    picker="month"
                                                                    name="date_from"
                                                                    value={
                                                                        input?.date_from
                                                                            ? dayjs(input?.date_from, "MM, YYYY")
                                                                            : null
                                                                    }
                                                                    onChange={(value) => {
                                                                        handleInputChange(value, "date_from", index)


                                                                    }
                                                                    }
                                                                    disabledDate={(current) =>
                                                                        current && current?.isAfter(dayjs(), "month")
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <Form.Item
                                                                name={[index, "date_till"]}
                                                                className="mb-2"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "End Date is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <DatePicker
                                                                    size="large"
                                                                    className="w-100 mb-2"
                                                                    format="MM, YYYY"
                                                                    placeholder="To"
                                                                    disabled={input?.date_from === null}
                                                                    picker="month"
                                                                    name="date_till"

                                                                    value={
                                                                        input?.date_till
                                                                            ? dayjs(input?.date_till, "MM, YYYY")
                                                                            : null
                                                                    }
                                                                    onChange={(value) =>
                                                                        handleInputChange(value, "date_till", index)
                                                                    }

                                                                    disabledDate={(current) => {
                                                                        if (input?.date_from) {
                                                                            return current?.isBefore(dayjs(input?.date_from, "MM, YYYY"), "month") || (current?.isSame(dayjs(input?.date_from, "MM, YYYY"), "month") ? current?.isAfter(dayjs(input?.date_from, "MM, YYYY"), "month") : current?.isAfter(dayjs(), "month"))
                                                                        } else {
                                                                            return current && current?.isAfter(dayjs(), "month")
                                                                        }
                                                                    }

                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <div>
                                                            {inputs.length > 1 ? (
                                                                <div className="d-flex justify-content-end align-items-center my-2">
                                                                    <Button
                                                                        type="primary"
                                                                        onClick={() => handleDeleteInput(index)}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Row >
                                                </div>
                                            )
                                        })}
                                    <div className="d-flex justify-content-center align-items-center mt-4">
                                        <Button
                                            size="large"
                                            className="text-center btn btn-responsive expertButtonNext"
                                            onClick={handleAddInput}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>} */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Item>
                {code ? (
                  <>
                    <Form.Item>
                      <NavLink
                        to={`/expert-register?regType=2&step=1&type=linkedin`}
                        onClick={() => setCurrentStepConsultant(1)}
                      >
                        <Button
                          size="large"
                          className="btn btn-responsive expertButtonNext"
                        >
                          Prev
                        </Button>
                      </NavLink>
                    </Form.Item>
                  </>
                ) : (
                  <NavLink
                    to={`/expert-register?regType=2&step=1`}
                    onClick={() => setCurrentStepConsultant(1)}
                  >
                    <Button
                      size="large"
                      className="btn btn-responsive expertButtonNext"
                    >
                      Prev
                    </Button>
                  </NavLink>
                )}
              </Form.Item>

              {code ? (
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      // onClick={handleSubmit2}
                      className="btn btn-responsive expertButtonNext"
                      size="large"
                      htmlType="submit"
                    // disabled={
                    //     (!code && !form.isFieldsTouched(true))
                    //     ||
                    //     (code && (!form.isFieldTouched("linkedin_url") || !form.isFieldTouched("industry") || !form.isFieldTouched("tags"))
                    //     ) ||
                    //     (code && form.getFieldsError().filter(({ errors }) => errors.length).length
                    //     )
                    // }
                    >
                      Next
                    </Button>
                  )}
                </Form.Item>
              ) : (
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      // onClick={handleSubmit}
                      className="btn btn-responsive expertButtonNext"
                      size="large"
                      htmlType="submit"
                    // disabled={
                    //     !form.isFieldsTouched(true) ||
                    //     !!form
                    //         .getFieldsError()
                    //         .filter(({ errors }) => errors.length).length
                    // }
                    >
                      Next
                    </Button>
                  )}
                </Form.Item>
              )}
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegistarConsultantStep2;
