import { Avatar, Button, Card, Dropdown, Input, Spin, Tooltip } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import API_URL from '../../Constants/api-path';
import { LoadingOutlined } from "@ant-design/icons";
import moment from 'moment';
import axios from 'axios';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { RiDeleteBin7Line } from "react-icons/ri";

const CommentBox = ({ states, functions, callOnce }) => {

    const { setCommentText, setPopoverVisible, setSelectedComment, getComments } = functions
    const { loadingComments, expertComments: data = [1, 2, 3, 4], hit, selectedComment, commentText } = states
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [loading, setLoading] = useState(false);
    // const [visibleCommentBox, setVisibleCommentBox] = useState(false);
    const [loadingadd, setLoadingadd] = useState(false);
    const [managerList, setManagerList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({
        key: ""
    });
    const [isSearching, setIsSearching] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const GetManagerList = useCallback(async (page = 1, options = selectedOptions) => {
        try {
            let object = {};
            if (options?.key) {
                object.key = options?.key;
            }
            const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties
            const res = await fetch(
                `${API_URL.MANAGER_LIST
                }?page=${page}&limit=10&${queryParams?.toString()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                }
            );

            const result = await res.json();
            if (result) {
                setManagerList(result?.data);
                setIsSearching(false)
            } else {
                setIsSearching(false)
            }
        } catch (error) {
            setIsSearching(false)
        }

    }, [selectedOptions, userData?.token]);

    const updateComment = useCallback(async (id) => {
        if (id) {
            try {
                let res = await axios.put(`${API_URL.UPDATE_EXPERT_COMMENT}/${id}`,
                    {
                        "comment": selectedComment?.comment
                    }
                    , {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        }
                    })
                if (res?.data) {
                    getComments(hit?._id)
                    setSelectedComment({})
                }

            } catch (error) {
                console.error('error: ', error);
            }
        }
    }, [getComments, hit?._id, selectedComment?.comment, setSelectedComment, userData?.token]);

    const deleteComment = useCallback(async (id) => {
        if (id) {
            setLoading(true);
            try {
                let res = await axios
                    .delete(`${API_URL.Delete_EXPERT_COMMENT}/${id}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        }
                    })
                if (res) {
                    setLoading(false);
                    getComments(hit?._id)
                    setSelectedComment({})
                }

            } catch (error) {
                setLoading(false);
                console.error('error: ', error);
            }
        }

    }, [getComments, hit?._id, setSelectedComment, userData?.token]);

    const addComment = useCallback(async (expert_id) => {
        if (expert_id) {
            setLoadingadd(true)
            try {
                let res = await axios
                    .post(`${API_URL.ADD_EXPERT_COMMENT}`,
                        {
                            "admin_id": userData?.data?._id,
                            "expert_id": expert_id,
                            "comment": commentText
                        }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        }
                    })
                if (res?.data?.result?._id) {
                    getComments(expert_id)
                    setLoadingadd(false)
                    setCommentText("")
                    setSelectedOptions({
                        key: ""
                    })
                    // setVisibleCommentBox(false)
                }
            } catch (error) {
                setLoadingadd(false)
                console.error('error: ', error);
            }


        }

    }, [commentText, getComments, userData?.data?._id, userData?.token]);

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);

    const handleTextClick = () => {
        // Focus the input when text is clicked
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleTextClick2 = () => {
        // Focus the input when text is clicked
        if (inputRef2.current) {
            inputRef2.current.focus();
        }
    };

    const handleInputChange = useCallback(() => {
        const inputValue = selectedOptions.key
        GetManagerList(1, {
            key: inputValue,
        });
    }, [GetManagerList, selectedOptions.key]);

    useEffect(() => {
        if (!callOnce.current) {
            callOnce.current = true
            GetManagerList(1, selectedOptions);
        }
    }, [GetManagerList, callOnce, selectedOptions]);


    //DEBOUNCE FUNCTION
    useEffect(() => {
        if (isSearching) {
            const handler = setTimeout(async () => {
                await handleInputChange();
            }, 1000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [handleInputChange, isSearching]);
    const items = managerList?.map((manager, index) => (
        {
            key: index,
            label: (
                <div onClick={() => {
                    if (selectedComment?._id) {
                        // const result = selectedComment?.comment?.replace(/@\w+/g, "@" + manager?.email);
                        const result = selectedComment?.comment?.replace(/@\S*$/, "@" + manager?.name);
                        let newComment = { ...selectedComment, comment: result }
                        setSelectedComment(newComment)
                    } else {
                        // const result = commentText.replace(/@\w+/g, "@" + manager?.email);
                        const result = commentText.replace(/@\S*$/, "@" + manager?.name);
                        setCommentText(result)
                    }
                    handleTextClick()
                    handleTextClick2()
                    setDropdownVisible(false);
                }}>
                    <div className='d-flex align-items-center gap-2 g-2'>
                        <div>
                            <Avatar >
                                {manager?.name === undefined
                                    ? "NU"
                                    : manager?.name?.split(" ")?.[0]?.[0]?.toUpperCase() + "" + manager?.name?.split(" ")?.[1]?.[0]?.toUpperCase()}
                            </Avatar>
                        </div>
                        <div>
                            <div>{manager?.name}</div>
                            <div>{manager?.email}</div>
                        </div>
                    </div>

                </div>
            ),

        }
    ))
    const handleDropdownVisibilityChange = (visible, type) => {
        if (selectedComment?._id && type === "update") {
            setDropdownVisible(visible && selectedComment?.comment?.includes("@"))
            if (!visible) {
                setSelectedOptions({
                    key: ""
                })
            }
        } else if (type === "create") {
            setDropdownVisible(visible && commentText?.includes("@"));
            if (!visible) {
                setSelectedOptions({
                    key: ""
                })
            }
        }
    };
    return (
        <>
            {/* LIST */}
            {loadingComments && <div className='h-50 d-flex align-items-center justify-content-center'>
                <Spin
                    spinning={loadingComments}
                    className="ms-2"
                    size="default"
                    indicator={<LoadingOutlined />}
                />
            </div>}
            {!loadingComments && data?.length > 0 &&
                <div className='comment_list'> {data?.map((dat, index) => (
                    <div className='mb-3' key={index}>
                        <Card
                            key={index}
                            title={selectedComment?._id && selectedComment?.index === index && selectedComment?.type === "delete" ?
                                null
                                : <div className='d-flex align-items-center justify-content-between gap-2'>
                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                        <div>
                                            <Avatar >
                                                {dat?.admin_name === undefined
                                                    ? "NU"
                                                    : dat?.admin_name?.split(" ")?.[0]?.[0]?.toUpperCase() + "" + dat?.admin_name?.split(" ")?.[1]?.[0]?.toUpperCase()}
                                            </Avatar>
                                        </div>
                                        <div className=''>
                                            <div> {dat?.admin_name}</div>
                                            <div className='text-muted' style={{
                                                fontSize: 10
                                            }}> {moment(dat?.updated_at || dat?.created_at).calendar(null, {
                                                sameDay: '[Today at] LT',
                                                nextDay: '[Tomorrow at] LT',
                                                nextWeek: 'dddd [at] LT',
                                                lastDay: '[Yesterday at] LT',
                                                lastWeek: 'DD/MM/YYYY [at] LT',
                                                sameElse: 'DD/MM/YYYY [at] LT'
                                            })}</div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                        {userData?.data?._id === dat?.admin_id && <Tooltip title={"Edit"} color="#5746EB" > <HiOutlinePencilSquare className='cursor-pointer' onClick={() => {
                                            setSelectedComment({ ...dat, index: index, type: "update" })
                                            // setVisibleCommentBox(false)
                                        }} /></Tooltip>}
                                        {userData?.data?._id === dat?.admin_id && <Tooltip title={"delete"} color="#5746EB"> <RiDeleteBin7Line className='cursor-pointer' onClick={() => {
                                            setSelectedComment({ ...dat, index: index, type: "delete" })
                                            // setVisibleCommentBox(false)
                                        }} /></Tooltip>
                                        }
                                    </div>
                                </div>}
                            className='commentbox_header'
                            bodyStyle={{
                                padding: 10
                            }}
                            bordered={true}
                        >
                            {selectedComment?._id && selectedComment?.index === index && selectedComment?.type === "update" ?
                                <>
                                    <div className='mb-2'>
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}

                                            placement="bottomLeft"
                                            trigger={["click"]}
                                            open={dropdownVisible && selectedComment?._id}
                                            onOpenChange={(open) => handleDropdownVisibilityChange(open, "update")}
                                        >
                                            <Input value={selectedComment?.comment}
                                                ref={inputRef2}
                                                onChange={(e) => {
                                                    let newComment = { ...selectedComment, comment: e.target.value }
                                                    setSelectedComment(newComment)
                                                    setIsSearching(true)
                                                    const matches = e?.target?.value?.match(/@(\w+(\.\w+)*|[\w-]*)?/g)
                                                    // const matches = e?.target?.value?.match(/@(\w+(\.\w+)*|[\w-]+)/g)
                                                    setSelectedOptions({
                                                        key: matches ? matches[matches.length - 1].substring(1) : "",
                                                    })
                                                    // setSelectedOptions({
                                                    //     key: e.target.value?.split("@")?.[1],
                                                    // })
                                                    if (e.target.value.endsWith("@")) {
                                                        setDropdownVisible(true);
                                                    }
                                                }
                                                } />
                                        </Dropdown>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div>
                                            <Button
                                                className="me-2"
                                                key="back"
                                                onClick={() => {
                                                    setCommentText("")
                                                    setSelectedComment({})
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                // key="submit"
                                                className="viewButton text-end"
                                                style={{ height: "32px" }}
                                                disabled={!selectedComment?.comment}
                                                onClick={() => updateComment(selectedComment?._id)}
                                            >
                                                Update{" "}
                                                <Spin
                                                    spinning={loadingadd}
                                                    className="ms-2"
                                                    size="small"
                                                    indicator={<LoadingOutlined />}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </>
                                : selectedComment?._id && selectedComment?.index === index && selectedComment?.type === "delete" ?
                                    <>
                                        <p style={{
                                            fontSize: 15,
                                            fontWeight: "bolder"
                                        }}>Delete this comment?</p>
                                        <div className='mb-2'>{selectedComment?.comment}</div>
                                        <div className="d-flex justify-content-center align-items-center ">
                                            <Button
                                                className="me-2"
                                                key="back"
                                                onClick={() => {
                                                    setCommentText("")
                                                    setSelectedComment({})
                                                }}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                // key="submit"
                                                className="viewButton text-end"
                                                style={{ height: "32px" }}
                                                onClick={() => deleteComment(selectedComment?._id)}
                                            >
                                                Yes{" "}
                                                <Spin
                                                    spinning={loading}
                                                    className="ms-2"
                                                    size="small"
                                                    indicator={<LoadingOutlined />}
                                                />
                                            </Button>
                                        </div>
                                    </>
                                    : <div className=''>
                                        {dat?.comment}
                                    </div>}
                        </Card>
                    </div>
                ))}
                </div>
            }

            <Card
                title={<>
                    <div>
                        <Avatar >
                            {userData?.data?.name === undefined
                                ? "NU"
                                : userData?.data?.name?.split(" ")?.[0]?.[0]?.toUpperCase() + "" + userData?.data?.name?.split(" ")?.[1]?.[0]?.toUpperCase()}
                        </Avatar> {userData?.data?.name}
                    </div>
                </>}
                className='commentbox_header'
                bodyStyle={{
                    padding: 10
                }}
            // bordered={false}
            >
                <div className='mb-2'>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottomLeft"
                        trigger={["click"]}
                        open={dropdownVisible && !selectedComment?._id}
                        onOpenChange={(open) => handleDropdownVisibilityChange(open, "create")}
                    >
                        <Input value={commentText}
                            ref={inputRef}
                            onChange={(e) => {
                                setSelectedComment({})
                                setCommentText(e.target.value)
                                setIsSearching(true)
                                const matches = e?.target?.value?.match(/@(\w+(\.\w+)*|[\w-]*)?/g)
                                // const matches = e?.target?.value?.match(/@(\w+(\.\w+)*|[\w-]+)/g)
                                setSelectedOptions({
                                    key: matches ? matches[matches.length - 1].substring(1) : "",
                                })

                                if (e.target.value.endsWith("@")) {
                                    setDropdownVisible(true);
                                }
                            }
                            } />
                    </Dropdown>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <div>
                        <Button
                            className="me-2"
                            key="back"
                            onClick={() => {
                                setPopoverVisible({
                                    index: 0,
                                    open: false
                                })
                                setCommentText("")
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="viewButton text-end"
                            style={{ height: "32px" }}
                            disabled={!commentText}
                            onClick={() => addComment(hit?._id)}
                        >
                            Submit{" "}
                            <Spin
                                spinning={loadingadd}
                                className="ms-2"
                                size="small"
                                indicator={<LoadingOutlined />}
                            />
                        </Button>
                    </div>
                </div>
            </Card>
        </>

    )
}

export default CommentBox
